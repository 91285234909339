import _ from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Delete } from '@mui/icons-material'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const MeterDetailsForm = ({ meter, currentValueId, onRemoveMeter, setMeter }) => {
  return (
    <Grid container spacing={2} item xs={12} justifyContent='flex-end'>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          fullWidth
          label='Bezeichnung'
          onChange={(e) => setMeter({ ...meter, name: e.target.value })}
          required
          value={meter.name}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          fullWidth
          label='Zählernummer'
          onChange={(e) => setMeter({ ...meter, number: e.target.value })}
          value={meter.number}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <DatePicker
          label='Datum'
          mask='__.__.____'
          value={_.find(meter.values, x => x.id === currentValueId).date}
          onChange={date => setMeter({ ...meter, values: _.map(meter.values, x => x.id === currentValueId ? { ...x, date } : x) })}
          renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <TextField
          fullWidth
          label='Zählerstand'
          onChange={e => setMeter({ ...meter, values: _.map(meter.values, x => x.id === currentValueId ? { ...x, value: e.target.value } : x) })}
          required
          type='number'
          value={_.find(meter.values, x => x.id === currentValueId).value}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={1}>
        <FormControl fullWidth required variant='standard'>
          <InputLabel id='unit-label'>Einheit</InputLabel>
          <Select
            labelId='unit-label'
            value={meter.unit}
            label='Einheit'
            onChange={e => setMeter({ ...meter, unit: e.target.value })}
          >
            <MenuItem value='m³'>m³</MenuItem>
            <MenuItem value='kWh'>kWh</MenuItem>
            <MenuItem value='MWh'>MWh</MenuItem>
            <MenuItem value='Einheiten'>Einheiten</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs='auto' sm={1}>
        {meter.new
          ? <IconButton onClick={onRemoveMeter}>
            <Delete />
          </IconButton>
          : null
        }
      </Grid>
    </Grid>
  )
}

export default MeterDetailsForm
