import _ from 'lodash'
import api from '../app/api'
import UserForm from '../users/UserForm'
import { Box, Typography } from '@mui/material'
import { Navigate, useLocation } from 'react-router-dom'

const UserEdit = () => {
  const { data: me } = api.useGetMeQuery()
  const { state } = useLocation()
  const { data: users, isLoading } = api.useGetUsersQuery()

  const user = isLoading || !state ? null : _.find(users, x => x.username === state.username)

  return me.role !== 'admin'
    ? <Navigate to='/' replace/>
    : !!user
      ? <Box sx={{ marginBottom: '50px' }}>
        <Typography variant='h3' sx={{ marginBottom: '20px' }}>
          Benutzer bearbeiten
        </Typography>

        <UserForm user={user}/>
      </Box>
      : null
}

export default UserEdit
