import api from '../app/api'
import AppRestUrls from '../app/constants/AppRestUrls'
import pouchApi from '../app/pouchApi'
import { sliceActions } from '../app/slice'
import { tagTypes as tagTypesPouchApi } from '../app/pouchApi'
import { tagTypes } from '../app/api'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const logout = async () => {
      localStorage.clear()
      dispatch(sliceActions.logout())
      dispatch(api.util.invalidateTags(tagTypes))
      dispatch(pouchApi.util.invalidateTags(tagTypesPouchApi))

      window.open(AppRestUrls.logout(), '_self')
    }

    logout()
  })

  return null
}

export default Logout
