import _ from 'lodash'
import api from '../app/api'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import pouchApi from '../app/pouchApi'
import React from 'react'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const RealEstates = () => {
  const { data: realEstates, isLoading } = pouchApi.useGetRealEstatesQuery()
  const { data: me } = api.useGetMeQuery()
  const navigate = useNavigate()

  return isLoading
    ? null
    : <Box sx={{ marginBottom: '50px'}}>
      <Typography variant='h3'>
        Immobilien
      </Typography>

      <Box sx={{ display: 'flex' }}>
        <Button onClick={() => navigate('/immobilien/neu')} disabled={me.maxRealEstates - realEstates.length <= 0} sx={{ marginLeft: 'auto' }} variant='contained'>
          Neue Immobilie
        </Button>
      </Box>

      <Box sx={{ marginTop: '10px' }}>
        <Typography>Lizenzen: {realEstates.length} von {me.maxRealEstates} vergeben.</Typography>
      </Box>

      {_.map(realEstates, realEstate => (
        <Card key={realEstate._id} sx={{ margin: '30px 0' }} onClick={() => navigate('/immobilien/details', { state: { _id: realEstate._id } })}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Box sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                {realEstate._attachments && realEstate._attachments.photo
                  ? <img src={'data:' + realEstate._attachments.photo.content_type + ';base64, ' + realEstate._attachments.photo.data} style={{ maxWidth: '100%' }} alt='Foto der Immobilie'/>
                  : <Box sx={{ paddingTop: '60%', backgroundColor: 'lightGray', position: 'relative' }}><ImageOutlined sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '50px' }}/></Box>
                }
              </Box>

              <Box sx={{ width: { xs: '100%', md: '70%' }, marginTop: { xs: '10px', md: 0 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Bezeichnung</Typography>
                    <Typography>{realEstate.name}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Anschrift</Typography>
                    <Typography>{realEstate.street + ', ' + realEstate.zip + ' ' + realEstate.city}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
}

export default RealEstates
