import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'

export default function ProtocolPdf ({ title, realEstate, tenant, damages, keys, signaturePads }) {
  const pageMargin = '40px'

  return (
    <Document>
      <Page style={{ margin: pageMargin, fontSize: '14px', fontFamily: 'Helvetica' }}>
        <Text style={{ fontSize: '20px', fontFamily: 'Helvetica-Bold', textAlign: 'center', marginBottom: '20px', marginTop: '10px' }}>
          {title}
        </Text>

        <Text style={{ marginBottom: '5px' }}>
          Immobilie: {realEstate ? realEstate.label : ''}
        </Text>

        <Text style={{ marginBottom: '5px' }}>
          Mieter: {tenant ? tenant.label : ''}
        </Text>

        <Text style={{ marginBottom: '30px' }}>
          Datum: {moment().format('DD.MM.yyyy HH:mm')} Uhr
        </Text>

        <Text style={{ fontSize: '16px', fontFamily: 'Helvetica-Bold', marginBottom: '20px' }}>
          Schäden:
        </Text>

        {!damages || !damages.length > 0
          ? <Text style={{ marginBottom: '30px' }}>
            Keine Schäden festgestellt.
          </Text>
          : null
        }

        {_.map(damages, damage => (
          <View key={damage.id} wrap={false} style={{ marginRight: pageMargin }}>
            {!!damage.images && damage.images.length > 0
              ? <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', gap: '15px', marginRight: pageMargin, marginBottom: '15px' }}>
                {_.map(damage.images, image => (
                  <Image key={image.id} src={image.data} style={{ maxHeight: '200px', maxWidth: '200px', objectFit: 'contain' }}/>
                ))}
              </View>
              : null
            }

            <Text style={{ marginBottom: '5px' }}>Raum: {damage.room ? damage.room.label : ''}</Text>
            <Text style={{ marginBottom: '5px' }}>Beschreibung: {damage.description}</Text>
            <Text style={{ marginBottom: '5px' }}>Verantwortlicher für Behebung: {damage.responsible}</Text>
            <Text style={{ marginBottom: '30px' }}>Frist für Behebung: {damage.dueDate ? moment(damage.dueDate).format('DD.MM.yyyy') : ''}</Text>
          </View>
        ))}

        <View wrap={false}>
          <Text style={{ fontSize: '16px', fontFamily: 'Helvetica-Bold', marginBottom: '20px', marginTop: '10px' }}>
            Übergebene Schlüssel:
          </Text>

          {!keys || !keys.length > 0
            ? <Text style={{ marginBottom: pageMargin }}>
              Keine Schlüssel übergeben.
            </Text>
            : null
          }

          <View style={{ marginBottom: pageMargin }}>
            {_.map(keys, (key, i) => (
              <Text style={{ marginBottom: i < keys.length - 1 ? '5px' : pageMargin }} key={key.id}>
                {key.description + ': ' + key.quantity + ' Schlüssel'}
              </Text>
            ))}
          </View>
        </View>

       {!!signaturePads && signaturePads.length > 0
          ? <View wrap={false} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '25px', marginBottom: pageMargin, marginRight: pageMargin }}>
            {_.map(signaturePads, (pad, i) => (
              <View key={pad.label} style={{ marginBottom: i < signaturePads.length - 1 ? '5px' : pageMargin }}>
                <Image src={pad.src} style={{ maxHeight: '50px', maxWidth: '140px', objectFit: 'contain', marginBottom: '10px' }}/>

                <Text>{pad.label}</Text>
              </View>
            ))}
          </View>
          : null
        }
      </Page>
    </Document>
  )
}
