import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import api from './app/api'
import App from './App'
import pouchApi from './app/pouchApi'
import React from 'react'
import ReactDOM from 'react-dom'
import slice from './app/slice'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { setupListeners } from '@reduxjs/toolkit/query/react'

export const store = configureStore({
  reducer: {
    [slice.name]: slice.reducer,
    [api.reducerPath]: api.reducer,
    [pouchApi.reducerPath]: pouchApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(pouchApi.middleware)
})

setupListeners(store.dispatch)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
