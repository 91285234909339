import _ from 'lodash'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Dialog } from 'common_components'

const ErrorDialog = ({ close, errors, ok, open }) => {
  return (
    <Dialog
      close={close}
      open={open}
      text={
        <List>
          {_.map(errors, error => (
            <ListItem key={error}>
              <ListItemIcon>
                <ReportProblemOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={error}
                sx={{ overflowWrap: "break-word" }}
              />
            </ListItem>
          ))}
        </List>
      }
      ok={ok}
    />
  )
}

export default ErrorDialog
