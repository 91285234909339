import _ from 'lodash'
import countries from '../app/constants/countries'
import ErrorDialog from '../app/components/ErrorDialog'
import pouchApi from '../app/pouchApi'
import React, { Fragment, useState } from 'react'
import { AddSharp, Delete } from '@mui/icons-material'
import { Autocomplete, Button, Box, Card, CardContent, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog } from 'common_components'
import { LoadingButton } from '@mui/lab'
import { v4 as uuid } from 'uuid'

const RealEstatePurchaseForm = ({ realEstate, endEdit }) => {
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()
  const formId = "real-estate-purchase-form"
  const [errors, setErrors] = useState([])
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const [updateConfirmed, setUpdateConfirmed] = useState(false)

  const [sellerCompany, setSellerCompany] = useState(realEstate.sellerCompany)
  const [sellerSalutation, setSellerSalutation] = useState(realEstate.sellerSalutation)
  const [sellerFirstName, setSellerFirstName] = useState(realEstate.sellerFirstName)
  const [sellerLastName, setSellerLastName] = useState(realEstate.sellerLastName)
  const [sellerStreet, setSellerStreet] = useState(realEstate.sellerStreet)
  const [sellerZip, setSellerZip] = useState(realEstate.sellerZip)
  const [sellerCity, setSellerCity] = useState(realEstate.sellerCity)
  const [sellerCountry, setSellerCountry] = useState(realEstate.sellerCountry)

  const [purchaseCurrency, setPurchaseCurrency] = useState(realEstate.purchaseCurrency || 'EUR')
  const [purchasePrice, setPurchasePrice] = useState(realEstate.purchasePrice || [{ id: uuid(), label: 'Objekt', price: '', canDelete: false }])
  const [closingCosts, setClosingCosts] = useState(realEstate.closingCosts || [])
  const [financingLoans, setFinancingLoans] = useState(realEstate.financingLoans || [])

  const deletedPurchasePrice = _.filter(realEstate.purchasePrice, item => !_.some(purchasePrice, x => item.id === x.id))
  const deletedClosingCosts = _.filter(realEstate.closingCosts, item => !_.some(closingCosts, x => item.id === x.id))
  const deletedFinancingLoans = _.filter(realEstate.financingLoans, item => !_.some(financingLoans, x => item.id === x.id))

  return(
    <form
      id={formId}
      onSubmit={async e => {
        e.preventDefault()

        if ((deletedPurchasePrice.length > 0 || deletedClosingCosts.length > 0 || deletedFinancingLoans.length > 0) && !updateConfirmed) {
          setShowUpdateConfirmation(true)
          return
        }

        const res = await putRealEstate({
          ...realEstate,

          sellerCompany,
          sellerSalutation,
          sellerFirstName,
          sellerLastName,
          sellerStreet,
          sellerZip,
          sellerCity,
          sellerCountry,

          purchaseCurrency,
          purchasePrice,
          closingCosts,
          financingLoans
        })

        if (!res.error) endEdit()
      }}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Verkäufer</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Firma'
                  onChange={e => setSellerCompany(e.target.value)}
                  value={sellerCompany}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormControl size="small">
                  <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                  <RadioGroup row value={sellerSalutation} onChange={e => setSellerSalutation(e.target.value)}>
                    <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                    <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                    <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5} lg={4}>
                <TextField
                  fullWidth
                  label='Vorname'
                  onChange={e => setSellerFirstName(e.target.value)}
                  value={sellerFirstName}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={7} lg={4}>
                <TextField
                  fullWidth
                  label='Nachname'
                  onChange={e => setSellerLastName(e.target.value)}
                  value={sellerLastName}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Straße und Hausnummer'
                  onChange={e => setSellerStreet(e.target.value)}
                  value={sellerStreet}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Postleitzahl'
                  onChange={e => setSellerZip(e.target.value)}
                  value={sellerZip}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Ort'
                  onChange={e => setSellerCity(e.target.value)}
                  value={sellerCity}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  onChange={(e, country) => setSellerCountry(country)}
                  options={countries}
                  renderInput={(params) => <TextField {...params} label='Land Verkäufer' variant='standard' style={{ width: '100%' }} />}
                  value={sellerCountry}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Kaufpreis</Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth required variant='standard'>
                  <InputLabel id='currency-label'>Währung</InputLabel>
                  <Select
                    labelId='currency-label'
                    value={purchaseCurrency}
                    label='Währung'
                    onChange={e => setPurchaseCurrency(e.target.value)}
                  >
                    <MenuItem value='EUR'>EUR (€)</MenuItem>
                    <MenuItem value='USD'>USD ($)</MenuItem>
                    <MenuItem value='GBP'>GBP (£)</MenuItem>
                    <MenuItem value='CHF'>CHF</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={8} sx={{ display: { xs: 'none', sm: 'inline' } }}/>

              {_.map(purchasePrice, purchasePriceItem => {
                const changeItem = itemUpdate => {
                  setPurchasePrice(_.map(purchasePrice, x => x.id === purchasePriceItem.id
                    ? {
                      ...purchasePriceItem,
                      ...itemUpdate
                    }
                    : x
                  ))
                }

                return (
                  <Fragment key={purchasePriceItem.id}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        inputProps={{ style: { fontWeight: 'bold' } }}
                        label='Bezeichnung'
                        onChange={e => changeItem({ label: e.target.value })}
                        required
                        value={purchasePriceItem.label}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={9.5} sm={2}>
                      <TextField
                        fullWidth
                        label='Preis'
                        onChange={e => changeItem({ price: e.target.value })}
                        required
                        type='number'
                        value={purchasePriceItem.price}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2.5} sm={1}>
                      {purchasePriceItem.canDelete
                        ? <IconButton onClick={() => setPurchasePrice(_.filter(purchasePrice, x => x.id !== purchasePriceItem.id))}>
                          <Delete />
                        </IconButton>
                        : null
                      }
                    </Grid>
                  </Fragment>
                )
              })}

              <Grid item xs={12}>
                <IconButton onClick={() => setPurchasePrice([...purchasePrice, { id: uuid(), label: '', price: '', canDelete: true }])}>
                  <AddSharp />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold' }}>Kaufnebenkosten</Typography>
              </Grid>

              {_.map(closingCosts, closingCost => {
                const changeClosingCost = closingCostUpdate => {
                  setClosingCosts(_.map(closingCosts, x => x.id === closingCost.id
                    ? {
                      ...closingCost,
                      ...closingCostUpdate
                    }
                    : x
                  ))
                }

                return (
                  <Fragment key={closingCost.id}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        inputProps={{ style: { fontWeight: 'bold' } }}
                        label='Bezeichnung'
                        onChange={e => changeClosingCost({ label: e.target.value })}
                        required
                        value={closingCost.label}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={9.5} sm={2}>
                      <TextField
                        fullWidth
                        label='Preis'
                        onChange={e => changeClosingCost({ price: e.target.value })}
                        required
                        type='number'
                        value={closingCost.price}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={2.5} sm={1}>
                      <IconButton onClick={() => setClosingCosts(_.filter(closingCosts, x => x.id !== closingCost.id))}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Fragment>
                )
              })}

              <Grid item xs={12}>
                <IconButton onClick={() => setClosingCosts([...closingCosts, { id: uuid(), label: '', price: '' }])}>
                  <AddSharp />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Finanzierungsdarlehen</Typography>
              </Grid>

              {_.map(financingLoans, financingLoan => {
                const changeLoan = loanUpdate => {
                  setFinancingLoans(_.map(financingLoans, x => x.id === financingLoan.id
                    ? {
                      ...financingLoan,
                      ...loanUpdate
                    }
                    : x
                  ))
                }

                return (
                  <Grid item xs={12} container spacing={2} key={financingLoan.id}>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Bank'
                        onChange={e => changeLoan({ bank: e.target.value })}
                        required
                        value={financingLoan.bank}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Ansprechpartner'
                        onChange={e => changeLoan({ contact: e.target.value })}
                        value={financingLoan.contact}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Darlehenssumme'
                        onChange={e => changeLoan({ amount: e.target.value })}
                        required
                        type='number'
                        value={financingLoan.amount}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <FormControl fullWidth required variant='standard'>
                        <InputLabel id='loan-currency-label'>Währung</InputLabel>
                        <Select
                          labelId='currency-label'
                          value={financingLoan.currency}
                          label='Währung'
                          onChange={e => changeLoan({ currency: e.target.value })}
                        >
                          <MenuItem value='EUR'>EUR (€)</MenuItem>
                          <MenuItem value='USD'>USD ($)</MenuItem>
                          <MenuItem value='GBP'>GBP (£)</MenuItem>
                          <MenuItem value='CHF'>CHF</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <DatePicker
                        label='Zinsbindungsfrist'
                        mask='__.__.____'
                        onChange={date => changeLoan({ fixedInterestPeriod: date })}
                        renderInput={params => <TextField {...params} variant='standard' style={{ width: '100%' }} inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }}/>}
                        value={financingLoan.fixedInterestPeriod}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Zinssatz in %'
                        onChange={e => changeLoan({ interestRate: e.target.value })}
                        type='number'
                        value={financingLoan.interestRate}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Anfängliche Tilgung in %'
                        onChange={e => changeLoan({ initialAcquittance: e.target.value })}
                        type='number'
                        value={financingLoan.initialAcquittance}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Annuität in %'
                        onChange={e => changeLoan({ annuity: e.target.value })}
                        type='number'
                        value={financingLoan.annuity}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Jährliche Sondertilgungsrate'
                        onChange={e => changeLoan({ annualUnscheduledRepayment: e.target.value })}
                        type='number'
                        value={financingLoan.annualUnscheduledRepayment}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2} sx={{ display: 'flex' }}>
                      <IconButton onClick={() => setFinancingLoans(_.filter(financingLoans, x => x.id !== financingLoan.id))} sx={{ marginLeft: 'auto', marginRight: '15px' }}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <IconButton onClick={() => setFinancingLoans([...financingLoans, { id: uuid(), bank: '', contact: '', amount: '', currency: 'EUR', fixedInterestPeriod: null, interestRate: 0, initialAcquittance: 0, annuity: 0, annualUnscheduledRepayment: 0 }])}>
                  <AddSharp />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            Abbrechen
          </Button>

          <LoadingButton loading={isLoading} variant='outlined' type='submit'>
            Speichern
          </LoadingButton>
        </Box>
      </Box>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />

      <Dialog
        close={() => setShowUpdateConfirmation(false)}
        open={showUpdateConfirmation}
        title="Bestätigung"
        text="Sind Sie sicher, dass die Änderungen inkl. dem Löschen von Kontakten übernommen werden sollen?"
        no={() => setShowUpdateConfirmation(false)}
        noText="Zurück"
        submit={() => {
          setShowUpdateConfirmation(false)
          setUpdateConfirmed(true)
        }}
        submitFormId={formId}
        submitText="Speichern"
      />
    </form>
  )
}

export default RealEstatePurchaseForm
