import api from '../app/api'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import { Button, Box, Grid, Typography } from '@mui/material'

const RealEstateLocationBox = ({ realEstate, onEditClick }) => {
  const { data: me } = api.useGetMeQuery()

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Box sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
        {realEstate._attachments && realEstate._attachments.photo
          ? <img src={'data:' + realEstate._attachments.photo.content_type + ';base64, ' + realEstate._attachments.photo.data} style={{ maxWidth: '100%' }} alt='Foto der Immobilie'/>
          : <Box sx={{ paddingTop: '60%', backgroundColor: 'lightGray', position: 'relative' }}><ImageOutlined sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '50px' }}/></Box>
        }
      </Box>

      <Box sx={{ width: { xs: '100%', md: '70%' }, marginTop: { xs: '10px', md: 0 }, marginBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Bezeichnung</Typography>
            <Typography>{realEstate.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Interne Bezeichnung</Typography>
            <Typography>{realEstate.internalName}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Etage</Typography>
            <Typography>{realEstate.floor}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
            <Typography>{realEstate.street}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
            <Typography>{realEstate.zip}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
            <Typography>{realEstate.city}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
            <Typography>{realEstate.country.label}</Typography>
          </Grid>
        </Grid>
      </Box>

      {me.role === 'user'
        ? null
        : <Button onClick={onEditClick} variant='outlined' sx={{ marginLeft: 'auto' }}>
          Bearbeiten
        </Button>
      }
    </Box>
  )
}

export default RealEstateLocationBox
