import api from '../app/api'
import ErrorDialog from '../app/components/ErrorDialog'
import React, { useState } from 'react'
import { Box, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'

const PasswordEdit = () => {
  const [putPassword, { isLoading }] = api.usePutPasswordMutation()
  const navigate = useNavigate()
  const [errors, setErrors] = useState([])

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  return (
    <Box sx={{ marginBottom: '50px' }}>
      <Typography variant='h3'>
        Passwort ändern
      </Typography>

      <form
        onSubmit={async e => {
          e.preventDefault()

          let errors = []

          if (password !== passwordConfirmation) errors.push('Die Passwörter stimmen nicht überein!')
          if (password.length < 8) errors.push('Das Passwort muss mindestens 8 Zeichen haben.')
          if (password.length > 20) errors.push('Das Passwort darf maximal 20 Zeichen haben.')
          if (!/[a-z]+/.test(password)) errors.push('Das Passwort muss mindestens einen Kleinbuchstaben enthalten.')
          if (!/[A-Z]+/.test(password)) errors.push('Das Passwort muss mindestens einen Großbuchstaben enthalten.')
          if (!/[0-9]+/.test(password)) errors.push('Das Passwort muss mindestens eine Nummer enthalten.')
          if (!/[^a-zA-Z0-9]+/.test(password)) errors.push('Das Passwort muss mindestens ein Sonderzeichen enthalten.')

          if (errors.length > 0) {
            setErrors(errors)
            return
          }

          const res = await putPassword(password)

          if (!res.error) navigate('/')
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Passwort'
                  onChange={e => setPassword(e.target.value)}
                  type='password'
                  value={password}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Passwort wiederholen'
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  type='password'
                  value={passwordConfirmation}
                  variant='standard'
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <LoadingButton loading={isLoading} sx={{ marginLeft: 'auto' }} type='submit' variant='contained'>
              Speichern
            </LoadingButton>
          </CardActions>
        </Card>
      </form>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />
    </Box>
  )
}

export default PasswordEdit
