import _ from 'lodash'
import api from '../app/api'
import getCurrencyString from '../app/utils/getCurrencyString'
import getDateString from '../app/utils/getDateString'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import TenantCard from '../tenants/TenantCard'
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog, DialogForm } from 'common_components'

const RealEstateTenantBox = ({ realEstate, onEditClick }) => {
  const { data: tenants, isLoading } = pouchApi.useGetTenantsQuery()
  const { data: me } = api.useGetMeQuery()
  const [postTenant, { isLoading: isCreating }] = pouchApi.usePostTenantMutation()

  const sortedTenants = _.sortBy(
    _.filter(tenants, x => x.realEstateId === realEstate._id),
    'rentalStart'
  ).reverse()

  const currentTenant = _.first(_.filter(sortedTenants, x => moment(x.rentalStart).isBefore(moment())))
  const [showAllTenants, setShowAllTenants] = useState(false)
  const [showDetails, setShowDetails] = useState(null)
  const [ownerOccupation, setOwnerOccupation] = useState(null)

  return isLoading
    ? null
    : <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Aktueller Mieter</Typography>
            </Grid>

            {currentTenant
              ? currentTenant.ownerOccupation
                ? <>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold' }}>Selbstnutzung</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Start</Typography>
                    <Typography>{getDateString(currentTenant.rentalStart)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Ende</Typography>
                    <Typography>{getDateString(currentTenant.rentalEnd)}</Typography>
                  </Grid>
                </>
                : <>
                  {_.map(currentTenant.persons, person => (
                    <Grid item xs={12} container spacing={2} key={person.id}>
                      {person.company
                        ? <Grid item xs={12}>
                          <Typography variant='body2' sx={{ opacity: '50%' }}>Firma</Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>{person.company}</Typography>
                        </Grid>
                        : null
                      }

                      <Grid item xs={12} md={5}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{person.firstName}</Typography>
                      </Grid>

                      <Grid item xs={12} md={7}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{person.lastName}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
                        <Typography>{person.street}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={2}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
                        <Typography>{person.zip}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
                        <Typography>{person.city}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={4}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
                        <Typography>{person.country.label}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={3}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
                        <Typography><a href={'tel:' + person.phone}>{person.phone}</a></Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} lg={5}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
                        <Typography><a href={'tel:' + person.phoneMobile}>{person.phoneMobile}</a></Typography>
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                        <Typography><a href={'mailto:' + person.email}>{person.email}</a></Typography>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item xs={12} container spacing={2}>
                    {_.map(currentTenant.costs, cost => (
                      <Grid item xs={12} sm={6} lg={3} key={cost.id}>
                        <Typography variant='body2' sx={{ opacity: '50%' }}>{cost.label}</Typography>
                        <Typography>{getCurrencyString(cost.value, currentTenant.currency)}</Typography>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Kaution</Typography>
                    <Typography>{getCurrencyString(currentTenant.deposit, currentTenant.currency)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Art der Kaution</Typography>
                    <Typography>{currentTenant.depositType}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Status Kaution</Typography>
                    <Typography>{currentTenant.depositState}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Zahlungsdatum Kaution</Typography>
                    <Typography>{getDateString(currentTenant.depositPaymentDate)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Mietbeginn</Typography>
                    <Typography>{getDateString(currentTenant.rentalStart)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Mietende</Typography>
                    <Typography>{getDateString(currentTenant.rentalEnd)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Mieterdauer</Typography>
                    <Typography>{currentTenant.rentalLimited ? 'befristet' : 'unbefristet'}</Typography>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>IBAN</Typography>
                    <Typography>{currentTenant.iban}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} lg={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>BIC</Typography>
                    <Typography>{currentTenant.bic}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} lg={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Bank</Typography>
                    <Typography>{currentTenant.bank}</Typography>
                  </Grid>
                </>
              : <Grid item xs={12}>
                <Typography sx={{ opacity: '60%' }}>Es gibt keinen aktuellen und keine vergangenen Mieter.</Typography>
              </Grid>
            }
            </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={2} sx={{ marginTop: '1px' }}>
        {sortedTenants.length > 0
          ? <Grid item xs={12} sm='auto'>
            <Button onClick={() => setShowAllTenants(true)} variant='contained' sx={{ float: 'right' }}>
              Alle Mieter
            </Button>
          </Grid>
          : null
        }

        {!currentTenant || !currentTenant.ownerOccupation
          ? <Grid item xs={12} sm='auto'>
            <Button onClick={() => setOwnerOccupation({ ownerOccupation: true, realEstateId: realEstate._id, rentalStart: null, rentalEnd: null })} variant='contained' sx={{ float: 'right' }}>
              Selbstnutzung
            </Button>
          </Grid>
          : null
        }

        {currentTenant && me.role !== 'user'
          ? <Grid item xs={12} sm='auto' sx={{ marginLeft: 'auto' }}>
            <Button onClick={onEditClick} variant='outlined' sx={{ float: 'right' }}>
              Bearbeiten
            </Button>
          </Grid>
          : null
        }
      </Grid>

      <Dialog
        close={() => setShowAllTenants(false)}
        maxWidth='lg'
        ok={() => setShowAllTenants(false)}
        okText='Schließen'
        open={!!showAllTenants}
        text={_.map(sortedTenants, tenant => (
          tenant.ownerOccupation
            ? <Card
              key={tenant._id}
              sx={{ margin: '30px 0' }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold' }}>Selbstnutzung</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Start</Typography>
                    <Typography>{getDateString(tenant.rentalStart)}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Ende</Typography>
                    <Typography>{getDateString(tenant.rentalEnd)}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            : <TenantCard
              key={tenant._id}
              setShowDetails={setShowDetails}
              showAll={showDetails === tenant._id}
              showRealEstate={false}
              tenant={tenant}
            />
          )
        )}
        title='Alle Mieter'
      />

      <DialogForm
        formFields={ownerOccupation
          ? [
            <DatePicker
              label='Start'
              mask='__.__.____'
              value={ownerOccupation.rentalStart}
              onChange={date => setOwnerOccupation({ ...ownerOccupation, rentalStart: date })}
              renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
            />,

            <DatePicker
              label='Ende'
              mask='__.__.____'
              value={ownerOccupation.rentalEnd}
              onChange={date => setOwnerOccupation({ ...ownerOccupation, rentalEnd: date })}
              renderInput={(params) => <TextField {...params} variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
            />
          ]
          : null
        }
        getErrors={() => {
          if (moment(ownerOccupation.rentalStart).get('year') < 1900 || Number.isNaN(moment(ownerOccupation.rentalStart).get('year'))) {
            return ['Bitte überprüfen Sie Ihre eingegeben Daten. Das Datum ist nicht korrekt.']
          }
          if (!!ownerOccupation.rentalEnd && (moment(ownerOccupation.rentalEnd).get('year') < 1900 || Number.isNaN(moment(ownerOccupation.rentalEnd).get('year')))) {
            return ['Bitte überprüfen Sie Ihre eingegeben Daten. Das Datum ist nicht korrekt.']
          }

          return null
        }}
        isLoading={isCreating}
        onClose={() => setOwnerOccupation(null)}
        onSubmit={async () => {
          const res = await postTenant(ownerOccupation)

          if (!res.error) setOwnerOccupation(null)
        }}
        open={!!ownerOccupation}
        title='Selbstnutzung hinzufügen'
      />
    </Box>
}

export default RealEstateTenantBox
