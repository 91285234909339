import ErrorDialog from '../app/components/ErrorDialog'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Grid, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LoadingButton } from '@mui/lab'

const TenantOwnerOccupationForm = ({ endEdit, ownerOccupation, title }) => {
  const [putTenant, { isLoading: isUpdating }] = pouchApi.usePutTenantMutation()

  const [errors, setErrors] = useState([])

  const [rentalStart, setRentalStart] = useState(ownerOccupation.rentalStart)
  const [rentalEnd, setRentalEnd] = useState(ownerOccupation.rentalEnd)

  return <Box>
    <form
      onSubmit={async e => {
        e.preventDefault()

        let dateError = false
        if (rentalStart) dateError = dateError || moment(rentalStart).get('year') < 1900 || Number.isNaN(moment(rentalStart).get('year'))
        if (rentalEnd) dateError = dateError || moment(rentalEnd).get('year') < 1900 || Number.isNaN(moment(rentalEnd).get('year'))

        if (dateError) {
          setErrors(['Jedes Datum muss im Format dd.mm.yyyy eingegeben werden. Das Jahr muss nach 1900 liegen. Bitte überprüfen Sie Ihre Eingaben!'])
          return
        }

        const ownerOccupationUpdate = {
          ...ownerOccupation,
          ownerOccupation: true,
          rentalStart,
          rentalEnd
        }

        const res = await putTenant(ownerOccupationUpdate)

        if (!res.error) endEdit()
      }}
    >
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            {title
              ? <Grid item xs={12}>
                <Typography variant='h6'>{title}</Typography>
              </Grid>
              : null
            }

            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>Selbstnutzung</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                clearable
                label='Start'
                mask='__.__.____'
                onChange={date => setRentalStart(date)}
                renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
                value={rentalStart}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                clearable
                label='Ende'
                mask='__.__.____'
                onChange={date => setRentalEnd(date)}
                renderInput={(params) => <TextField {...params} variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
                value={rentalEnd}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            Abbrechen
          </Button>

          <LoadingButton loading={isUpdating} type='submit' variant='outlined'>
            Speichern
          </LoadingButton>
        </CardActions>
      </Card>
    </form>

    <ErrorDialog
      close={() => setErrors([])}
      open={errors.length > 0}
      errors={errors}
      ok={() => setErrors([])}
    />
  </Box>
}

export default TenantOwnerOccupationForm
