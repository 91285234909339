import api from '../app/api'
import UserForm from '../users/UserForm'
import { Box, Typography } from '@mui/material'
import { Navigate } from 'react-router-dom'

const UserCreate = () => {
  const { data: me } = api.useGetMeQuery()
  const { data: users, isLoading } = api.useGetUsersQuery()

  return isLoading
    ? null
    : me.role !== 'admin'
      ? <Navigate to='/' replace/>
      : me.maxUsers - users.length <= 0
        ? <Navigate to='/benutzer' replace/>
        : <Box sx={{ marginBottom: '50px' }}>
          <Typography variant='h3' sx={{ marginBottom: '20px' }}>
            Benutzer erstellen
          </Typography>

          <UserForm/>
        </Box>
}

export default UserCreate
