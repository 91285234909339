import _ from 'lodash'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import TenantForm from '../tenants/TenantForm'
import TenantOwnerOccupationForm from '../tenants/TenantOwnerOccupationForm'

const RealEstateTenantForm = ({ realEstate, endEdit }) => {
  const { data: tenants, isLoading } = pouchApi.useGetTenantsQuery()
  const currentTenant = _.last(_.sortBy(
    _.filter(tenants, x => x.realEstateId === realEstate._id && moment(x.rentalStart).isBefore(moment())),
    'rentalStart'
  ))

  return isLoading
    ? null
    : currentTenant.ownerOccupation
      ? <TenantOwnerOccupationForm
        endEdit={endEdit}
        ownerOccupation={currentTenant}
        title='Aktueller Mieter'
        />
      : <TenantForm
        buttonVariant='outlined'
        onCancel={endEdit}
        onDataSaved={endEdit}
        showRealEstate={false}
        tenant={currentTenant}
        title='Aktueller Mieter'
      />
}

export default RealEstateTenantForm
