import React from 'react'
import { Domain, DomainAdd, DomainDisabled, FamilyRestroom, FileCopy, Group, Home, Settings } from '@mui/icons-material'

const AppMenuLinks = (me) => {
  let links = [
    { href: '/immobilien', text: 'Immobilien', icon: <Home/> },
    { href: '/mieter', text: 'Mieter', icon: <FamilyRestroom/> },
    { type: 'collapse', text: 'Dokumente', icon: <FileCopy/>, subItems: [
      {href: '/dokument/uebergabe/neu', text: 'Wohnungsübergabe-Protokoll', icon: <DomainAdd/>},
      {href: '/dokument/rueckgabe/neu', text: 'Wohnungsrückgabe-Protokoll', icon: <DomainDisabled/>},
      {href: '/dokument/bestaetigung/neu', text: 'Wohnungsgeber-Bestätigung', icon: <Domain/>},
    ] }
  ]

  if (me.role === 'admin') {
    links = [
      ...links,
      { href: '/benutzer', text: 'Benutzer', icon: <Group/> },
      { href: '/einstellungen', text: 'Einstellungen', icon: <Settings/> }
    ]
  }
  return links
}

export default AppMenuLinks
