import _ from 'lodash'
import AuthPage from './app/components/AuthPage'
import DocumentConfirmationCreate from './pages/DocumentConfirmationCreate'
import DocumentHandoverCreate from './pages/DocumentHandoverCreate'
import DocumentReturnCreate from './pages/DocumentReturnCreate'
import Login from './pages/Login'
import LoginError from './pages/LoginError'
import Logout from './pages/Logout'
import Page from './app/components/Page'
import PasswordEdit from './pages/PasswordEdit'
import React from 'react'
import RealEstateCreate from './pages/RealEstateCreate'
import RealEstateDetails from './pages/RealEstateDetails'
import RealEstates from './pages/RealEstates'
import Settings from './pages/Settings'
import TenantCreate from './pages/TenantCreate'
import TenantEdit from './pages/TenantEdit'
import Tenants from './pages/Tenants'
import UserCreate from './pages/UserCreate'
import UserEdit from './pages/UserEdit'
import Users from './pages/Users'
import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom'

function App() {
  const authPages = [
    { path: '/benutzer', component: <Users/> },
    { path: '/benutzer/bearbeiten', component: <UserEdit/> },
    { path: '/benutzer/neu', component: <UserCreate/> },
    { path: '/einstellungen', component: <Settings/> },
    { path: '/immobilien', component: <RealEstates/> },
    { path: '/immobilien/details', component: <RealEstateDetails/> },
    { path: '/immobilien/neu', component: <RealEstateCreate/> },
    { path: '/mieter', component: <Tenants/> },
    { path: '/mieter/bearbeiten', component: <TenantEdit/> },
    { path: '/mieter/neu', component: <TenantCreate/> },
    { path: '/passwort', component: <PasswordEdit/> },
    { path: '/dokument/uebergabe/neu', component: <DocumentHandoverCreate/> },
    { path: '/dokument/rueckgabe/neu', component: <DocumentReturnCreate/> },
    { path: '/dokument/bestaetigung/neu', component: <DocumentConfirmationCreate/> }
  ]

  return (
    <Router>
      <Routes>
        {_.map(authPages, page => (
          <Route key={page.path} path={page.path} element={<AuthPage> <Page> {page.component} </Page> </AuthPage>}/>
        ))}
        <Route path='/' element={<Navigate to='/immobilien' replace/>}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/login-error' element={<LoginError />}/>
        <Route path='/logout' element={<Logout />}/>
      </Routes>
    </Router>
  )
}

export default App
