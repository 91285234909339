import _ from 'lodash'
import api from '../app/api'
import getCurrencyString from '../app/utils/getCurrencyString'
import getDateString from '../app/utils/getDateString'
import React from 'react'
import { Button, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'

const RealEstatePurchaseBox = ({ realEstate, onEditClick }) => {
  const { data: me } = api.useGetMeQuery()

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Verkäufer</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Firma</Typography>
              <Typography>{realEstate.sellerCompany}</Typography>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Anrede</Typography>
              <Typography>{realEstate.sellerSalutation}</Typography>
            </Grid>

            <Grid item xs={12} sm={4.5}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
              <Typography>{realEstate.sellerFirstName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
              <Typography>{realEstate.sellerLastName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
              <Typography>{realEstate.sellerStreet}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
              <Typography>{realEstate.sellerZip}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
              <Typography>{realEstate.sellerCity}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
              <Typography>{realEstate.sellerCountry ? realEstate.sellerCountry.label : ''}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Kaufpreis</Typography>
            </Grid>

            {_.map(realEstate.purchasePrice, purchasePriceItem => (
              <Grid item xs={12} sm={6} md={3} key={purchasePriceItem.id}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>{purchasePriceItem.label}</Typography>
                <Typography>{getCurrencyString(purchasePriceItem.price, realEstate.purchaseCurrency)}</Typography>
              </Grid>
            ))}

            <Grid item xs={12} sx={{ marginTop: '8px' }}>
              <Typography variant='h6'>Kaufnebenkosten</Typography>
            </Grid>

            {_.map(realEstate.closingCosts, closingCost => (
              <Grid item xs={12} sm={6} md={3} key={closingCost.id}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>{closingCost.label}</Typography>
                <Typography>{getCurrencyString(closingCost.price, realEstate.purchaseCurrency)}</Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Finanzierungsdarlehen</Typography>
            </Grid>

            {_.reduce(_.map(realEstate.financingLoans, financingLoan => (
              <Grid item xs={12} container spacing={2} key={financingLoan.id}>
                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Bank</Typography>
                  <Typography>{financingLoan.bank}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Ansprechpartner</Typography>
                  <Typography>{financingLoan.contact}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Darlehenssumme</Typography>
                  <Typography>{getCurrencyString(financingLoan.amount, financingLoan.currency)}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Zinsbindungsfrist</Typography>
                  <Typography>{getDateString(financingLoan.fixedInterestPeriod)}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Zinssatz</Typography>
                  <Typography>{financingLoan.interestRate + ' %'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Anfängliche Tilgung</Typography>
                  <Typography>{financingLoan.initialAcquittance + ' %'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Annuität</Typography>
                  <Typography>{financingLoan.annuity + ' %'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Jährliche Sondertilgungsrate</Typography>
                  <Typography>{financingLoan.annualUnscheduledRepayment + ' %'}</Typography>
                </Grid>
              </Grid>
            )), (prev, next) => (
              [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
            ))}
          </Grid>
        </CardContent>
      </Card>

      {me.role === 'user'
        ? null
        : <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={onEditClick} variant='outlined' sx={{ marginLeft: 'auto' }}>
            Bearbeiten
          </Button>
        </Box>
      }
    </Box>
  )
}

export default RealEstatePurchaseBox
