import _ from 'lodash'
import pouchApi from '../app/pouchApi'
import React from 'react'
import TenantForm from '../tenants/TenantForm'
import { Box, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

const TenantEdit = () => {
  const { state } = useLocation()
  const { data: tenants, isLoading } = pouchApi.useGetTenantsQuery()
  const navigate = useNavigate()

  const tenant = isLoading || !state ? null : _.find(tenants, x => x._id === state._id)

  return !!tenant
    ? <Box sx={{ marginBottom: '50px' }}>
      <Typography variant='h3' sx={{ marginBottom: '20px' }}>
        Mieter bearbeiten
      </Typography>

      <TenantForm tenant={tenant} onCancel={() => navigate('/mieter')}/>
    </Box>
    : null
}

export default TenantEdit
