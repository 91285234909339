import api from '../app/api'
import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { Info } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'

const UserForm = ({ user }) => {
  const [putUser, { isLoading: isUpdating }] = api.usePutUserMutation()
  const [postUser, { isLoading: isCreating }] = api.usePostUserMutation()
  const [deactivateUser, { isLoading: isDeactivating }] = api.useDeactivateUserMutation()
  const navigate = useNavigate()

  const [showRoleInfo, setShowRoleInfo] = useState(false)
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState(false)

  const [firstName, setFirstName] = useState(user ? user.firstName : '')
  const [lastName, setLastName] = useState(user ? user.lastName : '')
  const [email, setEmail] = useState(user ? user.email : '')
  const [role, setRole] = useState(user ? user.role : 'admin')

  return(
    <form
      onSubmit={async e => {
        e.preventDefault()

        const userUpdate = {
          ...user,
          firstName,
          lastName,
          email,
          role
        }

        const res = !!user ? await putUser(userUpdate) : await postUser(userUpdate)

        if (!res.error) navigate('/benutzer')
      }}
    >
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Vorname'
                onChange={e => setFirstName(e.target.value)}
                required
                value={firstName}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label='Nachname'
                onChange={e => setLastName(e.target.value)}
                required
                value={lastName}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label='E-Mail'
                onChange={e => setEmail(e.target.value)}
                required
                type='email'
                value={email}
                variant='standard'
              />
            </Grid>

            <Grid item xs={10} sm={11}>
              <FormControl fullWidth required variant='standard'>
                <InputLabel id='role-label'>Rolle</InputLabel>
                <Select
                  label='Rolle'
                  labelId='role-label'
                  onChange={e => setRole(e.target.value)}
                  value={role}
                >
                  <MenuItem value='admin'>Administrator</MenuItem>
                  <MenuItem value='manager'>Manager</MenuItem>
                  <MenuItem value='user'>Benutzer</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => setShowRoleInfo(true)}>
                <Info/>
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          {user
            ? <LoadingButton loading={isDeactivating} onClick={() => setShowDeactivateConfirmation(true)} variant='outlined' sx={{ marginRight: 'auto' }}>
              Deaktvieren
            </LoadingButton>
            : null
          }

          {user
            ? <Button
              onClick={() => navigate('/benutzer')}
              disabled={isCreating || isUpdating || showDeactivateConfirmation || isDeactivating}
              sx={{ marginLeft: 'auto', marginRight: '10px' }}
              variant='contained'
            >
              Abbrechen
            </Button>
            : null
          }

          <LoadingButton disabled={showDeactivateConfirmation || isDeactivating} loading={isCreating || isUpdating} type='submit' variant='contained' sx={{ marginLeft: 'auto' }}>
            Speichern
          </LoadingButton>
        </CardActions>
      </Card>

      <Dialog
        close={() => setShowRoleInfo(false)}
        ok={() => setShowRoleInfo(false)}
        okText='Schließen'
        open={!!showRoleInfo}
        text={
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>Administrator</Typography>
            <Typography sx={{ marginBottom: '15px' }}>Ein Administrator hat uneingeschränkten Zugriff auf die Software.</Typography>

            <Typography sx={{ fontWeight: 'bold' }}>Manager</Typography>
            <Typography sx={{ marginBottom: '15px' }}>
              Ein Manager hat uneingeschränkten Zugriff auf die Immobilien und Mieter.
              Er hat keinen Zugriff auf die Benutzerverwaltung und die Einstellungen
            </Typography>

            <Typography sx={{ fontWeight: 'bold' }}>Benutzer</Typography>
            <Typography>
              Ein Benutzer hat Lese-Zugriff auf die Immobilien und Mieter.
              Er kann neue Daten anlegen, jedoch keine Bearbeitungen vornehmen.
              Er hat nur auf Ordner mit unbeschränkter Einsicht und deren Inhalte Zugriff.
            </Typography>
          </Box>
        }
        title='Rollen'
      />

      <Dialog
        close={() => setShowDeactivateConfirmation(false)}
        no={() => setShowDeactivateConfirmation(false)}
        noText='Abbrechen'
        open={showDeactivateConfirmation}
        text='Sind Sie sicher, dass Sie diesen Benutzer deaktivieren möchten? Der Benutzer hat anschließend keinen Zugriff mehr auf imoto.'
        title='Benutzer deaktivieren'
        yes={async () => {
          setShowDeactivateConfirmation(false)
          const res = await deactivateUser(user)
          if (!res.error) navigate('/benutzer')
        }}
        yesText='Deaktivieren'
      />
    </form>
  )
}

export default UserForm
