import api from '../api'
import AppLink from './AppLink'
import AppMenuLinks from '../constants/AppMenuLinks'
import AppRestUrls from '../constants/AppRestUrls'
import ErrorDialog from './ErrorDialog'
import React, { useState } from 'react'
import { Badge } from '@mui/material'
import { Help, VpnKey } from '@mui/icons-material'
import { Page as CommonPage, HelpDialog } from 'common_components'
import { sliceActions } from '../slice'
import { useDispatch, useSelector } from 'react-redux'

const Page = ({children}) => {
  const { data: me } = api.useGetMeQuery()
  const { data: newHelpdeskMessages } = api.useGetNewHelpdeskMessagesQuery(null, { refetchOnFocus: true, pollingInterval: 600000 }) //refetch every 10 minutes
  const [showHelp, setShowHelp] = useState(false)

  const error = useSelector((state) => state.app.error)
  const dispatch = useDispatch()

  return (
    <CommonPage
      drawerWidth={260}
      imgAlt='ProntoWeb GmbH Logo'
      imgMaxWidth={{ xs: '100px', sm: '130px' }}
      imgPadding={{ xs: '6px 2px', sm: '2px 15px' }}
      imgSrc='/logo-imoto.jpg'
      Link={AppLink}
      mainMenuLinks={AppMenuLinks(me)}
      me={me}
      secondaryMenuLinks={[
        {
          Icon: <Badge badgeContent={newHelpdeskMessages || 0} color='error' variant='dot'><Help/></Badge>,
          onClick: () => setShowHelp(!showHelp),
          selected: showHelp,
          text: 'Hilfe',
          type: 'button'
        },
        {
          href: '/passwort',
          Icon: <VpnKey/>,
          text: 'Passwort ändern',
          type: 'link'
        }
      ]}
    >
      {children}

      <ErrorDialog
        close={() => dispatch(sliceActions.setError(null))}
        errors={!!error
          ? error.status === 409
            ? ['Es gab einen Konflikt beim Speichern Ihrer Daten. Ihre Angaben konnten nicht übernommen werden. Versuchen Sie es erneut oder laden Sie die Seite neu.']
            : ['Es gab einen unterwarteten Fehler. Die Aktion konnte nicht ausgeführt werden. Bitte versuchen Sie es erneut. (' + JSON.stringify(error) + ')']
          : null
        }
        ok={() => dispatch(sliceActions.setError(null))}
        open={!!error}
      />

      <HelpDialog
        open={showHelp}
        close={() => {
          dispatch(api.util.invalidateTags(['newHelpdeskMessages']))
          setShowHelp(false)
        }}
        supportUrl={AppRestUrls.supportForApp()}
      />
    </CommonPage>
  )
}

export default Page
