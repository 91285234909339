import _ from 'lodash'
import api from '../app/api'
import getDateString from '../app/utils/getDateString'
import MeterDetailsBox from './MeterDetailsBox'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Button, Box, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog, DialogForm } from 'common_components'

const RealEstateMeterReadingBox = ({ realEstate, onEditClick }) => {
  const { data: me } = api.useGetMeQuery()
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()
  const [newMeterReading, setNewMeterReading] = useState(null)
  const [showHistory, setShowHistory] = useState(null)

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Wasser</Typography>
            </Grid>

            {_.reduce(_.map(_.filter(realEstate.meters, x => x.type === 'water'), meter => (
              <MeterDetailsBox
                key={meter.id}
                meter={meter}
                onAddNewMeterReading={() => setNewMeterReading({ currentMeter: meter, date: moment(), value: 0 })}
              />
            )), (prev, next) => (
              [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
            ))}

            <Grid item xs={12}>
              <Button onClick={() => setShowHistory('water')} variant='outlined'>
                Historie
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Strom</Typography>
            </Grid>

            {_.reduce(_.map(_.filter(realEstate.meters, x => x.type === 'electricity'), meter => (
              <MeterDetailsBox
                key={meter.id}
                meter={meter}
                onAddNewMeterReading={() => setNewMeterReading({ currentMeter: meter, date: moment(), value: 0 })}
              />
            )), (prev, next) => (
              [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
            ))}

            <Grid item xs={12}>
              <Button onClick={() => setShowHistory('electricity')} variant='outlined'>
                Historie
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Heizung</Typography>
            </Grid>

            {_.reduce(_.map(_.filter(realEstate.meters, x => x.type === 'radiator'), meter => (
              <MeterDetailsBox
                key={meter.id}
                meter={meter}
                onAddNewMeterReading={() => setNewMeterReading({ currentMeter: meter, date: moment(), value: 0 })}
              />
            )), (prev, next) => (
              [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
            ))}

            <Grid item xs={12}>
              <Button onClick={() => setShowHistory('radiator')} variant='outlined'>
                Historie
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {me.role === 'user'
        ? null
        : <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={onEditClick} variant='outlined' sx={{ marginLeft: 'auto' }}>
            Bearbeiten
          </Button>
        </Box>
      }

      <DialogForm
        cancel={() => setNewMeterReading(null)}
        cancelButtonText='Abbrechen'
        formFields={newMeterReading
          ? [
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Bezeichnung</Typography>
                <Typography>{newMeterReading.currentMeter.name}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Zählernummer</Typography>
                <Typography>{newMeterReading.currentMeter.number || '-'}</Typography>
              </Grid>
            </Grid>,

            <DatePicker
              label='Datum'
              mask='__.__.____'
              value={newMeterReading.date}
              onChange={date => setNewMeterReading({ ...newMeterReading, date })}
              renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
            />,

            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  label='Zählerstand'
                  onChange={e => setNewMeterReading({ ...newMeterReading, value: e.target.value })}
                  required
                  type='number'
                  value={newMeterReading.value}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Einheit</Typography>
                <Typography>{newMeterReading.currentMeter.unit}</Typography>
              </Grid>
            </Grid>
          ]
          : null
        }
        getErrors={() => {
          if (moment(newMeterReading.date).get('year') < 1900 || Number.isNaN(moment(newMeterReading.date).get('year'))) {
            return ['Bitte überprüfen Sie Ihre eingegeben Daten. Das Datum ist nicht korrekt.']
          }

          const currentValue = _.last(_.sortBy(newMeterReading.currentMeter.values, x => moment(x.date)))
          if (moment(currentValue.date).isAfter(moment(newMeterReading.date))) {
            return ['Es gibt bereits einen Zählerstand mit aktuellerem Datum. Bitte geben Sie ein Datum ein, das später ist.']
          }
          if (currentValue.value >= newMeterReading.value) {
            return ['Es gibt bereits einen Zählerstand mit höherem Wert. Bitte geben Sie einen Zählerstand ein, der größer ist.']
          }

          return null
        }}
        isLoading={isLoading}
        onClose={() => setNewMeterReading(null)}
        open={!!newMeterReading}
        onSubmit={async () => {
          const res = await putRealEstate({
            ...realEstate,
            meters: _.map(
              realEstate.meters,
              x => x.id === newMeterReading.currentMeter.id
                ? {
                  ...newMeterReading.currentMeter,
                  values: _.concat(
                    newMeterReading.currentMeter.values,
                    [{ date: newMeterReading.date, value: newMeterReading.value }]
                  )
                }
                : x
            )
          })

          if (!res.error) setNewMeterReading(null)
        }}
        title='Neuer Zählerstand'
      />

      <Dialog
        close={() => setShowHistory(false)}
        maxWidth='md'
        ok={() => setShowHistory(false)}
        okText='Schließen'
        open={!!showHistory}
        text={_.map(_.filter(realEstate.meters, x => x.type === showHistory), meter => (
          <Card sx={{ marginTop: '10px' }} key={meter.id}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    {meter.name + (meter.number ? ' (' + meter.number + ')' : '')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{ margin: '0 20px' }}>
                  <TableContainer sx={{ maxHeight: '170px' }}>
                    <Table stickyHeader sx={{ minWidth: { xs: '0', md: '500px' } }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Datum</TableCell>
                          <TableCell>{'Zählerstand in ' + meter.unit}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.map(_.sortBy(meter.values, x => moment(x.date)).reverse(), meterReading => (
                          <TableRow key={meterReading.date} sx={{ '&:last-child td': { border: 0 } }}>
                            <TableCell>{getDateString(meterReading.date)}</TableCell>
                            <TableCell>{meterReading.value}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          )
        )}
        title='Historie'
      />
    </Box>
  )
}

export default RealEstateMeterReadingBox
