import _ from 'lodash'
import api from '../app/api'
import checkAndGetIbanInfo from '../app/utils/checkAndGetIbanInfo'
import checkAndParsePhoneNumber from '../app/utils/checkAndParsePhoneNumber'
import countries from '../app/constants/countries'
import ErrorDialog from '../app/components/ErrorDialog'
import FilesBox from '../realEstates/FilesBox'
import MeterCreateForm from '../realEstates/MeterCreateForm'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { Fragment, useState } from 'react'
import { AddSharp, Delete, Remove } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Step, StepButton, Stepper, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LoadingButton } from '@mui/lab'
import { Navigate, useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const RealEstateCreate = () => {
  const [postRealEstate, { isLoading: isSaving }] = pouchApi.usePostRealEstateMutation()
  const [postFiles, { isLoading: isSavingFiles }] = pouchApi.usePostFilesMutation()
  const { data: realEstates, isLoading } = pouchApi.useGetRealEstatesQuery()
  const { data: me } = api.useGetMeQuery()
  const navigate = useNavigate()

  const [step, setStep] = useState(0)
  const [currentFolder, setCurrentFolder] = useState(null)
  const [errors, setErrors] = useState([])

  const [photo, setPhoto] = useState(null)
  const [name, setName] = useState('')
  const [internalName, setInternalName] = useState('')
  const [street, setStreet] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState(countries[0])
  const [floor, setFloor] = useState('')

  const [ownerCompany, setOwnerCompany] = useState('')
  const [ownerSalutation, setOwnerSalutation] = useState('Herr')
  const [ownerFirstName, setOwnerFirstName] = useState('')
  const [ownerLastName, setOwnerLastName] = useState('')
  const [ownerStreet, setOwnerStreet] = useState('')
  const [ownerZip, setOwnerZip] = useState('')
  const [ownerCity, setOwnerCity] = useState('')
  const [ownerCountry, setOwnerCountry] = useState(countries[0])
  const [ownerPhone, setOwnerPhone] = useState('')
  const [ownerPhoneMobile, setOwnerPhoneMobile] = useState('')
  const [ownerEmail, setOwnerEmail] = useState('')
  const [ownerLegalRepresentative, setOwnerLegalRepresentative] = useState('')
  const [ownerIsLandlord, setOwnerIsLandlord] = useState(true)
  const [landlordCompany, setLandlordCompany] = useState('')
  const [landlordSalutation, setLandlordSalutation] = useState('')
  const [landlordFirstName, setLandlordFirstName] = useState('')
  const [landlordLastName, setLandlordLastName] = useState('')
  const [landlordStreet, setLandlordStreet] = useState('')
  const [landlordZip, setLandlordZip] = useState('')
  const [landlordCity, setLandlordCity] = useState('')
  const [landlordCountry, setLandlordCountry] = useState(null)
  const [landlordPhone, setLandlordPhone] = useState('')
  const [landlordPhoneMobile, setLandlordPhoneMobile] = useState('')
  const [landlordEmail, setLandlordEmail] = useState('')
  const [landlordLegalRepresentative, setLandlordLegalRepresentative] = useState('')

  const [managementCompany, setManagementCompany] = useState('')
  const [managementStreet, setManagementStreet] = useState('')
  const [managementZip, setManagementZip] = useState('')
  const [managementCity, setManagementCity] = useState('')
  const [managementCountry, setManagementCountry] = useState(null)
  const [managementPhone, setManagementPhone] = useState('')
  const [managementPhoneMobile, setManagementPhoneMobile] = useState('')
  const [managementEmail, setManagementEmail] = useState('')
  const [managementIban, setManagementIban] = useState('')
  const [managementIbanValid, setManagementIbanValid] = useState(true)
  const [managementBankDataKnown, setManagementBankDataKnown] = useState(false)
  const [managementBic, setManagementBic] = useState('')
  const [managementBank, setManagementBank] = useState('')
  const [managementContacts, setManagementContacts] = useState([])

  const [usage, setUsage] = useState('Privat')
  const [type, setType] = useState('Einfamilienhaus')
  const [constructionYear, setConstructionYear] = useState(moment().format('yyyy'))
  const [heatingType, setHeatingType] = useState('Gas')
  const [generalCondition, setGeneralCondition] = useState('')
  const [rooms, setRooms] = useState([])

  const [sellerCompany, setSellerCompany] = useState('')
  const [sellerSalutation, setSellerSalutation] = useState('')
  const [sellerFirstName, setSellerFirstName] = useState('')
  const [sellerLastName, setSellerLastName] = useState('')
  const [sellerStreet, setSellerStreet] = useState('')
  const [sellerZip, setSellerZip] = useState('')
  const [sellerCity, setSellerCity] = useState('')
  const [sellerCountry, setSellerCountry] = useState(null)
  const [purchaseCurrency, setPurchaseCurrency] = useState('EUR')
  const [purchasePrice, setPurchasePrice] = useState([{ id: uuid(), label: 'Objekt', price: '', canDelete: false }])
  const [closingCosts, setClosingCosts] = useState([])
  const [financingLoans, setFinancingLoans] = useState([])

  const [meters, setMeters] = useState([])

  const [files, setFiles] = useState([])

  const onManagementIbanBlur = () => {
    const res = checkAndGetIbanInfo(managementIban)

    setManagementIbanValid(res.valid)

    if (!!res.BIC && !!res.Bezeichnung) {
      setManagementBic(res.BIC)
      setManagementBank(res.Bezeichnung)
      setManagementBankDataKnown(true)
    } else {
      setManagementBankDataKnown(false)
    }

    if (managementIban === '') {
      setManagementBic('')
      setManagementBank('')
    }
  }

  return isLoading
    ? null
    : me.maxRealEstates - realEstates.length <= 0
      ? <Navigate to='/immobilien' replace/>
      : <Box sx={{ marginBottom: '50px' }}>
        <Typography variant='h3'>
          Immobilie erstellen
        </Typography>

        <form
          id='realEstateCreateForm'
          onSubmit={async e => {
            e.preventDefault()

            let realEstate = photo
              ? ({
                _attachments: {
                  'photo': {
                    content_type: photo.type,
                    data: photo
                  }
                }
              })
              : {}

            realEstate = {
              ...realEstate,
              name,
              internalName,
              street,
              zip,
              city,
              country,
              floor,

              ownerCompany,
              ownerSalutation,
              ownerFirstName,
              ownerLastName,
              ownerStreet,
              ownerZip,
              ownerCity,
              ownerCountry,
              ownerPhone,
              ownerPhoneMobile,
              ownerEmail,
              ownerLegalRepresentative,
              ownerIsLandlord,
              landlordCompany,
              landlordSalutation,
              landlordFirstName,
              landlordLastName,
              landlordStreet,
              landlordZip,
              landlordCity,
              landlordCountry,
              landlordPhone,
              landlordPhoneMobile,
              landlordEmail,
              landlordLegalRepresentative,

              managementCompany,
              managementStreet,
              managementZip,
              managementCity,
              managementCountry,
              managementPhone,
              managementPhoneMobile,
              managementEmail,
              managementIban,
              managementBankDataKnown,
              managementBic,
              managementBank,
              managementContacts,

              usage,
              type,
              constructionYear,
              heatingType,
              generalCondition,
              rooms,

              sellerCompany,
              sellerSalutation,
              sellerFirstName,
              sellerLastName,
              sellerStreet,
              sellerZip,
              sellerCity,
              sellerCountry,
              purchaseCurrency,
              purchasePrice,
              closingCosts,
              financingLoans,

              meters
            }

            const res = await postRealEstate(realEstate)

            if (!res.error) {
              const filesRes = await postFiles(_.map(files, file => ({
                ..._.omit(file, 'data', '_id'),
                type: 'default',
                realEstateId: res.data.id,

                _attachments: {
                  'file': { content_type: file.data.type, data: file.data }
                }
              })))

              if (!filesRes.error) navigate('/immobilien')
            }
          }}
        >

          <Stepper activeStep={step} alternativeLabel sx={{ margin: '20px 0' }}>
            {_.map(['Lage', 'Vermieter / Eigentümer', 'Hausverwaltung', 'Beschreibung', 'Erwerb', 'Aktuelle Zählerstände', 'Dokumente'], (label, i) => (
              <Step key={label}>
                <StepButton color='inherit' onClick={() => setStep(i)} sx={{ '.MuiStepLabel-label': { display: { xs: 'none', md: 'block' } } }}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {step === 0
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Lage</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <input
                      accept='image/*'
                      id='upload-photo'
                      onChange={e => setPhoto(e.target.files[0])}
                      style={{ display: 'none' }}
                      type='file'
                    />
                    <label htmlFor='upload-photo'><Button component='span' variant='outlined'>Bild hochladen</Button></label>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box sx={{ border: '1px solid lightGrey', p: '10px' }}>
                      {photo
                        ? <img src={URL.createObjectURL(photo)} style={{ maxWidth: '100%' }} alt='Foto der Immobilie'/>
                        : 'Kein Bild hochgeladen'
                      }
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {photo
                      ? <Button
                        onClick={() => {
                          const photoInput = document.getElementById('upload-photo')
                          photoInput.value = null
                          setPhoto(null)
                        }}
                        variant='outlined'
                      >
                        Bild löschen
                      </Button>
                      : null
                    }
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Bezeichnung'
                      onChange={e => setName(e.target.value)}
                      required
                      value={name}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Interne Bezeichnung'
                      onChange={e => setInternalName(e.target.value)}
                      value={internalName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Straße und Hausnummer'
                      onChange={e => setStreet(e.target.value)}
                      required
                      value={street}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Etage'
                      onChange={e => setFloor(e.target.value)}
                      value={floor}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Postleitzahl'
                      onChange={e => setZip(e.target.value)}
                      required
                      value={zip}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Ort'
                      onChange={e => setCity(e.target.value)}
                      required
                      value={city}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.key === value.key}
                      onChange={(e, country) => setCountry(country)}
                      options={countries}
                      renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                      value={country}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button
                  onClick={() => {
                    const valid = checkValidity()
                    if (valid) {
                      window.scrollTo(0, 0)
                      setStep(1)
                    }
                  }}
                  sx={{ marginLeft: 'auto' }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 1
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Eigentümer</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Firma'
                      onChange={e => setOwnerCompany(e.target.value)}
                      value={ownerCompany}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <FormControl size="small">
                      <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                      <RadioGroup row value={ownerSalutation} onChange={e => setOwnerSalutation(e.target.value)}>
                        <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                        <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                        <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5} lg={4}>
                    <TextField
                      fullWidth
                      label='Vorname'
                      onChange={e => setOwnerFirstName(e.target.value)}
                      required
                      value={ownerFirstName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={7} lg={4}>
                    <TextField
                      fullWidth
                      label='Nachname'
                      onChange={e => setOwnerLastName(e.target.value)}
                      required
                      value={ownerLastName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Straße und Hausnummer'
                      onChange={e => setOwnerStreet(e.target.value)}
                      required
                      value={ownerStreet}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Postleitzahl'
                      onChange={e => setOwnerZip(e.target.value)}
                      required
                      value={ownerZip}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Ort'
                      onChange={e => setOwnerCity(e.target.value)}
                      required
                      value={ownerCity}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.key === value.key}
                      onChange={(e, country) => setOwnerCountry(country)}
                      options={countries}
                      renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                      value={ownerCountry}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='Telefon'
                      onChange={e => setOwnerPhone(e.target.value)}
                      value={ownerPhone}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='Telefon (mobil)'
                      onChange={e => setOwnerPhoneMobile(e.target.value)}
                      value={ownerPhoneMobile}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='E-Mail'
                      onChange={e => setOwnerEmail(e.target.value)}
                      required
                      type='email'
                      value={ownerEmail}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Gesetzlicher Vertreter'
                      onChange={e => setOwnerLegalRepresentative(e.target.value)}
                      value={ownerLegalRepresentative}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ownerIsLandlord}
                          onChange={e => {
                            setLandlordCompany('')
                            setLandlordSalutation(e.target.checked ? '' : 'Herr')
                            setLandlordFirstName('')
                            setLandlordLastName('')
                            setLandlordStreet('')
                            setLandlordZip('')
                            setLandlordCity('')
                            setLandlordCountry(e.target.checked ? null :countries[0])
                            setLandlordPhone('')
                            setLandlordPhoneMobile('')
                            setLandlordEmail('')
                            setLandlordLegalRepresentative('')
                            setOwnerIsLandlord(e.target.checked)
                          }}
                        />
                      }
                      label='Eigentümer ist auch Vermieter'
                    />
                  </Grid>
                </Grid>

                {!ownerIsLandlord
                  ? <Grid container spacing={2} sx={{ marginTop: '30px' }}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Vermieter</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Firma'
                        onChange={e => setLandlordCompany(e.target.value)}
                        value={landlordCompany}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <FormControl size="small">
                        <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                        <RadioGroup row value={landlordSalutation} onChange={e => setLandlordSalutation(e.target.value)}>
                          <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                          <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                          <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5} lg={4}>
                      <TextField
                        fullWidth
                        label='Vorname'
                        onChange={e => setLandlordFirstName(e.target.value)}
                        required
                        value={landlordFirstName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={7} lg={4}>
                      <TextField
                        fullWidth
                        label='Nachname'
                        onChange={e => setLandlordLastName(e.target.value)}
                        required
                        value={landlordLastName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Straße und Hausnummer'
                        onChange={e => setLandlordStreet(e.target.value)}
                        required
                        value={landlordStreet}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label='Postleitzahl'
                        onChange={e => setLandlordZip(e.target.value)}
                        required
                        value={landlordZip}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label='Ort'
                        onChange={e => setLandlordCity(e.target.value)}
                        required
                        value={landlordCity}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        onChange={(e, country) => setLandlordCountry(country)}
                        options={countries}
                        renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                        value={landlordCountry}
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        fullWidth
                        label='Telefon'
                        onChange={e => setLandlordPhone(e.target.value)}
                        value={landlordPhone}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        fullWidth
                        label='Telefon (mobil)'
                        onChange={e => setLandlordPhoneMobile(e.target.value)}
                        value={landlordPhoneMobile}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} lg={4}>
                      <TextField
                        fullWidth
                        label='E-Mail'
                        onChange={e => setLandlordEmail(e.target.value)}
                        required
                        type='email'
                        value={landlordEmail}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Gesetzlicher Vertreter'
                        onChange={e => setLandlordLegalRepresentative(e.target.value)}
                        value={landlordLegalRepresentative}
                        variant='standard'
                      />
                    </Grid>
                  </Grid>
                  : null
                }
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(0)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <Button
                  onClick={() => {
                    const valid = checkValidity()

                    if (valid) {
                      let phoneNumbersValid = true
                      if (ownerPhone) {
                        const parsedTemp = checkAndParsePhoneNumber(ownerCountry.countryCode, ownerPhone)
                        if (!!parsedTemp) setOwnerPhone(parsedTemp)
                        else phoneNumbersValid = false
                      }
                      if (ownerPhoneMobile) {
                        const parsedTemp = checkAndParsePhoneNumber(ownerCountry.countryCode, ownerPhoneMobile)
                        if (!!parsedTemp) setOwnerPhoneMobile(parsedTemp)
                        else phoneNumbersValid = false
                      }
                      if (landlordPhone) {
                        const parsedTemp = checkAndParsePhoneNumber(landlordCountry.countryCode, landlordPhone)
                        if (!!parsedTemp) setLandlordPhone(parsedTemp)
                        else phoneNumbersValid = false
                      }
                      if (landlordPhoneMobile) {
                        const parsedTemp = checkAndParsePhoneNumber(landlordCountry.countryCode, landlordPhoneMobile)
                        if (!!parsedTemp) setLandlordPhoneMobile(parsedTemp)
                        else phoneNumbersValid = false
                      }

                      if (phoneNumbersValid) {
                        window.scrollTo(0, 0)
                        setStep(2)
                      } else {
                        setErrors(['Eine Telefonnummer konnte nicht normalisiert werden, bitte überprüfen Sie Ihre Eingaben.'])
                      }
                    }
                  }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 2
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Hausverwaltung</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Firma'
                      onChange={e => setManagementCompany(e.target.value)}
                      value={managementCompany}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Straße und Hausnummer'
                      onChange={e => setManagementStreet(e.target.value)}
                      value={managementStreet}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Postleitzahl'
                      onChange={e => setManagementZip(e.target.value)}
                      value={managementZip}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Ort'
                      onChange={e => setManagementCity(e.target.value)}
                      value={managementCity}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.key === value.key}
                      onChange={(e, country) => setManagementCountry(country)}
                      options={countries}
                      renderInput={(params) => <TextField
                        {...params}
                        label='Land'
                        required={!!managementPhone || !!managementPhoneMobile || (!!managementContacts && managementContacts.length > 0)}
                        variant='standard'
                        style={{ width: '100%' }}
                      />}
                      value={managementCountry}
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='Telefon'
                      onChange={e => setManagementPhone(e.target.value)}
                      value={managementPhone}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='Telefon (mobil)'
                      onChange={e => setManagementPhoneMobile(e.target.value)}
                      value={managementPhoneMobile}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label='E-Mail'
                      onChange={e => setManagementEmail(e.target.value)}
                      type='email'
                      value={managementEmail}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      error={!managementIbanValid}
                      fullWidth
                      helperText={managementIbanValid ? '' : 'Diese IBAN ist nicht gültig.'}
                      label='IBAN'
                      onBlur={onManagementIbanBlur}
                      onChange={e => setManagementIban(e.target.value)}
                      onKeyDown={e => e.key === 'Enter' ? onManagementIbanBlur() : null}
                      value={managementIban}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} lg={4}>
                    <TextField
                      disabled={!managementIban || !managementIbanValid || managementBankDataKnown}
                      fullWidth
                      label='BIC'
                      onChange={e => setManagementBic(e.target.value)}
                      value={managementBic}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={8} lg={4}>
                    <TextField
                      disabled={!managementIban || !managementIbanValid || managementBankDataKnown}
                      fullWidth
                      label='Bank'
                      onChange={e => setManagementBank(e.target.value)}
                      value={managementBank}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ margin: '10px 0', fontWeight: 'bold' }}>Kontakte</Typography>
                  </Grid>

                  {_.map(managementContacts, contact => {
                    const updateManagementContact = contactUpdate => {
                      setManagementContacts(_.map(
                        managementContacts,
                        x => x.id === contact.id
                          ? {
                            ...contact,
                            ...contactUpdate
                          }
                          : x
                      ))
                    }

                    return (
                      <Grid item xs={12} container spacing={2} key={contact.id} justifyContent='flex-end' sx={{ marginBottom: '10px' }}>
                        <Grid item xs={12} lg={4}>
                          <FormControl size="small">
                            <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                            <RadioGroup row value={contact.salutation} onChange={e => updateManagementContact({ salutation: e.target.value })}>
                              <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                              <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                              <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={5} lg={4}>
                          <TextField
                            fullWidth
                            label='Vorname'
                            onChange={e => updateManagementContact({ firstName: e.target.value })}
                            required
                            value={contact.firstName}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} md={7} lg={4}>
                          <TextField
                            fullWidth
                            label='Nachname'
                            onChange={e => updateManagementContact({ lastName: e.target.value })}
                            required
                            value={contact.lastName}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label='Aufgaben'
                            onChange={e => updateManagementContact({ work: e.target.value })}
                            value={contact.work}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            label='Telefon'
                            onChange={e => updateManagementContact({ phone: e.target.value })}
                            value={contact.phone}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            label='Telefon (mobil)'
                            onChange={e => updateManagementContact({ phoneMobile: e.target.value })}
                            value={contact.phoneMobile}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <TextField
                            fullWidth
                            label='E-Mail'
                            onChange={(e) => updateManagementContact({ email: e.target.value })}
                            type='email'
                            value={contact.email}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs='auto' md={1}>
                          <IconButton onClick={() => setManagementContacts(_.filter(managementContacts, x => x.id !== contact.id))}>
                            <Remove />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}

                  <IconButton onClick={() => setManagementContacts([...managementContacts, { id: uuid(), salutation: 'Herr', firstName: '', lastName: '', phone: '', phoneMobile: '', email: '', work: '' }])} style={{ margin: '5px 12px' }}>
                    <AddSharp />
                  </IconButton>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(1)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <Button
                  onClick={() => {
                    const valid = checkValidity()

                    if (valid) {
                      let phoneNumbersValid = true
                      if (managementPhone) {
                        const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, managementPhone)
                        if (!!parsedTemp) setManagementPhone(parsedTemp)
                        else phoneNumbersValid = false
                      }
                      if (managementPhoneMobile) {
                        const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, managementPhoneMobile)
                        if (!!parsedTemp) setManagementPhoneMobile(parsedTemp)
                        else phoneNumbersValid = false
                      }
                      for (let contact of managementContacts) {
                        let phone = contact.phone
                        let phoneMobile = contact.phone
                        if (phone) {
                          const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, phone)
                          if (!!parsedTemp) phone = parsedTemp
                          else phoneNumbersValid = false
                        }
                        if (contact.phoneMobile) {
                          const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, contact.phoneMobile)
                          if (!!parsedTemp) phoneMobile = parsedTemp
                          else phoneNumbersValid = false
                        }
                        setManagementContacts(_.map(managementContacts, x => x.id === contact.id ? { ...contact, phone, phoneMobile } : x))
                      }

                      if (phoneNumbersValid && managementIbanValid) {
                        window.scrollTo(0, 0)
                        setStep(3)
                      } else {
                        let temp = []
                        if (!phoneNumbersValid) temp.push('Eine Telefonnummer konnte nicht normalisiert werden, bitte überprüfen Sie Ihre Eingaben.')
                        if (!managementIbanValid) temp.push('Die IBAN ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe.')

                        setErrors(temp)
                      }
                    }
                  }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 3
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Beschreibung der Immobilie</Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth required variant='standard'>
                      <InputLabel id='usage-label'>Benutzung</InputLabel>
                      <Select
                        labelId='usage-label'
                        value={usage}
                        label='Benutzung'
                        onChange={e => setUsage(e.target.value)}
                      >
                        <MenuItem value='Privat'>Privat</MenuItem>
                        <MenuItem value='Gewerblich'>Gewerblich</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth required variant='standard'>
                      <InputLabel id='type-label'>Art der Immobilie</InputLabel>
                      <Select
                        labelId='type-label'
                        value={type}
                        label='Art der Immobilie'
                        onChange={e => setType(e.target.value)}
                      >
                        <MenuItem value='Einfamilienhaus'>Einfamilienhaus</MenuItem>
                        <MenuItem value='Mehrfamilienhaus'>Mehrfamilienhaus</MenuItem>
                        <MenuItem value='Doppelhaushälfte'>Doppelhaushälfte</MenuItem>
                        <MenuItem value='Reihenendhaus'>Reihenendhaus</MenuItem>
                        <MenuItem value='Reihenmittelhaus'>Reihenmittelhaus</MenuItem>
                        <MenuItem value='Grundstück'>Grundstück</MenuItem>
                        <MenuItem value='Halle'>Halle</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <DatePicker
                      label='Baujahr'
                      minDate={new Date("1800-01-01")}
                      onChange={(constructionYear) => setConstructionYear(constructionYear ? moment(constructionYear).format('yyyy') : null)}
                      openTo='year'
                      renderInput={(params) => <TextField {...params} required variant='standard' style={{ width: '100%' }}/>}
                      value={constructionYear}
                      views={['year']}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth required variant='standard'>
                      <InputLabel id='heatingType-label'>Heizungsart</InputLabel>
                      <Select
                        labelId='heatingType-label'
                        value={heatingType}
                        label='Heizungsart'
                        onChange={e => setHeatingType(e.target.value)}
                      >
                        <MenuItem value='Gas'>Gas</MenuItem>
                        <MenuItem value='Öl'>Öl</MenuItem>
                        <MenuItem value='Holz'>Holz</MenuItem>
                        <MenuItem value='Pellet'>Pellet</MenuItem>
                        <MenuItem value='Elektroheizung'>Elektroheizung</MenuItem>
                        <MenuItem value='Nachtspeicheröfen'>Nachtspeicheröfen</MenuItem>
                        <MenuItem value='Wärmepumpe'>Wärmepumpe</MenuItem>
                        <MenuItem value='Fernwärme'>Fernwärme</MenuItem>
                        <MenuItem value='Blockheizkraftwerk'>Blockheizkraftwerk</MenuItem>
                        <MenuItem value='Solarthermie'>Solarthermie</MenuItem>
                        <MenuItem value='Brennstoffzelle'>Brennstoffzelle</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Allgemeinzustand'
                      onChange={e => setGeneralCondition(e.target.value)}
                      required
                      value={generalCondition}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Räume
                      </Typography>
                    </Grid>

                    {_.map(rooms, room => {
                      const changeRoom = roomUpdate => {
                        setRooms(_.map(rooms, x => x.id === room.id
                          ? {
                            ...room,
                            ...roomUpdate
                          }
                          : x
                        ))
                      }

                      return (
                        <Grid item xs={12} container spacing={2} key={room.id} justifyContent='flex-end'>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              inputProps={{ style: { fontWeight: 'bold' } }}
                              label='Bezeichnung'
                              onChange={e => changeRoom({ name: e.target.value })}
                              required
                              value={room.name}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={2}>
                            <TextField
                              fullWidth
                              label='Raumgröße in m²'
                              onChange={e => changeRoom({ size: e.target.value })}
                              type='number'
                              value={room.size}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              fullWidth
                              label='Fenster'
                              onChange={e => changeRoom({ windows: e.target.value })}
                              value={room.windows}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              fullWidth
                              label='Rollläden'
                              onChange={e => changeRoom({ shutters: e.target.value })}
                              value={room.shutters}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label='Wandbelag'
                              onChange={e => changeRoom({ wallCovering: e.target.value })}
                              value={room.wallCovering}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label='Bodenbelag'
                              onChange={e => changeRoom({ flooring: e.target.value })}
                              value={room.flooring}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label='Mobiliar'
                              onChange={e => changeRoom({ furnishings: e.target.value })}
                              value={room.furnishings}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label='Beleuchtung'
                              onChange={e => changeRoom({ lighting: e.target.value })}
                              value={room.lighting}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label='Steckdosen'
                              onChange={e => changeRoom({ sockets: e.target.value })}
                              value={room.sockets}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth variant='standard'>
                              <InputLabel id='heating-label' shrink>Heizung</InputLabel>
                              <Select
                                displayEmpty
                                label='Heizung'
                                labelId='heating-label'
                                onChange={e => changeRoom({ heating: e.target.value })}
                                value={room.heating}
                              >
                                <MenuItem value=''>keine</MenuItem>
                                <MenuItem value='Konvektor'>Konvektor</MenuItem>
                                <MenuItem value='Kamin'>Kamin</MenuItem>
                                <MenuItem value='Fußboden'>Fußboden</MenuItem>
                                <MenuItem value='Nachtspeicherofen'>Nachtspeicherofen</MenuItem>
                                <MenuItem value='Röhrenheizkörper'>Röhrenheizkörper</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={11}>
                            <TextField
                              fullWidth
                              label='Bemerkungen'
                              onChange={e => changeRoom({ comment: e.target.value })}
                              value={room.comment}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs='auto' sm={1}>
                            <IconButton onClick={() => setRooms(_.filter(rooms, x => x.id !== room.id))}>
                              <Remove />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )
                    })}

                    <Grid item xs={12}>
                      <IconButton onClick={() => setRooms([...rooms, { id: uuid(), name: '', size: '', windows: '', shutters: '', wallCovering: '', flooring: '', furnishings: '', lighting: '', sockets: '', heating: '', comment: '' }])}>
                        <AddSharp />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(2)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <Button
                  onClick={() => {
                    const valid = checkValidity()
                    if (valid) {
                      if (constructionYear < 1800) {
                        setErrors(['Bitte überprüfen Sie Ihre eingegeben Daten. Ein Datum ist nicht korrekt.'])
                      } else {
                        window.scrollTo(0, 0)
                        setStep(4)
                      }
                    }
                  }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 4
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Verkäufer</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Firma Verkäufer'
                      onChange={e => setSellerCompany(e.target.value)}
                      value={sellerCompany}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <FormControl size="small">
                      <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                      <RadioGroup row value={sellerSalutation} onChange={e => setSellerSalutation(e.target.value)}>
                        <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                        <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                        <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={5} lg={4}>
                    <TextField
                      fullWidth
                      label='Vorname Verkäufer'
                      onChange={e => setSellerFirstName(e.target.value)}
                      value={sellerFirstName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={7} lg={4}>
                    <TextField
                      fullWidth
                      label='Nachname Verkäufer'
                      onChange={e => setSellerLastName(e.target.value)}
                      value={sellerLastName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Straße und Hausnummer Verkäufer'
                      onChange={e => setSellerStreet(e.target.value)}
                      value={sellerStreet}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Postleitzahl Verkäufer'
                      onChange={e => setSellerZip(e.target.value)}
                      value={sellerZip}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Ort Verkäufer'
                      onChange={e => setSellerCity(e.target.value)}
                      value={sellerCity}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.key === value.key}
                      onChange={(e, country) => setSellerCountry(country)}
                      options={countries}
                      renderInput={(params) => <TextField {...params} label='Land Verkäufer' variant='standard' style={{ width: '100%' }} />}
                      value={sellerCountry}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '30px' }}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Kaufpreis</Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth required variant='standard'>
                      <InputLabel id='currency-label'>Währung</InputLabel>
                      <Select
                        labelId='currency-label'
                        value={purchaseCurrency}
                        label='Währung'
                        onChange={e => setPurchaseCurrency(e.target.value)}
                      >
                        <MenuItem value='EUR'>EUR (€)</MenuItem>
                        <MenuItem value='USD'>USD ($)</MenuItem>
                        <MenuItem value='GBP'>GBP (£)</MenuItem>
                        <MenuItem value='CHF'>CHF</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={8} sx={{ display: { xs: 'none', sm: 'inline' } }}/>

                  {_.map(purchasePrice, purchasePriceItem => {
                    const changeItem = itemUpdate => {
                      setPurchasePrice(_.map(purchasePrice, x => x.id === purchasePriceItem.id
                        ? {
                          ...purchasePriceItem,
                          ...itemUpdate
                        }
                        : x
                      ))
                    }

                    return (
                      <Fragment key={purchasePriceItem.id}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            inputProps={{ style: { fontWeight: 'bold' } }}
                            label='Bezeichnung'
                            onChange={e => changeItem({ label: e.target.value })}
                            required
                            value={purchasePriceItem.label}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={9.5} sm={2}>
                          <TextField
                            fullWidth
                            label='Preis'
                            onChange={e => changeItem({ price: e.target.value })}
                            required
                            type='number'
                            value={purchasePriceItem.price}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={2.5} sm={1}>
                          {purchasePriceItem.canDelete
                            ? <IconButton onClick={() => setPurchasePrice(_.filter(purchasePrice, x => x.id !== purchasePriceItem.id))}>
                              <Delete />
                            </IconButton>
                            : null
                          }
                        </Grid>
                      </Fragment>
                    )
                  })}

                  <Grid item xs={12}>
                    <IconButton onClick={() => setPurchasePrice([...purchasePrice, { id: uuid(), label: '', price: '', canDelete: true }])}>
                      <AddSharp />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold' }}>Kaufnebenkosten</Typography>
                  </Grid>

                  {_.map(closingCosts, closingCost => {
                    const changeClosingCost = closingCostUpdate => {
                      setClosingCosts(_.map(closingCosts, x => x.id === closingCost.id
                        ? {
                          ...closingCost,
                          ...closingCostUpdate
                        }
                        : x
                      ))
                    }

                    return (
                      <Fragment key={closingCost.id}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            inputProps={{ style: { fontWeight: 'bold' } }}
                            label='Bezeichnung'
                            onChange={e => changeClosingCost({ label: e.target.value })}
                            required
                            value={closingCost.label}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={9.5} sm={2}>
                          <TextField
                            fullWidth
                            label='Preis'
                            onChange={e => changeClosingCost({ price: e.target.value })}
                            required
                            type='number'
                            value={closingCost.price}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={2.5} sm={1}>
                          <IconButton onClick={() => setClosingCosts(_.filter(closingCosts, x => x.id !== closingCost.id))}>
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    )
                  })}

                  <Grid item xs={12}>
                    <IconButton onClick={() => setClosingCosts([...closingCosts, { id: uuid(), label: '', price: '' }])}>
                      <AddSharp />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '30px' }}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Finanzierungsdarlehen</Typography>
                  </Grid>

                  {_.map(financingLoans, financingLoan => {
                    const changeLoan = loanUpdate => {
                      setFinancingLoans(_.map(financingLoans, x => x.id === financingLoan.id
                        ? {
                          ...financingLoan,
                          ...loanUpdate
                        }
                        : x
                      ))
                    }

                    return (
                      <Grid item xs={12} container spacing={2} key={financingLoan.id}>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            fullWidth
                            label='Bank'
                            onChange={e => changeLoan({ bank: e.target.value })}
                            required
                            value={financingLoan.bank}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            fullWidth
                            label='Ansprechpartner'
                            onChange={e => changeLoan({ contact: e.target.value })}
                            value={financingLoan.contact}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <TextField
                            fullWidth
                            label='Darlehenssumme'
                            onChange={e => changeLoan({ amount: e.target.value })}
                            required
                            type='number'
                            value={financingLoan.amount}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <FormControl fullWidth required variant='standard'>
                            <InputLabel id='loan-currency-label'>Währung</InputLabel>
                            <Select
                              labelId='currency-label'
                              value={financingLoan.currency}
                              label='Währung'
                              onChange={e => changeLoan({ currency: e.target.value })}
                            >
                              <MenuItem value='EUR'>EUR (€)</MenuItem>
                              <MenuItem value='USD'>USD ($)</MenuItem>
                              <MenuItem value='GBP'>GBP (£)</MenuItem>
                              <MenuItem value='CHF'>CHF</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <DatePicker
                            label='Zinsbindungsfrist'
                            mask='__.__.____'
                            onChange={date => changeLoan({ fixedInterestPeriod: date })}
                            renderInput={params => <TextField {...params} variant='standard' style={{ width: '100%' }} inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }}/>}
                            value={financingLoan.fixedInterestPeriod}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <TextField
                            fullWidth
                            label='Zinssatz in %'
                            onChange={e => changeLoan({ interestRate: e.target.value })}
                            type='number'
                            value={financingLoan.interestRate}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <TextField
                            fullWidth
                            label='Anfängliche Tilgung in %'
                            onChange={e => changeLoan({ initialAcquittance: e.target.value })}
                            type='number'
                            value={financingLoan.initialAcquittance}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <TextField
                            fullWidth
                            label='Annuität in %'
                            onChange={e => changeLoan({ annuity: e.target.value })}
                            type='number'
                            value={financingLoan.annuity}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2}>
                          <TextField
                            fullWidth
                            label='Jährliche Sondertilgungsrate'
                            onChange={e => changeLoan({ annualUnscheduledRepayment: e.target.value })}
                            type='number'
                            value={financingLoan.annualUnscheduledRepayment}
                            variant='standard'
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={2} sx={{ display: 'flex' }}>
                          <IconButton onClick={() => setFinancingLoans(_.filter(financingLoans, x => x.id !== financingLoan.id))} sx={{ marginLeft: 'auto', marginRight: '15px' }}>
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}

                  <Grid item xs={12}>
                    <IconButton onClick={() => setFinancingLoans([...financingLoans, { id: uuid(), bank: '', contact: '', amount: '', currency: 'EUR', fixedInterestPeriod: null, interestRate: 0, initialAcquittance: 0, annuity: 0, annualUnscheduledRepayment: 0 }])}>
                      <AddSharp />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(3)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <Button
                  onClick={() => {
                    const valid = checkValidity()
                    if (valid) {
                      window.scrollTo(0, 0)
                      setStep(5)
                    }
                  }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 5
            ? <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Aktuelle Zählerstände</Typography>
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Wasser
                      </Typography>
                    </Grid>

                    {_.map(_.filter(meters, x => x.type ==='water'), meter => (
                      <MeterCreateForm
                        key={meter.id}
                        meter={meter}
                        onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                        setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                      />
                    ))}

                    <IconButton onClick={() => setMeters([...meters, { id: uuid(), type: 'water', name: '', number: '', unit: 'm³', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ margin: '5px 12px' }}>
                      <AddSharp />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Strom
                      </Typography>
                    </Grid>

                    {_.map(_.filter(meters, x => x.type ==='electricity'), meter => (
                      <MeterCreateForm
                        key={meter.id}
                        meter={meter}
                        onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                        setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                      />
                    ))}

                    <IconButton onClick={() => setMeters([...meters, { id: uuid(), type: 'electricity', name: '', number: '', unit: 'kWh', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ margin: '5px 12px' }}>
                      <AddSharp />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Heizung
                      </Typography>
                    </Grid>

                    {_.map(_.filter(meters, x => x.type ==='radiator'), meter => (
                      <MeterCreateForm
                        key={meter.id}
                        meter={meter}
                        onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                        setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                      />
                    ))}

                    <IconButton onClick={() => setMeters([...meters, { id: uuid(), type: 'radiator', name: '', number: '', unit: 'kWh', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ margin: '5px 12px' }}>
                      <AddSharp />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(4)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <Button
                  onClick={() => {
                    const valid = checkValidity()
                    if (valid) {
                      if (_.some(meters, x => moment(x.values[0].date).get('year') < 1900 || Number.isNaN(moment(x.values[0].date).get('year')))) {
                        setErrors(['Bitte überprüfen Sie Ihre eingegeben Daten. Ein Datum ist nicht korrekt.'])
                      } else {
                        window.scrollTo(0, 0)
                        setStep(6)
                      }
                    }
                  }}
                  variant='contained'
                >
                  Weiter
                </Button>
              </CardActions>
            </Card>
            : null
          }

          {step === 6
            ? <Card>
              <CardContent>
                <FilesBox
                  currentFolder={currentFolder}
                  files={files}
                  getFileHref={file => URL.createObjectURL(file.data)}
                  setCurrentFolder={setCurrentFolder}
                  setFiles={files => {
                    if (_.some(files, file => !file.name)) {
                      setErrors(['Bitte geben Sie einen Namen für das Dokument an.'])
                      return false
                    }
                    setFiles(files)
                    return true
                  }}
                />
              </CardContent>

              <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setStep(5)
                  }}
                  variant='contained'
                >
                  Zurück
                </Button>

                <LoadingButton loading={isSaving || isSavingFiles} variant='contained' type='submit'>
                  Speichern
                </LoadingButton>
              </CardActions>
            </Card>
            : null
          }
        </form>

        <ErrorDialog
          close={() => setErrors([])}
          errors={errors}
          ok={() => setErrors([])}
          open={errors.length > 0}
        />
      </Box>
}

function checkValidity () {
  const form = document.getElementById('realEstateCreateForm')
  if (!form.checkValidity()) {
    form.reportValidity()
    return false
  }

  return true
}

export default RealEstateCreate
