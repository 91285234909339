import AppRestUrls from './constants/AppRestUrls'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { sliceActions } from './slice'

const tagTypes = ['me', 'user', 'newHelpdeskMessages']

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: AppRestUrls.base(),
    prepareHeaders: (headers) => {
      headers.set('X-UNAUTH-BEHAVIOUR', 'return-401')
      return headers
    },
    credentials: "include"
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getMe: build.query({
      query: () => 'user/me',
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
       try {
          const res = await queryFulfilled
          dispatch(sliceActions.setMe(res.data))
        } catch (e) {
          //error handled in AuthPage
        }
      },
      providesTags: ['me']
    }),
    getUsers: build.query({ query: () => 'user', providesTags: ['user']}),
    postUser: build.mutation({
      query: args => ({ url: 'user', method: 'POST', body: args }),
      invalidatesTags: ['user']
    }),
    putUser: build.mutation({
      query: args => ({ url: `user/${args.username}`, method: 'PUT', body: args }),
      invalidatesTags: ['user']
    }),
    deactivateUser: build.mutation({
      query: args => ({ url: `user/deactivate/${args.username}`, method: 'PUT' }),
      invalidatesTags: ['user']
    }),
    putPassword: build.mutation({
      query: args => ({ url: 'user/password', method: 'PUT', body: args })
    }),
    getNewHelpdeskMessages: build.query({
      query: () => 'user/me/support/new-messages',
      providesTags: ['newHelpdeskMessages']
    })
  })
})

export default api
export { tagTypes }
