import _ from 'lodash'
import ErrorDialog from '../app/components/ErrorDialog'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { AddSharp, Delete } from '@mui/icons-material'
import { Button, Box, Card, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog } from 'common_components'
import { LoadingButton } from '@mui/lab'
import { v4 as uuid } from 'uuid'

const RealEstateDescriptionForm = ({ realEstate, endEdit }) => {
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()
  const formId = "real-estate-description-form"
  const [errors, setErrors] = useState([])
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const [updateConfirmed, setUpdateConfirmed] = useState(false)

  const [usage, setUsage] = useState(realEstate.usage)
  const [type, setType] = useState(realEstate.type)
  const [constructionYear, setConstructionYear] = useState(realEstate.constructionYear)
  const [heatingType, setHeatingType] = useState(realEstate.heatingType)
  const [generalCondition, setGeneralCondition] = useState(realEstate.generalCondition)
  const [rooms, setRooms] = useState(realEstate.rooms)

  const deletedRooms = _.filter(realEstate.rooms, r => !_.some(rooms, x => r.id === x.id))

  return (
    <form
      id={formId}
      onSubmit={async e => {
        e.preventDefault()

        if (deletedRooms.length > 0 && !updateConfirmed) {
          setShowUpdateConfirmation(true)
          return
        }

        if (constructionYear < 1800) {
          setErrors(['Bitte überprüfen Sie Ihre eingegeben Daten. Ein Datum ist nicht korrekt.'])
          return
        }

        const res = await putRealEstate({
          ...realEstate,
          usage,
          type,
          constructionYear,
          heatingType,
          generalCondition,
          rooms
        })

        if (!res.error) endEdit()
      }}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Allgemeines</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required variant='standard'>
                  <InputLabel id='usage-label'>Benutzung</InputLabel>
                  <Select
                    labelId='usage-label'
                    value={usage}
                    label='Benutzung'
                    onChange={e => setUsage(e.target.value)}
                  >
                    <MenuItem value='Privat'>Privat</MenuItem>
                    <MenuItem value='Gewerblich'>Gewerblich</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required variant='standard'>
                  <InputLabel id='type-label'>Art der Immobilie</InputLabel>
                  <Select
                    labelId='type-label'
                    value={type}
                    label='Art der Immobilie'
                    onChange={e => setType(e.target.value)}
                  >
                    <MenuItem value='Einfamilienhaus'>Einfamilienhaus</MenuItem>
                    <MenuItem value='Mehrfamilienhaus'>Mehrfamilienhaus</MenuItem>
                    <MenuItem value='Doppelhaushälfte'>Doppelhaushälfte</MenuItem>
                    <MenuItem value='Reihenendhaus'>Reihenendhaus</MenuItem>
                    <MenuItem value='Reihenmittelhaus'>Reihenmittelhaus</MenuItem>
                    <MenuItem value='Grundstück'>Grundstück</MenuItem>
                    <MenuItem value='Halle'>Halle</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <DatePicker
                  label='Baujahr'
                  minDate={new Date("1800-01-01")}
                  onChange={(constructionYear) => setConstructionYear(constructionYear ? moment(constructionYear).format('yyyy') : null)}
                  openTo='year'
                  renderInput={(params) => <TextField {...params} required variant='standard' style={{ width: '100%' }}/>}
                  value={constructionYear}
                  views={['year']}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required variant='standard'>
                  <InputLabel id='heatingType-label'>Heizungsart</InputLabel>
                  <Select
                    labelId='heatingType-label'
                    value={heatingType}
                    label='Heizungsart'
                    onChange={e => setHeatingType(e.target.value)}
                  >
                    <MenuItem value='Gas'>Gas</MenuItem>
                    <MenuItem value='Öl'>Öl</MenuItem>
                    <MenuItem value='Holz'>Holz</MenuItem>
                    <MenuItem value='Pellet'>Pellet</MenuItem>
                    <MenuItem value='Elektroheizung'>Elektroheizung</MenuItem>
                    <MenuItem value='Nachtspeicheröfen'>Nachtspeicheröfen</MenuItem>
                    <MenuItem value='Wärmepumpe'>Wärmepumpe</MenuItem>
                    <MenuItem value='Fernwärme'>Fernwärme</MenuItem>
                    <MenuItem value='Blockheizkraftwerk'>Blockheizkraftwerk</MenuItem>
                    <MenuItem value='Solarthermie'>Solarthermie</MenuItem>
                    <MenuItem value='Brennstoffzelle'>Brennstoffzelle</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Allgemeinzustand'
                  onChange={e => setGeneralCondition(e.target.value)}
                  required
                  value={generalCondition}
                  variant='standard'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='h6'>Räume</Typography>
              </Grid>

              {_.map(rooms, room => {
                const changeRoom = roomUpdate => {
                  setRooms(_.map(rooms, x => x.id === room.id
                    ? {
                      ...room,
                      ...roomUpdate
                    }
                    : x
                  ))
                }

                return (
                  <Grid item xs={12} container spacing={2} key={room.id} justifyContent='flex-end'>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Bezeichnung'
                        onChange={e => changeRoom({ name: e.target.value })}
                        required
                        value={room.name}
                        variant='standard'
                        inputProps={{ style: { fontWeight: 'bold' } }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Raumgröße in m²'
                        onChange={e => changeRoom({ size: e.target.value })}
                        type='number'
                        value={room.size}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Fenster'
                        onChange={e => changeRoom({ windows: e.target.value })}
                        value={room.windows}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Rollläden'
                        onChange={e => changeRoom({ shutters: e.target.value })}
                        value={room.shutters}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Wandbelag'
                        onChange={e => changeRoom({ wallCovering: e.target.value })}
                        value={room.wallCovering}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Bodenbelag'
                        onChange={e => changeRoom({ flooring: e.target.value })}
                        value={room.flooring}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Mobiliar'
                        onChange={e => changeRoom({ furnishings: e.target.value })}
                        value={room.furnishings}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Beleuchtung'
                        onChange={e => changeRoom({ lighting: e.target.value })}
                        value={room.lighting}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Steckdosen'
                        onChange={e => changeRoom({ sockets: e.target.value })}
                        value={room.sockets}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <FormControl fullWidth variant='standard'>
                        <InputLabel id='heating-label' shrink>Heizung</InputLabel>
                        <Select
                          displayEmpty
                          label='Heizung'
                          labelId='heating-label'
                          onChange={e => changeRoom({ heating: e.target.value })}
                          value={room.heating}
                        >
                          <MenuItem value=''>keine</MenuItem>
                          <MenuItem value='Konvektor'>Konvektor</MenuItem>
                          <MenuItem value='Kamin'>Kamin</MenuItem>
                          <MenuItem value='Fußboden'>Fußboden</MenuItem>
                          <MenuItem value='Nachtspeicherofen'>Nachtspeicherofen</MenuItem>
                          <MenuItem value='Röhrenheizkörper'>Röhrenheizkörper</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={11}>
                      <TextField
                        fullWidth
                        label='Bemerkungen'
                        onChange={e => changeRoom({ comment: e.target.value })}
                        value={room.comment}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs='auto' sm={1}>
                      <IconButton onClick={() => setRooms(_.filter(rooms, x => x.id !== room.id))}>
                        <Delete/>
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <IconButton onClick={() => setRooms([...rooms, { id: uuid(), name: '', size: '', windows: '', shutters: '', wallCovering: '', flooring: '', furnishings: '', lighting: '', sockets: '', heating: '', comment: '' }])}>
                  <AddSharp />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            Abbrechen
          </Button>

          <LoadingButton loading={isLoading} variant='outlined' type='submit'>
            Speichern
          </LoadingButton>
        </Box>
      </Box>

      <Dialog
        close={() => setShowUpdateConfirmation(false)}
        open={showUpdateConfirmation}
        title="Bestätigung"
        text="Sind Sie sicher, dass die Änderungen inkl. dem Löschen von Räumen übernommen werden sollen?"
        no={() => setShowUpdateConfirmation(false)}
        noText="Zurück"
        submit={() => {
          setShowUpdateConfirmation(false)
          setUpdateConfirmed(true)
        }}
        submitFormId={formId}
        submitText="Speichern"
      />

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />
    </form>
  )
}

export default RealEstateDescriptionForm
