import _ from 'lodash'
import countries from '../app/constants/countries'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Autocomplete, Button, Box, Grid, IconButton, TextField } from '@mui/material'
import { Delete, Upload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

const RealEstateLocationForm = ({ realEstate, endEdit }) => {
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()

  const [editedPhoto, setEditedPhoto] = useState(null)
  const [name, setName] = useState(realEstate.name)
  const [internalName, setInternalName] = useState(realEstate.internalName)
  const [floor, setFloor] = useState(realEstate.floor)
  const [street, setStreet] = useState(realEstate.street)
  const [zip, setZip] = useState(realEstate.zip)
  const [city, setCity] = useState(realEstate.city)
  const [country, setCountry] = useState(realEstate.country)

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()

        let realEstateUpdate = realEstate

        if (!!editedPhoto && realEstate._attachments && realEstate._attachments.photo) {
          realEstateUpdate = {
            ...realEstateUpdate,
            _attachments: _.omit(realEstate._attachments, ['photo'])
          }
        }

        const res = await putRealEstate({
          ...realEstateUpdate,
          name,
          internalName,
          street,
          zip,
          city,
          country,
          floor,
          _attachments: editedPhoto && editedPhoto !== 'deleted'
            ? {
              ...realEstateUpdate._attachments,
              photo: {
                content_type: editedPhoto.type,
                data: editedPhoto
              }
            }
            : realEstateUpdate._attachments
        })

        if (!res.error) endEdit()
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
          <Grid container spacing={2} justifyContent='flex-end'>
            <Grid item xs={12}>
              {editedPhoto && editedPhoto !== 'deleted'
                ? <img src={URL.createObjectURL(editedPhoto)} style={{ maxWidth: '100%' }} alt='Foto der Immobilie'/>
                : !editedPhoto && realEstate._attachments && realEstate._attachments.photo
                  ? <img src={'data:' + realEstate._attachments.photo.content_type + ';base64, ' + realEstate._attachments.photo.data} style={{ maxWidth: '100%' }} alt='Foto der Immobilie'/>
                  : <Box sx={{ paddingTop: '60%', backgroundColor: 'lightGray', position: 'relative' }}><ImageOutlined sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '50px' }}/></Box>
              }
            </Grid>

            <Grid item xs='auto'>
              <input
                accept='image/*'
                id='upload-photo'
                onChange={e => setEditedPhoto(e.target.files[0])}
                style={{ display: 'none' }}
                type='file'
              />
              <label htmlFor='upload-photo'><IconButton component='span' color='primary'><Upload/></IconButton></label>

              {(editedPhoto && editedPhoto !== 'deleted') || (realEstate._attachments && realEstate._attachments.photo)
                ? <IconButton
                  color='primary'
                  onClick={() => {
                    const photoInput = document.getElementById('upload-photo')
                    photoInput.value = null
                    setEditedPhoto('deleted')
                  }}
                >
                  <Delete/>
                </IconButton>
                : null
              }
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: { xs: '100%', md: '70%' }, marginTop: { xs: '10px', md: 0 }, marginBottom: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Bezeichnung'
                onChange={e => setName(e.target.value)}
                required
                value={name}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Interne Bezeichnung'
                onChange={e => setInternalName(e.target.value)}
                value={internalName}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Etage'
                onChange={e => setFloor(e.target.value)}
                value={floor}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Straße und Hausnummer'
                onChange={e => setStreet(e.target.value)}
                required
                value={street}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Postleitzahl'
                onChange={e => setZip(e.target.value)}
                required
                value={zip}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Ort'
                onChange={e => setCity(e.target.value)}
                required
                value={city}
                variant='standard'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.key === value.key}
                onChange={(e, country) => setCountry(country)}
                options={countries}
                renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                value={country}
              />
            </Grid>
          </Grid>
        </Box>

        <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
          Abbrechen
        </Button>

        <LoadingButton loading={isLoading} variant='outlined' type='submit'>
          Speichern
        </LoadingButton>
      </Box>
    </form>
  )
}

export default RealEstateLocationForm
