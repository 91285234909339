import _ from 'lodash'
import checkAndGetIbanInfo from '../app/utils/checkAndGetIbanInfo'
import checkAndParsePhoneNumber from '../app/utils/checkAndParsePhoneNumber'
import countries from '../app/constants/countries'
import ErrorDialog from '../app/components/ErrorDialog'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { Fragment, useState } from 'react'
import { AddSharp, Close, Delete, Edit, Save } from '@mui/icons-material'
import { Autocomplete, Button, Box, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog } from 'common_components'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const TenantForm = ({ onDataSaved, onCancel, showRealEstate = true, tenant, title, buttonVariant = 'contained' }) => {
  const [postTenant, { isLoading: isCreating }] = pouchApi.usePostTenantMutation()
  const [putTenant, { isLoading: isUpdating }] = pouchApi.usePutTenantMutation()
  const { data: realEstates, isLoading } = pouchApi.useGetRealEstatesQuery()

  const navigate = useNavigate()
  const formId = 'tenant-form'
  const [errors, setErrors] = useState([])
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const [updateConfirmed, setUpdateConfirmed] = useState(false)

  const realEstateOptions = _.map(realEstates, x => ({ ...x, label: x.name + ' (' + x.street + ', ' + x.zip + ' ' + x.city + ')' }))

  const [persons, setPersons] = useState(tenant
    ? tenant.persons
    : [{
      id: uuid(),
      company: '',
      firstName: '',
      lastName: '',
      street: '',
      zip: '',
      city: '',
      country: countries[0],
      phone: '',
      phoneMobile: '',
      email: ''
    }]
  )

  const [realEstate, setRealEstate] = useState(null)
  const [currency, setCurrency] = useState(tenant ? tenant.currency : 'EUR')
  const [costs, setCosts] = useState(tenant && tenant.costs
    ? tenant.costs
    : [
      { id: uuid(), label: 'Kaltmiete', value: '', canDelete: false },
      { id: uuid(), label: 'Nebenkostenabschlag', value: '', canDelete: false }
    ]
  )
  const [deposit, setDeposit] = useState(tenant ? tenant.deposit : '')
  const [depositType, setDepositType] = useState(tenant ? tenant.depositType : 'Keine Kaution')
  const [depositState, setDepositState] = useState(tenant ? tenant.depositState : 'Ausstehend')
  const [depositPaymentDate, setDepositPaymentDate] = useState(tenant ? tenant.depositPaymentDate : null)
  const [rentalStart, setRentalStart] = useState(tenant ? tenant.rentalStart : null)
  const [rentalEnd, setRentalEnd] = useState(tenant ? tenant.rentalEnd : null)
  const [rentalLimited, setRentalLimited] = useState(tenant ? tenant.rentalLimited : false)

  const [iban, setIban] = useState(tenant ? tenant.iban : '')
  const [ibanValid, setIbanValid] = useState(true)
  const [bankDataKnown, setBankDataKnown] = useState(tenant ? tenant.bankDataKnown : false)
  const [bic, setBic] = useState(tenant ? tenant.bic : '')
  const [bank, setBank] = useState(tenant ? tenant.bank : '')

  const [files, setFiles] = useState(tenant ? tenant.files || [] : [])
  const [editFile, setEditFile] = useState(null)

  const deletedPersons = tenant ? _.filter(tenant.persons, p => !_.some(persons, x => p.id === x.id)) : []
  const deletedCosts = tenant ? _.filter(tenant.costs, c => !_.some(costs, x => c.id === x.id)) : []
  const deletedFiles = tenant ? _.filter(tenant.files, f => !_.some(files, x => f.id === x.id)) : []

  const onIbanBlur = () => {
    const res = checkAndGetIbanInfo(iban)

    setIbanValid(res.valid)

    if (!!res.BIC && !!res.Bezeichnung) {
      setBic(res.BIC)
      setBank(res.Bezeichnung)
      setBankDataKnown(true)
    } else {
      setBankDataKnown(false)
    }

    if (iban === '') {
      setBic('')
      setBank('')
    }
  }

  return isLoading
    ? null
    : <Box>
      <form
        id={formId}
        onSubmit={async e => {
          e.preventDefault()

          if (!ibanValid) {
            setErrors(['Die IBAN ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe.'])
            return
          }

          if ((deletedPersons.length > 0 || deletedCosts.length > 0 || deletedFiles.length > 0)&& !updateConfirmed) {
            setShowUpdateConfirmation(true)
            return
          }

          let dateError = false
          if (depositPaymentDate) dateError = dateError || moment(depositPaymentDate).get('year') < 1900 || Number.isNaN(moment(depositPaymentDate).get('year'))
          if (rentalStart) dateError = dateError || moment(rentalStart).get('year') < 1900 || Number.isNaN(moment(rentalStart).get('year'))
          if (rentalEnd) dateError = dateError || moment(rentalEnd).get('year') < 1900 || Number.isNaN(moment(rentalEnd).get('year'))

          if (dateError) {
            setErrors(['Jedes Datum muss im Format dd.mm.yyyy eingegeben werden. Das Jahr muss nach 1900 liegen. Bitte überprüfen Sie Ihre Eingaben!'])
            return
          }

          let personsParsed = persons
          let personsError = false
          for (let person of persons) {
            if (person.phone) {
              const parsedTemp = checkAndParsePhoneNumber(person.country.countryCode, person.phone)
              if (parsedTemp === false) personsError = true
              else personsParsed = _.map(personsParsed, x => x.id === person.id ? { ...x, phone: parsedTemp } : x)
            }
            if (person.phoneMobile) {
              const parsedTemp = checkAndParsePhoneNumber(person.country.countryCode, person.phoneMobile)
              if (parsedTemp === false) personsError = true
              else personsParsed = _.map(personsParsed, x => x.id === person.id ? { ...x, phoneMobile: parsedTemp } : x)
            }
          }

          if (personsError) {
            setErrors(['Eine Telefonnummer konnte nicht normalisiert werden, bitte überprüfen Sie Ihre Eingaben.'])
            return
          }

          const _attachments = tenant
            ? _.omit(tenant._attachments, _.filter(
                tenant.files,
                file => !_.find(files, x => x.id === file.id)
              ).map(file => file.id))
            : {}

          for (let newFile of _.filter(files, x => !!x.data)) {
            _attachments[newFile.id] = { content_type: newFile.data.type, data: newFile.data }
          }

          const tenantUpdate = {
            ...tenant,
            persons: personsParsed,

            realEstateId: tenant ? tenant.realEstateId : realEstate._id,
            ownerOccupation: false,
            currency,
            costs,
            deposit,
            depositType,
            depositState,
            depositPaymentDate,
            rentalStart,
            rentalEnd,
            rentalLimited,

            bankDataKnown,
            iban,
            bic,
            bank,

            files: _.map(files, file => _.omit(file, 'data')),
            _attachments
          }

          const res = !!tenant ? await putTenant(tenantUpdate) : await postTenant(tenantUpdate)

          if (!res.error) {
            if (!!onDataSaved) onDataSaved()
            else navigate('/mieter')
          }
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={4}>
              {title
                ? <Grid item xs={12}>
                  <Typography variant='h6'>{title}</Typography>
                </Grid>
                : null
              }

              {_.map(persons, person => {
                const changePerson = update => {
                  setPersons(_.map(persons, x => x.id === person.id
                    ? {
                      ...person,
                      ...update
                    }
                    : x
                  ))
                }

                return (
                  <Grid item xs={12} container spacing={2} key={person.id}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Firma'
                        onChange={e => changePerson({ company: e.target.value })}
                        value={person.company}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label='Vorname'
                        onChange={e => changePerson({ firstName: e.target.value })}
                        required
                        value={person.firstName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={7}>
                      <TextField
                        fullWidth
                        label='Nachname'
                        onChange={e => changePerson({ lastName: e.target.value })}
                        required
                        value={person.lastName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        fullWidth
                        label='Straße und Hausnummer'
                        onChange={e => changePerson({ street: e.target.value })}
                        required
                        value={person.street}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2}>
                      <TextField
                        fullWidth
                        label='Postleitzahl'
                        onChange={e => changePerson({ zip: e.target.value })}
                        required
                        value={person.zip}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        fullWidth
                        label='Ort'
                        onChange={e => changePerson({ city: e.target.value })}
                        required
                        value={person.city}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        onChange={(e, country) => changePerson({ country: country })}
                        options={countries}
                        renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                        value={person.country}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        fullWidth
                        label='Telefon'
                        onChange={e => changePerson({ phone: e.target.value })}
                        value={person.phone}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        fullWidth
                        label='Telefon (mobil)'
                        onChange={e => changePerson({ phoneMobile: e.target.value })}
                        value={person.phoneMobile}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={11} lg={4}>
                      <TextField
                        fullWidth
                        label='E-Mail'
                        onChange={e => changePerson({ email: e.target.value })}
                        required
                        type='email'
                        value={person.email}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={1}>
                      <Box display='flex'>
                        <IconButton disabled={persons.length <= 1} onClick={() => setPersons(_.filter(persons, x => x.id !== person.id))} sx={{ marginLeft: 'auto' }}>
                          <Delete />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <Button startIcon={<AddSharp/>} onClick={() => setPersons([...persons, { id: uuid(), company: '', firstName: '', lastName: '', street: '', zip: '', city: '', country: countries[0], phone: '', phoneMobile: '', email: '' }])} variant='outlined'>Weiterer Mieter</Button>
              </Grid>

              <Grid item xs={12} container spacing={2}>
                {showRealEstate
                  ? <Grid item xs={12}>
                    <Autocomplete
                      disabled={!!tenant}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      onChange={(e, realEstate) => setRealEstate(realEstate)}
                      options={realEstateOptions}
                      renderInput={(params) => <TextField {...params} label='Immobilie' required variant='standard' style={{ width: '100%' }} />}
                      value={tenant ? _.find(realEstateOptions, x => x._id === tenant.realEstateId) : realEstate}
                    />
                  </Grid>
                  : null
                }

                <Grid item xs={12}>
                  <Typography variant='h6'>Kosten</Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth required variant='standard'>
                    <InputLabel id='currency-label'>Währung</InputLabel>
                    <Select
                      labelId='currency-label'
                      value={currency}
                      label='Währung'
                      onChange={e => setCurrency(e.target.value)}
                    >
                      <MenuItem value='EUR'>EUR (€)</MenuItem>
                      <MenuItem value='USD'>USD ($)</MenuItem>
                      <MenuItem value='GBP'>GBP (£)</MenuItem>
                      <MenuItem value='CHF'>CHF</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={9} sx={{ display: { xs: 'none', sm: 'inline' } }}/>

                {_.map(costs, cost => {
                  const changeCost = costUpdate => {
                    setCosts(_.map(costs, x => x.id === cost.id
                      ? {
                        ...cost,
                        ...costUpdate
                      }
                      : x
                    ))
                  }

                  return (
                    <Fragment key={cost.id}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          disabled={!cost.canDelete}
                          fullWidth
                          inputProps={{ style: { fontWeight: 'bold' } }}
                          label='Bezeichnung'
                          onChange={e => changeCost({ label: e.target.value })}
                          required
                          value={cost.label}
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs={9.5} sm={2}>
                        <TextField
                          fullWidth
                          label='Betrag'
                          onChange={e => changeCost({ value: e.target.value })}
                          required
                          type='number'
                          value={cost.value}
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs={2.5} sm={1}>
                        {cost.canDelete
                          ? <IconButton onClick={() => setCosts(_.filter(costs, x => x.id !== cost.id))}>
                            <Delete />
                          </IconButton>
                          : null
                        }
                      </Grid>
                    </Fragment>
                  )
                })}

                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                  <IconButton onClick={() => setCosts([...costs, { id: uuid(), label: '', value: '', canDelete: true }])}>
                    <AddSharp />
                  </IconButton>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    fullWidth
                    label='Kaution'
                    onChange={e => setDeposit(e.target.value)}
                    required={depositType !== 'Keine Kaution'}
                    type='number'
                    value={deposit}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel id='deposit-type-label'>Art der Kaution</InputLabel>
                    <Select
                      labelId='deposit-type-label'
                      value={depositType}
                      label='Art der Kaution'
                      onChange={e => setDepositType(e.target.value)}
                    >
                      <MenuItem value='Keine Kaution'>Keine Kaution</MenuItem>
                      <MenuItem value='Barkaution'>Barkaution</MenuItem>
                      <MenuItem value='Überweisung'>Überweisung</MenuItem>
                      <MenuItem value='Bankbürgschaft'>Bankbürgschaft</MenuItem>
                      <MenuItem value='Verpfändetes Sparbuch'>Verpfändetes Sparbuch</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel id='type-deposit-status'>Status Kaution</InputLabel>
                    <Select
                      labelId='type-deposit-status'
                      value={depositState}
                      label='Status Kaution'
                      onChange={e => setDepositState(e.target.value)}
                    >
                      <MenuItem value='Ausstehend'>Ausstehend</MenuItem>
                      <MenuItem value='Bezahlt'>Bezahlt</MenuItem>
                      <MenuItem value='Zurückgezahlt'>Zurückgezahlt</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <DatePicker
                    clearable
                    label='Zahlungsdatum Kaution'
                    mask='__.__.____'
                    value={depositPaymentDate}
                    onChange={date => setDepositPaymentDate(date)}
                    renderInput={(params) => <TextField {...params} variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DatePicker
                    clearable
                    label='Mietbeginn'
                    mask='__.__.____'
                    onChange={date => setRentalStart(date)}
                    renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
                    value={rentalStart}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <DatePicker
                    clearable
                    label='Mietende'
                    mask='__.__.____'
                    onChange={date => setRentalEnd(date)}
                    renderInput={(params) => <TextField {...params} variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
                    value={rentalEnd}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rentalLimited}
                        onChange={e => setRentalLimited(e.target.checked)}
                      />
                    }
                    label='Mieterdauer befristet'
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    error={!ibanValid}
                    fullWidth
                    helperText={ibanValid ? '' : 'Diese IBAN ist nicht gültig.'}
                    label='IBAN'
                    onBlur={onIbanBlur}
                    onChange={e => setIban(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? onIbanBlur() : null}
                    value={iban}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={4}>
                  <TextField
                    disabled={!iban || !ibanValid || bankDataKnown}
                    fullWidth
                    label='BIC'
                    onChange={e => setBic(e.target.value)}
                    value={bic}
                    variant='standard'
                  />
                </Grid>

                <Grid item xs={12} sm={8} lg={4}>
                  <TextField
                    disabled={!iban || !ibanValid || bankDataKnown}
                    fullWidth
                    label='Bank'
                    onChange={e => setBank(e.target.value)}
                    value={bank}
                    variant='standard'
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6'>Dokumente</Typography>
                </Grid>

                <Grid item xs={12}>
                  <List sx={{ maxWidth: '800px' }}>
                    {_.map(_.sortBy(files, 'name'), file => (
                      !!editFile && file.id === editFile.id
                        ? <ListItem
                          key={file.id}
                          secondaryAction={
                            <>
                              <IconButton
                                edge='end'
                                onClick={async () => {
                                  setFiles(_.map(files, x => x.id === file.id ? { ...x, name: editFile.name } : x))
                                  setEditFile(null)
                                }}
                                sx={{ color: 'primary.main', marginRight: '2px' }}
                              >
                                <Save/>
                              </IconButton>

                              <IconButton
                                edge='end'
                                onClick={() => setEditFile(null)}
                                sx={{ color: 'primary.main' }}
                              >
                                <Close/>
                              </IconButton>
                            </>
                          }
                          sx={{ '&.MuiListItem-root': { paddingRight: '90px', paddingLeft: '16px' } }}
                        >
                          <TextField
                            fullWidth
                            label='Name'
                            onChange={e => setEditFile({ ...file, name: e.target.value })}
                            required
                            value={editFile.name}
                            variant='standard'
                          />
                        </ListItem>
                        : <ListItem
                          disablePadding
                          key={file.id}
                          secondaryAction={
                            <>
                              <IconButton
                                edge='end'
                                onClick={() => setEditFile(file)}
                                sx={{ color: 'primary.main', marginRight: '2px' }}
                              >
                                <Edit/>
                              </IconButton>

                              <IconButton
                                edge='end'
                                onClick={() => setFiles(_.filter(files, x => x.id !== file.id))}
                                sx={{ color: 'primary.main' }}
                              >
                                <Delete/>
                              </IconButton>
                            </>
                          }
                          sx={{ '&.MuiListItem-root': { paddingRight: '90px', '.MuiListItemButton-root': { paddingRight: '10px' } } }}
                        >
                          <ListItemButton
                            component='a'
                            download={file.name}
                            href={file.data
                              ? URL.createObjectURL(file.data)
                              : 'data:' + tenant._attachments[file.id].content_type + ';base64, ' + tenant._attachments[file.id].data
                            }
                          >
                            <ListItemText primary={file.name}/>
                          </ListItemButton>
                        </ListItem>
                    ))}
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <input
                    id='upload-file'
                    onChange={e => {
                      setFiles([...files, { id: uuid(), data: e.target.files[0], name: e.target.files[0].name }])
                      const fileInput = document.getElementById('upload-file')
                      fileInput.value = null
                    }}
                    style={{ display: 'none' }}
                    type='file'
                  />
                  <label htmlFor='upload-file'><Button component='span' variant='outlined'>Dokument hochladen</Button></label>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            {onCancel
              ? <Button onClick={onCancel} variant={buttonVariant} sx={{ marginLeft: 'auto', marginRight: '10px' }}>
                Abbrechen
              </Button>
              : null
            }

            <LoadingButton loading={isCreating || isUpdating} sx={{ marginLeft: 'auto' }} type='submit' variant={buttonVariant}>
              Speichern
            </LoadingButton>
          </CardActions>
        </Card>
      </form>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />

      <Dialog
        close={() => setShowUpdateConfirmation(false)}
        open={showUpdateConfirmation}
        title='Bestätigung'
        text={
          'Sind Sie sicher, dass die Änderungen inkl. ' +
          _.filter([
            deletedPersons.length > 0 ? 'dem Löschen von Personen' : '',
            deletedCosts.length > 0 ? 'dem Löschen von Kosten' : '',
            deletedFiles.length > 0 ? 'dem Löschen von Dateien' : ''
          ], x => !!x).join(' und ') +
          ' übernommen werden sollen?'
        }
        no={() => setShowUpdateConfirmation(false)}
        noText='Zurück'
        submit={() => {
          setShowUpdateConfirmation(false)
          setUpdateConfirmed(true)
        }}
        submitFormId={formId}
        submitText='Speichern'
      />
    </Box>
}

export default TenantForm
