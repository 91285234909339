import parsePhoneNumberFromString from 'libphonenumber-js'

const checkAndParsePhoneNumber = (countryCode, phoneNumber) => {
  const parsedPhoneNumberObject = parsePhoneNumberFromString(phoneNumber, countryCode)

  if (parsedPhoneNumberObject) {
    return parsedPhoneNumberObject.number
  } else {
    return false
  }
}

export default checkAndParsePhoneNumber
