import _ from 'lodash'
import PouchDB from 'pouchdb'
import { createApi } from '@reduxjs/toolkit/query/react'
import { sliceActions } from './slice'
import { v4 as uuid } from 'uuid'

const customBaseQuery = () =>
  async (args, { getState, dispatch }) => {
    try {
      const db = new PouchDB(getState().app.me.remoteUrl)
      let res = null

      switch (args.method) {
        case 'GET_ONE':
          try {
            res = await db.get(args.id)
          } catch (e) {
            if (e.status === 404) res = null
            else throw e
          }
          break;
        case 'POST':
          res = await db.post({ ...args.body, _id: args.type + '-' + uuid() })
          break;
        case 'PUT':
          res = await db.put(args.body)
          break;
        case 'REMOVE':
          res = await db.remove(args.body)
          break;
        case 'POST_ALL':
          res = await db.bulkDocs(_.map(args.body, x => ({ ...x, _id: args.type + '-' + uuid() })))
          break;
        case 'PUT_ALL':
          res = await db.bulkDocs(args.body)
          break;
        case 'REMOVE_ALL':
          res = await db.bulkDocs(_.map(args.body, x => ({ ...x, _deleted: true })))
          break;
        default:
          const doc = await db.allDocs({ include_docs: true, startkey: args, endkey: args + '\ufff0', attachments: true })
          res = _.map(doc.rows, row => row.doc)
          break;
      }

      return { data: res }
    } catch (e) {
      dispatch(sliceActions.setError({...e, ...args}))
      return { error: e }
    }
  }

const tagTypes = ['realEstate', 'file', 'folder', 'tenant', 'settings']

const pouchApi = createApi({
  reducerPath: 'pouchApi',
  baseQuery: customBaseQuery(),
  refetchOnMountOrArgChange: true,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    postRealEstate: build.mutation({
      query: args => ({ type: 'realEstate', method: 'POST', body: args }),
      invalidatesTags: ['realEstate']
    }),
    getRealEstates: build.query({ query: () => 'realEstate', providesTags: ['realEstate']}),
    putRealEstate: build.mutation({
      query: args => ({ method: 'PUT', body: args }),
      invalidatesTags: ['realEstate']
    }),
    postFile: build.mutation({
      query: args => ({ type: 'file', method: 'POST', body: args }),
      invalidatesTags: ['file']
    }),
    postFiles: build.mutation({
      query: args => ({ type: 'file', method: 'POST_ALL', body: args }),
      invalidatesTags: ['file']
    }),
    getFiles: build.query({ query: () => 'file', providesTags: ['file']}),
    putFiles: build.mutation({
      query: args => ({ method: 'PUT_ALL', body: args }),
      invalidatesTags: ['file']
    }),
    removeFiles: build.mutation({
      query: args => ({ method: 'REMOVE_ALL', body: args }),
      invalidatesTags: ['file']
    }),
    postFolder: build.mutation({
      query: args => ({ type: 'folder', method: 'POST', body: args }),
      invalidatesTags: ['folder']
    }),
    getFolders: build.query({ query: () => 'folder', providesTags: ['folder']}),
    putFolder: build.mutation({
      query: args => ({ method: 'PUT', body: args }),
      invalidatesTags: ['folder']
    }),
    removeFolders: build.mutation({
      query: args => ({ method: 'REMOVE_ALL', body: args }),
      invalidatesTags: ['folder']
    }),
    postFolders: build.mutation({
      query: args => ({ type: 'folder', method: 'POST_ALL', body: args }),
      invalidatesTags: ['folder']
    }),
    getSettings: build.query({
      query: () => ({ method: 'GET_ONE', id: 'settings'}),
      providesTags: ['settings']
    }),
    putSettings: build.mutation({
      query: args => ({ method: 'PUT', body: args }),
      invalidatesTags: ['settings']
    }),
    postTenant: build.mutation({
      query: args => ({ type: 'tenant', method: 'POST', body: args }),
      invalidatesTags: ['tenant']
    }),
    getTenants: build.query({ query: () => 'tenant', providesTags: ['tenant']}),
    putTenant: build.mutation({
      query: args => ({ method: 'PUT', body: args }),
      invalidatesTags: ['tenant']
    })
  })
})

export default pouchApi
export { tagTypes }
