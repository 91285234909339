import _ from 'lodash'
import ErrorDialog from '../app/components/ErrorDialog'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import ProtocolPdf from './ProtocolPdf'
import React, { Fragment, useState, createRef } from 'react'
import Resizer from 'react-image-file-resizer'
import SignaturePad from 'react-signature-canvas'
import { AddAPhoto, AddSharp, Delete } from '@mui/icons-material'
import { Autocomplete, Button, Box, Card, CardActions, CardContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dialog } from 'common_components'
import { Navigate, useNavigate } from 'react-router-dom'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { v4 as uuid } from 'uuid'

const ProtocolForm = ({ type }) => {
  const [postFile] = pouchApi.usePostFileMutation()
  const { data: realEstates, isLoading: isLoadingRealEstates } = pouchApi.useGetRealEstatesQuery()
  const { data: tenants, isLoading: isLoadingTenants } = pouchApi.useGetTenantsQuery()
  const { data: settings, isLoading: isLoadingSettings } = pouchApi.useGetSettingsQuery()

  const navigate = useNavigate()
  const formId = 'protocol-form'
  const [errors, setErrors] = useState([])

  const [realEstate, setRealEstate] = useState(null)
  const [tenant, setTenant] = useState(null)
  const [damages, setDamages] = useState([])
  const [keys, setKeys] = useState([])

  const [showPdfDialog, setShowPdfDialog] = useState(false)
  const [showSignaturePad, setShowSignaturePad] = useState(false)
  const signaturePads = [
    {
      id: uuid(),
      label: 'Vermieter: ' + (realEstate ? realEstate.ownerIsLandlord ? realEstate.ownerFirstName + ' ' + realEstate.ownerLastName : realEstate.landlordFirstName + ' ' + realEstate.landlordLastName : ''),
      ref: createRef()
    },
    ..._.map(tenant ? tenant.persons : [], p => ({ id: uuid(), personId: p.id, label: 'Mieter: ' + p.firstName + ' ' + p.lastName + (p.company ? ', ' + p.company : ''), ref: createRef() }))
  ]

  const realEstateOptions = _.map(
    realEstates,
    x => ({ ...x, label: x.name + ' (' + x.street + ', ' + x.zip + ' ' + x.city + ')' })
  )
  const tenantOptions = realEstate
    ? _.map(
      _.filter(tenants, x => x.realEstateId === realEstate._id),
      x => ({
        ...x,
        label: _.map(x.persons, p => p.firstName + ' ' + p.lastName + (p.company ? ', ' + p.company : '')).join(' und ')
      })
    )
    : []
  const roomOptions = realEstate
    ? _.map(
      realEstate.rooms,
      x => ({ ...x, label: x.name })
    )
    : []

  const title = type === 'handover-protocol' ? 'Wohnungsübergabe-Protokoll' : 'Wohnungsrückgabe-Protokoll'

  return type !== 'handover-protocol' && type !== 'return-protocol'
    ? <Navigate to='/' replace/>
    : isLoadingRealEstates || isLoadingTenants || isLoadingSettings
      ? null
      : <Box sx={{ marginBottom: '50px' }}>
        <Typography variant='h3' sx={{ marginBottom: '20px', overflowWrap: 'break-word', hyphens: 'auto' }}>
          {title}
        </Typography>

        <form
          id={formId}
          onSubmit={async e => {
            e.preventDefault()

            if (_.some(signaturePads, pad => !pad.ref.current || pad.ref.current.isEmpty())) {
              setErrors(['Bitte ergänzen Sie fehlende Unterschriften.'])
              return
            }

            const trimmedSignaturePads = _.map(
              signaturePads,
              pad => ({ ...pad, src: pad.ref.current.toDataURL('image/png') })
            )

            const pdfBlob = await pdf(
              <ProtocolPdf
                title={title}
                realEstate={realEstate}
                tenant={tenant}
                damages={damages}
                keys={keys}
                signaturePads={trimmedSignaturePads}
              />
            ).toBlob()

            let protocol = {}
            for (let damage of damages) {
              for (let image of damage.images) {
                const imageData = image.data.substring(5).split(';base64,')

                protocol = {
                  ...protocol,
                  _attachments: {
                    ...protocol._attachments,
                    [image.id]: { content_type: imageData[0], data: imageData[1] }
                  }
                }
              }
            }

            for (let pad of trimmedSignaturePads) {
              protocol = {
                ...protocol,
                _attachments: {
                  ...protocol._attachments,
                  [pad.id]: { content_type: 'image/png', data: pad.src.split('base64,')[1] }
                }
              }
            }

            const folder = settings
              ? type === 'handover-protocol'
                ? settings.handoverProtocolFolder
                : settings.returnProtocolFolder
              : null

            protocol = {
              ...protocol,

              type,
              parent: folder,
              name: title + ' ' + moment().format('DD.MM.yyyy') + '.pdf',

              realEstateId: realEstate._id,
              tenantId: tenant._id,
              damages: _.map(damages, damage => ({
                ..._.omit(damage, 'room'),
                images: _.map(damage.images, x => x.id),
                roomId: damage.room ? damage.room.id : null
              })),
              keys,
              signatures: _.map(trimmedSignaturePads, pad => _.omit(pad, 'ref', 'src')),
              _attachments: {
                ...protocol._attachments,
                'file': { content_type: pdfBlob.type, data: pdfBlob }
              }
            }

            const res = await postFile(protocol)

            if (!res.error) {
              setShowPdfDialog(false)
              setShowSignaturePad(false)
              navigate(
                '/immobilien/details',
                { state: {
                  _id: realEstate._id,
                  tab: 4,
                  currentRealEstateFolder: folder
                } }
              )
            }
          }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(e, realEstate) => {
                      setTenant(null)
                      setRealEstate(realEstate)
                    }}
                    options={realEstateOptions}
                    renderInput={params => <TextField {...params} label='Immobilie' required variant='standard' fullWidth />}
                    value={realEstate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(e, tenant) => setTenant(tenant)}
                    options={tenantOptions}
                    renderInput={params => <TextField {...params} label='Mieter' required variant='standard' fullWidth />}
                    value={tenant}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Schäden
                  </Typography>
                </Grid>

                {!damages || damages.length < 1
                  ? <Grid item xs={12}>
                    <Typography>
                      Keine Schäden festgestellt.
                    </Typography>
                  </Grid>
                  : null
                }

                {_.map(damages, damage => {
                  const changeDamage = update => {
                    setDamages(_.map(damages, x => x.id === damage.id
                      ? {
                        ...damage,
                        ...update
                      }
                      : x
                    ))
                  }

                  return (
                    <Grid item xs={12} container spacing={3} key={damage.id}>
                      <Grid item xs={12}>
                        <Grid container spacing={5}>
                          {_.map(damage.images, image => (
                            <Grid item xs={16} sm={6} lg={4} key={image.id} container spacing={2} justifyContent='flex-end'>
                              <Grid item xs={12} sx={{ maxHeight: '350px' }}>
                                <img src={image.data} style={{ maxWidth: '100%', maxHeight: '100%' }} alt='Foto des Schadens'/>
                              </Grid>

                              <Grid item xs='auto' sx={{ display: 'flex' }}>
                                <IconButton
                                  color='primary'
                                  onClick={() => changeDamage({ images: _.filter(damage.images, x => x.id !== image.id) })}
                                  sx={{ marginTop: 'auto' }}
                                >
                                  <Delete/>
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}

                          <Grid item xs={16} sm={6} lg={4} sx={{ display: 'flex', minHeight: '150px' }}>
                            <input
                              accept='image/*'
                              id={'image-upload-' + damage.id}
                              onChange={e => {
                                Resizer.imageFileResizer(
                                  e.target.files[0],
                                  350,
                                  350,
                                  e.target.files[0].type === 'image/png' ? 'PNG' : 'JPEG',
                                  80,
                                  0,
                                  compressedImg => changeDamage({ images: [...damage.images, { id: uuid(), data: compressedImg }] }),
                                )
                                e.target.value = null
                              }}
                              style={{ display: 'none' }}
                              type='file'
                            />
                            <label htmlFor={'image-upload-' + damage.id} style={{ margin: 'auto' }}>
                              <IconButton component='span'>
                                <AddAPhoto sx={{ fontSize: 40 }} color='primary'/>
                              </IconButton>
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              onChange={(e, room) => changeDamage({ room })}
                              options={roomOptions}
                              renderInput={params => <TextField {...params} fullWidth label='Raum' variant='standard' />}
                              value={damage.room}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label='Beschreibung'
                              onChange={e => changeDamage({ description: e.target.value })}
                              required
                              value={damage.description}
                              variant='standard'
                              multiline
                              maxRows={4}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label='Verantwortlicher für Behebung'
                              onChange={e => changeDamage({ responsible: e.target.value })}
                              value={damage.responsible}
                              variant='standard'
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <DatePicker
                              label='Frist für Behebung'
                              mask='__.__.____'
                              value={damage.dueDate}
                              onChange={date => changeDamage({ dueDate: date })}
                              renderInput={(params) => <TextField {...params} variant='standard' fullWidth inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }}/>}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Box display='flex' sx={{ height: '100%' }}>
                              <Button
                                startIcon={<Delete/>}
                                onClick={() => setDamages(_.filter(damages, x => x.id !== damage.id))}
                                variant='outlined'
                                size='small'
                                sx={{ marginLeft: 'auto', marginTop: 'auto' }}
                              >
                                Schaden löschen
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}

                <Grid item xs={12}>
                  <Button startIcon={<AddSharp/>} onClick={() => setDamages([...damages, { id: uuid(), images: [], room: null, description: '', responsible: '', dueDate: null }])} variant='outlined'>Weiterer Schaden</Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Übergebene Schlüssel
                  </Typography>
                </Grid>

                {!keys || keys.length < 1
                  ? <Grid item xs={12}>
                    <Typography>
                      Keine Schlüssel übergeben.
                    </Typography>
                  </Grid>
                  : null
                }

                {_.map(keys, key => {
                  const changeKey = update => {
                    setKeys(_.map(keys, x => x.id === key.id
                      ? {
                        ...key,
                        ...update
                      }
                      : x
                    ))
                  }

                  return (
                    <Fragment key={key.id}>
                      <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                          fullWidth
                          label='Bezeichnung'
                          onChange={e => changeKey({ description: e.target.value })}
                          required
                          value={key.description}
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs={10} sm={5} lg={2}>
                        <TextField
                          fullWidth
                          label='Anzahl'
                          onChange={e => changeKey({ quantity: e.target.value })}
                          required
                          type='number'
                          value={key.quantity}
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs={2} sm={1}>
                        <IconButton
                          color='primary'
                          onClick={() => setKeys(_.filter(keys, x => x.id !== key.id))}
                          sx={{ marginTop: 'auto' }}
                          size='small'
                        >
                          <Delete/>
                        </IconButton>
                      </Grid>
                    </Fragment>
                  )
                })}

                <Grid item xs={12}>
                  <Button startIcon={<AddSharp/>} onClick={() => setKeys([...keys, { id: uuid(), description: '', quantity: 1 }])} variant='outlined'>Weitere Schlüssel</Button>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button
                onClick={() => {
                  const form = document.getElementById(formId)
                  if (_.some(damages, x => !!x.dueDate & (moment(x.dueDate).get('year') < 1900 || Number.isNaN(moment(x.dueDate).get('year'))))) {
                    setErrors(['Bitte überprüfen Sie Ihre eingegeben Daten. Ein Datum ist nicht korrekt.'])
                    return
                  }

                  if (!form.checkValidity()) {
                    form.reportValidity()
                  } else {
                    setShowPdfDialog(true)
                  }
                }}
                sx={{ marginLeft: 'auto' }}
                variant='outlined'
              >
                PDF-Vorschau
              </Button>
            </CardActions>
          </Card>

          <Dialog
            close={() => setShowPdfDialog(false)}
            ok={!showSignaturePad ? () => setShowSignaturePad(true) : null}
            okText='Unterschreiben'
            open={showPdfDialog}
            text={!showSignaturePad
              ? <Box sx={{ paddingTop: '20px', height: '60vh', minWidth: { sm: '500px', md: '800px', lg: '1100px' } }}>
                <PDFViewer style={{ width: '100%', height: '100%', border: 'none' }} showToolbar={false}>
                  <ProtocolPdf
                    title={title}
                    realEstate={realEstate}
                    tenant={tenant}
                    damages={damages}
                    keys={keys}
                  />
                </PDFViewer>
              </Box>
              : <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: { sm: '450px', lg: '950px' } }}>
                {_.map(signaturePads, pad => (
                  <Box sx={{ margin: '25px' }} key={pad.id}>
                    <SignaturePad
                      canvasProps={{ style: { border: '2px solid black' }, width: '400px', height: '150px' }}
                      ref={pad.ref}
                    />

                    <Grid container spacing={2} alignItems='center' sx={{ paddingLeft: '4px' }}>
                      <Grid item xs={10}>
                        <Typography>
                          {pad.label}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} sx={{ display: 'flex' }}>
                        <IconButton
                          color='primary'
                          onClick={() => pad.ref.current.clear()}
                          sx={{ marginLeft: 'auto' }}
                        >
                          <Delete/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            }
            no={() => !showSignaturePad ? setShowPdfDialog(false) : setShowSignaturePad(false)}
            noText='Zurück'
            submit={showSignaturePad ? () => {} : null}
            submitFormId={formId}
            submitText='Speichern'
            maxWidth='lg'
            title={title + ' PDF-Vorschau'}
          />
        </form>

        <ErrorDialog
          close={() => setErrors([])}
          errors={errors}
          ok={() => setErrors([])}
          open={errors.length > 0}
        />
      </Box>
}

export default ProtocolForm
