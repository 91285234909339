import _ from 'lodash'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import RealEstateAdministrationBox from '../realEstates/RealEstateAdministrationBox'
import RealEstateAdministrationForm from '../realEstates/RealEstateAdministrationForm'
import RealEstateDescriptionBox from '../realEstates/RealEstateDescriptionBox'
import RealEstateDescriptionForm from '../realEstates/RealEstateDescriptionForm'
import RealEstateFilesBox from '../realEstates/RealEstateFilesBox'
import RealEstateLocationBox from '../realEstates/RealEstateLocationBox'
import RealEstateLocationForm from '../realEstates/RealEstateLocationForm'
import RealEstateMeterReadingBox from '../realEstates/RealEstateMeterReadingBox'
import RealEstateMeterReadingForm from '../realEstates/RealEstateMeterReadingForm'
import RealEstatePurchaseBox from '../realEstates/RealEstatePurchaseBox'
import RealEstatePurchaseForm from '../realEstates/RealEstatePurchaseForm'
import RealEstateTenantBox from '../realEstates/RealEstateTenantBox'
import RealEstateTenantForm from '../realEstates/RealEstateTenantForm'
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material'
import { useLocation } from 'react-router-dom'

const RealEstateDetails = () => {
  const { state } = useLocation()
  const { data: realEstates, isLoading } = pouchApi.useGetRealEstatesQuery()
  const { data: files, isLoading: isLoadingFiles } = pouchApi.useGetFilesQuery()

  const realEstate = isLoading || !state ? null : _.find(realEstates, x => x._id === state._id)
  const realEstateFiles = isLoadingFiles || !realEstate ? null : _.filter(files, file => file.realEstateId === realEstate._id)
  const [tab, setTab] = useState(state.tab || 0)
  const [editLocation, setEditLocation] = useState(false)
  const [editAdministration, setEditAdministration] = useState(false)
  const [editPurchase, setEditPurchase] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [editMeterReading, setEditMeterReading] = useState(false)
  const [editCurrentTenant, setEditCurrentTenant] = useState(false)

  return !!realEstate
    ? <Box sx={{ marginBottom: '50px'}}>
      <Card>
        <CardContent>
          {editLocation
            ? <RealEstateLocationForm realEstate={realEstate} endEdit={() => setEditLocation(false)}/>
            : <RealEstateLocationBox realEstate={realEstate} onEditClick={() => setEditLocation(true)}/>
          }

          <Tabs
            value={tab}
            onChange={(e, tab) => {
              setEditLocation(false)
              setEditAdministration(false)
              setEditPurchase(false)
              setEditDescription(false)
              setEditMeterReading(false)
              setEditCurrentTenant(false)
              setTab(tab)
            }}
            variant='scrollable'
            scrollButtons='auto'
            sx={{ margin: '10px 0', backgroundColor: 'tertiary.main', borderRadius: '5px' }}
          >
            {_.map(
              ['Verwaltung', 'Erwerb', 'Beschreibung', 'Zählerstände', 'Dokumente', 'Mieter'],
              (label, i) => <Tab key={i} label={label} sx={{ borderRadius: '3px', backgroundColor: tab === i ? 'secondary.main' : 'tertiary.main' }}/>
            )}
          </Tabs>

          {tab === 0
            ? editAdministration
              ? <RealEstateAdministrationForm realEstate={realEstate} endEdit={() => setEditAdministration(false)}/>
              : <RealEstateAdministrationBox realEstate={realEstate} onEditClick={() => setEditAdministration(true)}/>
            : null
          }

          {tab === 1
            ? editPurchase
              ? <RealEstatePurchaseForm realEstate={realEstate} endEdit={() => setEditPurchase(false)}/>
              : <RealEstatePurchaseBox realEstate={realEstate} onEditClick={() => setEditPurchase(true)}/>
            : null
          }

          {tab === 2
            ? editDescription
              ? <RealEstateDescriptionForm realEstate={realEstate} endEdit={() => setEditDescription(false)}/>
              : <RealEstateDescriptionBox realEstate={realEstate} onEditClick={() => setEditDescription(true)}/>
            : null
          }

          {tab === 3
            ? editMeterReading
              ? <RealEstateMeterReadingForm realEstate={realEstate} endEdit={() => setEditMeterReading(false)}/>
              : <RealEstateMeterReadingBox realEstate={realEstate} onEditClick={() => setEditMeterReading(true)}/>
            : null
          }

          {tab === 4
            ? <RealEstateFilesBox realEstate={realEstate} realEstateFiles={realEstateFiles}/>
            : null
          }

          {tab === 5
            ? editCurrentTenant
              ? <RealEstateTenantForm realEstate={realEstate} endEdit={() => setEditCurrentTenant(false)}/>
              : <RealEstateTenantBox realEstate={realEstate} onEditClick={() => setEditCurrentTenant(true)}/>
            : null
          }
        </CardContent>
      </Card>
    </Box>
    : null
}

export default RealEstateDetails
