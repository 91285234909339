import _ from 'lodash'
import api from '../app/api'
import getCurrencyString from '../app/utils/getCurrencyString'
import getDateString from '../app/utils/getDateString'
import pouchApi from '../app/pouchApi'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const TenantCard = ({ onEditClick, setShowDetails, showAll, tenant, showRealEstate = true }) => {
  const { data: me } = api.useGetMeQuery()
  const { data: realEstates, isLoading } = pouchApi.useGetRealEstatesQuery()
  const realEstate = _.find(realEstates, x => x._id === tenant.realEstateId)

  return isLoading
    ? null
    : (
      <Card
        key={tenant._id}
        sx={{ margin: '30px 0' }}
        onClick={e => {
          if (_.some(['DIV', 'P', 'svg', 'path'], item => item === e.target.nodeName)) setShowDetails(showAll ? null : tenant._id)
        }}
      >
        <CardContent sx={{ ':last-child': { paddingBottom: '10px' } }}>
          <Grid container spacing={2}>
            {_.map(tenant.persons, person => (
              <Grid item xs={12} container spacing={2} key={person.id}>
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: 'bold' }}>{person.firstName + ' ' + person.lastName + (person.company ? ', ' + person.company : '')}</Typography>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Anschrift</Typography>
                  <Typography>{person.street + ', ' + person.zip + ' ' + person.city + ' (' + person.country.label + ')'}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
                  <Typography><a href={'tel:' + person.phone}>{person.phone}</a></Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
                  <Typography><a href={'tel:' + person.phoneMobile}>{person.phoneMobile}</a></Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                  <Typography><a href={'mailto:' + person.email}>{person.email}</a></Typography>
                </Grid>
              </Grid>
            ))}

            {showRealEstate
              ? <Grid item xs={12}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Immobilie</Typography>
                <Typography><Link to='/immobilien/details' state={{ _id: realEstate._id }}>{realEstate.name + ' (' + realEstate.street + ', ' + realEstate.zip + ' ' + realEstate.city + ')'}</Link></Typography>
              </Grid>
              : null
            }

            {showAll
              ? <>
                <Grid item xs={12} container spacing={2}>
                  {_.map(tenant.costs, cost => (
                    <Grid item xs={12} sm={6} lg={2} key={cost.id}>
                      <Typography variant='body2' sx={{ opacity: '50%' }}>{cost.label}</Typography>
                      <Typography>{getCurrencyString(cost.value, tenant.currency)}</Typography>
                    </Grid>
                  ))}
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Kaution</Typography>
                  <Typography>{getCurrencyString(tenant.deposit, tenant.currency)}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Art der Kaution</Typography>
                  <Typography>{tenant.depositType}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Status Kaution</Typography>
                  <Typography>{tenant.depositState}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Zahlungsdatum Kaution</Typography>
                  <Typography>{getDateString(tenant.depositPaymentDate)}</Typography>
                </Grid>

                <Grid item xs={12} sm={4} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Mietbeginn</Typography>
                  <Typography>{getDateString(tenant.rentalStart)}</Typography>
                </Grid>

                <Grid item xs={12} sm={4} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Mietende</Typography>
                  <Typography>{getDateString(tenant.rentalEnd)}</Typography>
                </Grid>

                <Grid item xs={12} sm={4} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Mieterdauer</Typography>
                  <Typography>{tenant.rentalLimited ? 'befristet' : 'unbefristet'}</Typography>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>IBAN</Typography>
                  <Typography>{tenant.iban}</Typography>
                </Grid>

                <Grid item xs={12} sm={4} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>BIC</Typography>
                  <Typography>{tenant.bic}</Typography>
                </Grid>

                <Grid item xs={12} sm={8} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Bank</Typography>
                  <Typography>{tenant.bank}</Typography>
                </Grid>

                {!!tenant.files
                  ? <Grid item xs={12}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Dokumente</Typography>

                    {_.map(_.sortBy(tenant.files, 'name'), file => (
                      <Typography key={file.id} style={{ marginTop: '5px' }}>
                        <a
                          download={file.name}
                          href={'data:' + tenant._attachments[file.id].content_type + ';base64, ' + tenant._attachments[file.id].data}
                        >
                          {file.name}
                        </a>
                      </Typography>
                    ))}
                  </Grid>
                  : null
                }
              </>
              : null
            }

            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Box sx={{ marginTop: 'auto', marginLeft: '50%' }}>
                {showAll ? <ExpandLess/> : <ExpandMore/>}
              </Box>

              {onEditClick && me.role !== 'user'
                ? <Button onClick={onEditClick} sx={{ marginLeft: 'auto' }} variant='outlined'>
                  Bearbeiten
                </Button>
                : null
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
}

export default TenantCard
