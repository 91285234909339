import _ from 'lodash'
import ErrorDialog from '../app/components/ErrorDialog'
import FilesBox from './FilesBox'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { useLocation } from 'react-router-dom'

const RealEstateFilesBox = ({ realEstate, realEstateFiles }) => {
  const { state } = useLocation()

  const [errors, setErrors] = useState([])
  const [currentFolder, setCurrentFolder] = useState(state.currentRealEstateFolder || null)
  const [isUpdating, setIsUpdating] = useState(false)

  const [removeFiles] = pouchApi.useRemoveFilesMutation()
  const [postFiles] = pouchApi.usePostFilesMutation()
  const [putFiles] = pouchApi.usePutFilesMutation()

  const saveFiles = async files => {
    if (_.some(files, file => !file.name)) {
      setErrors(['Bitte geben Sie einen Namen für das Dokument an.'])
      return false
    }

    setIsUpdating(true)

    const deletedFiles = _.filter(
      realEstateFiles,
      file => !_.find(files, x => x._id === file._id)
    )
    const resDelete = await removeFiles(deletedFiles)

    const newFiles = _.filter(files, x => !!x.data)
    const resCreate = await postFiles(_.map(newFiles, file => ({
      ..._.omit(file, 'data', '_id'),
      type: 'default',
      realEstateId: realEstate._id,

      _attachments: {
        'file': { content_type: file.data.type, data: file.data }
      }
    })))

    const updateFiles = _.filter(files, x => !x.data)
    const resUpdate = await putFiles(updateFiles)

    setIsUpdating(false)

    return !resDelete.error && !resCreate.error && !resUpdate.error
  }

  return <Box>
    <Card>
      <CardContent>
        <FilesBox
          currentFolder={currentFolder}
          files={realEstateFiles}
          getFileHref={file => {
            if (file.data) {
              return URL.createObjectURL(file.data)
            } else {
              const attachment = file._attachments['file']
              return 'data:' + attachment.content_type + ';base64, ' + attachment.data
            }
          }}
          setCurrentFolder={setCurrentFolder}
          setFiles={files => saveFiles(files)}
          isUpdating={isUpdating}
        />
      </CardContent>
    </Card>

    <ErrorDialog
      close={() => setErrors([])}
      errors={errors}
      ok={() => setErrors([])}
      open={errors.length > 0}
    />
  </Box>
}

export default RealEstateFilesBox
