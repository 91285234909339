import _ from 'lodash'
import api from '../app/api'
import getCurrentPath from '../app/utils/getCurrentPath'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Button, Box, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import { Close, Delete, Edit, Folder, FolderSpecial, Save } from '@mui/icons-material'
import { Dialog } from 'common_components'
import { v4 as uuid } from 'uuid'

const FilesBox = ({ currentFolder, files, getFileHref, setCurrentFolder, setFiles, isUpdating = false }) => {
  const { data: me } = api.useGetMeQuery()
  const { data: folders, isLoading } = pouchApi.useGetFoldersQuery()
  const restrictedFolders = me.role === 'user' ? _.filter(folders, x => x.access === 'Unbeschränkt') : folders
  const [editFile, setEditFile] = useState(null)
  const [deleteFile, setDeleteFile] = useState(null)

  return (
    isLoading || isUpdating
    ? null
    : <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6'>Dokumente</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ marginTop: '20px' }}>
            aktueller Pfad: {_.map(getCurrentPath(currentFolder, folders), folderId => (
              <span
                key={folderId}
                onClick={() => setCurrentFolder(folderId)}
                style={{ cursor: 'pointer'}}
              >
                {folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'}
              </span>
            )).reduce((prev, curr) => [prev, ' > ', curr])}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List>
            {_.map(_.sortBy(_.filter(restrictedFolders, x => x.parent === currentFolder), 'name'), folder => (
              <ListItem key={folder._id} disablePadding>
                <ListItemButton onClick={() => setCurrentFolder(folder._id)}>
                  <ListItemIcon>{folder.access === 'Beschränkt' ? <FolderSpecial/> : <Folder/>}</ListItemIcon>
                  <ListItemText primary={folder.name} sx={{ overflowWrap: 'break-word', hyphens: 'auto' }}/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <List sx={{ maxWidth: '800px' }}>
            {_.map(_.sortBy(_.filter(files, x => x.parent === currentFolder), 'name'), file => (
              !!editFile && file._id === editFile._id
                ? <ListItem
                  key={file._id}
                  secondaryAction={
                    <>
                      <IconButton
                        edge='end'
                        onClick={async () => {
                          const saved = await setFiles(_.map(files, x => x._id === file._id ? { ...x, name: editFile.name } : x))
                          if (saved) setEditFile(null)
                        }}
                        sx={{ color: 'primary.main', marginRight: '2px' }}
                      >
                        <Save/>
                      </IconButton>

                      <IconButton
                        edge='end'
                        onClick={() => setEditFile(null)}
                        sx={{ color: 'primary.main' }}
                      >
                        <Close/>
                      </IconButton>
                    </>
                  }
                  sx={{ '&.MuiListItem-root': { paddingRight: '90px', paddingLeft: '16px' } }}
                >
                  <TextField
                    fullWidth
                    label='Name'
                    onChange={e => setEditFile({ ...file, name: e.target.value })}
                    required
                    value={editFile.name}
                    variant='standard'
                  />
                </ListItem>
                : <ListItem
                  disablePadding
                  key={file._id}
                  secondaryAction={
                    <>
                      <IconButton
                        edge='end'
                        onClick={() => setEditFile(file)}
                        sx={{ color: 'primary.main', marginRight: '2px' }}
                      >
                        <Edit/>
                      </IconButton>

                      <IconButton
                        edge='end'
                        onClick={() => setDeleteFile(file)}
                        sx={{ color: 'primary.main' }}
                      >
                        <Delete/>
                      </IconButton>
                    </>
                  }
                  sx={{ '&.MuiListItem-root': { paddingRight: '90px', '.MuiListItemButton-root': { paddingRight: '10px' } } }}
                >
                  <ListItemButton
                    component='a'
                    download={file.name}
                    href={getFileHref(file)}
                  >
                    <ListItemText primary={file.name}/>
                  </ListItemButton>
                </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <input
            id='upload-file'
            onChange={e => {
              setFiles([...files, { _id: uuid(), data: e.target.files[0], name: e.target.files[0].name, parent: currentFolder }])
              const fileInput = document.getElementById('upload-file')
              fileInput.value = null
            }}
            style={{ display: 'none' }}
            type='file'
          />
          <label htmlFor='upload-file'><Button component='span' variant='outlined'>Dokument hochladen</Button></label>
        </Grid>
      </Grid>

      <Dialog
        close={() => setDeleteFile(null)}
        open={!!deleteFile}
        title="Lösch-Bestätigung"
        text="Sind Sie sicher, dass die Datei gelöscht werden soll?"
        no={() => setDeleteFile(null)}
        noText="Zurück"
        yes={() => {
          setFiles(_.filter(files, x => x._id !== deleteFile._id))
          setDeleteFile(null)
        }}
        yesText="Löschen"
      />
    </Box>
  )
}

export default FilesBox
