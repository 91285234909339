import _ from 'lodash'
import api from '../app/api'
import { Button, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'

const RealEstateDescriptionBox = ({ realEstate, onEditClick }) => {
  const { data: me } = api.useGetMeQuery()

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Allgemeines</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Benutzung</Typography>
              <Typography>{realEstate.usage}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Art der Immobilie</Typography>
              <Typography>{realEstate.type}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Baujahr</Typography>
              <Typography>{realEstate.constructionYear}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Heizungsart</Typography>
              <Typography>{realEstate.heatingType}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Allgemeinzustand</Typography>
              <Typography>{realEstate.generalCondition}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Räume</Typography>
            </Grid>

            {_.reduce(_.map(realEstate.rooms, room => (
              <Grid container spacing={2} item xs={12} key={room.id}>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Bezeichnung</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{room.name}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Raumgröße in m²</Typography>
                  <Typography>{room.size}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Fenster</Typography>
                  <Typography>{room.windows}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Rollläden</Typography>
                  <Typography>{room.shutters}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Wandbelag</Typography>
                  <Typography>{room.wallCovering}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Bodenbelag</Typography>
                  <Typography>{room.flooring}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Mobiliar</Typography>
                  <Typography>{room.furnishings}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Beleuchtung</Typography>
                  <Typography>{room.lighting}</Typography>
                </Grid>


                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Steckdosen</Typography>
                  <Typography>{room.sockets}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Heizung</Typography>
                  <Typography>{room.heating}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Bemerkungen</Typography>
                  <Typography>{room.comment}</Typography>
                </Grid>
              </Grid>
            )), (prev, next) => (
              [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
            ))}
          </Grid>
        </CardContent>
      </Card>

      {me.role === 'user'
        ? null
        : <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={onEditClick} variant='outlined' sx={{ marginLeft: 'auto' }}>
            Bearbeiten
          </Button>
        </Box>
      }
    </Box>
  )
}

export default RealEstateDescriptionBox
