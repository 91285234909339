import _ from 'lodash'
import ConfirmationPdf from '../documents/ConfirmationPdf'
import ErrorDialog from '../app/components/ErrorDialog'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState, createRef } from 'react'
import SignaturePad from 'react-signature-canvas'
import { AddSharp, Delete } from '@mui/icons-material'
import { Autocomplete, Button, Box, Card, CardActions, CardContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const DocumentConfirmationCreate = () => {
  const [postFile] = pouchApi.usePostFileMutation()
  const { data: realEstates, isLoading: isLoadingRealEstates } = pouchApi.useGetRealEstatesQuery()
  const { data: tenants, isLoading: isLoadingTenants } = pouchApi.useGetTenantsQuery()
  const { data: settings, isLoading: isLoadingSettings } = pouchApi.useGetSettingsQuery()

  const navigate = useNavigate()
  const formId = 'landlord-confirmation-form'
  const [errors, setErrors] = useState([])

  const [realEstate, setRealEstate] = useState(null)
  const [tenant, setTenant] = useState(null)
  const [additionalPersons, setAdditionalPersons] = useState([])

  const [showPdfDialog, setShowPdfDialog] = useState(false)
  const [showSignaturePad, setShowSignaturePad] = useState(false)
  const signaturePads = [
    {
      id: uuid(),
      label: 'Wohnungsgeber: ' + (realEstate ? realEstate.ownerIsLandlord ? realEstate.ownerFirstName + ' ' + realEstate.ownerLastName : realEstate.landlordFirstName + ' ' + realEstate.landlordLastName : ''),
      ref: createRef()
    }
  ]

  const realEstateOptions = _.map(
    realEstates,
    x => ({ ...x, label: x.name + ' (' + x.street + ', ' + x.zip + ' ' + x.city + ')' })
  )
  const tenantOptions = realEstate
    ? _.map(
      _.filter(tenants, x => x.realEstateId === realEstate._id),
      x => ({
        ...x,
        label: _.map(x.persons, p => p.firstName + ' ' + p.lastName + (p.company ? ', ' + p.company : '')).join(' und ')
      })
    )
    : []

  return isLoadingRealEstates || isLoadingTenants || isLoadingSettings
    ? null
    : <Box sx={{ marginBottom: '50px' }}>
      <Typography variant='h3' sx={{ marginBottom: '20px', overflowWrap: 'break-word', hyphens: 'auto' }}>
        Wohnungsgeber-Bestätigung
      </Typography>

      <form
        id={formId}
        onSubmit={async e => {
          e.preventDefault()

          if (_.some(signaturePads, pad => !pad.ref.current || pad.ref.current.isEmpty())) {
            setErrors(['Bitte ergänzen Sie die fehlende Unterschrift.'])
            return
          }

          const trimmedSignaturePads = _.map(
            signaturePads,
            pad => ({ ...pad, src: pad.ref.current.toDataURL('image/png') })
          )

          const pdfBlob = await pdf(
            <ConfirmationPdf
              realEstate={realEstate}
              tenant={tenant}
              additionalPersons={additionalPersons}
              signaturePads={trimmedSignaturePads}
            />
          ).toBlob()

          let protocol = {}

          for (let pad of trimmedSignaturePads) {
            protocol = {
              ...protocol,
              _attachments: {
                ...protocol._attachments,
                [pad.id]: { content_type: 'image/png', data: pad.src.split('base64,')[1] }
              }
            }
          }

          const landlordConfirmationFolder = settings
            ? settings.landlordConfirmationFolder
            : null

          protocol = {
            ...protocol,

            type: 'landlord-confirmation',
            parent: landlordConfirmationFolder,
            name: 'Wohnungsgeberbestätigung ' + moment().format('DD.MM.yyyy') + '.pdf',

            realEstateId: realEstate._id,
            tenantId: tenant._id,
            additionalPersons,
            signatures: _.map(trimmedSignaturePads, pad => _.omit(pad, 'ref', 'src')),
            _attachments: {
              ...protocol._attachments,
              'file': { content_type: pdfBlob.type, data: pdfBlob }
            }
          }

          const res = await postFile(protocol)

          if (!res.error) {
            setShowPdfDialog(false)
            setShowSignaturePad(false)
            navigate(
              '/immobilien/details',
              { state: {
                _id: realEstate._id,
                tab: 4,
                currentRealEstateFolder: landlordConfirmationFolder
              } }
            )
          }
        }}
      >
        <Card>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  onChange={(e, realEstate) => {
                    setTenant(null)
                    setRealEstate(realEstate)
                  }}
                  options={realEstateOptions}
                  renderInput={params => <TextField {...params} label='Immobilie' required variant='standard' fullWidth />}
                  value={realEstate}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  onChange={(e, tenant) => setTenant(tenant)}
                  options={tenantOptions}
                  renderInput={params => <TextField {...params} label='Mieter' required variant='standard' fullWidth />}
                  value={tenant}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Weitere Personen
                </Typography>
              </Grid>

              {!additionalPersons || additionalPersons.length < 1
                ? <Grid item xs={12}>
                  <Typography>
                    Keine weiteren Personen erfasst.
                  </Typography>
                </Grid>
                : null
              }

              {_.map(additionalPersons, person => {
                const changePerson = update => {
                  setAdditionalPersons(_.map(additionalPersons, x => x.id === person.id
                    ? {
                      ...person,
                      ...update
                    }
                    : x
                  ))
                }

                return (
                  <Grid item xs={12} container spacing={3} key={person.id}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label='Vorname'
                        onChange={e => changePerson({ firstName: e.target.value })}
                        required
                        value={person.firstName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label='Nachname'
                        onChange={e => changePerson({ lastName: e.target.value })}
                        required
                        value={person.lastName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <Box display='flex' sx={{ height: '100%' }}>
                        <Button
                          startIcon={<Delete/>}
                          onClick={() => setAdditionalPersons(_.filter(additionalPersons, x => x.id !== person.id))}
                          variant='outlined'
                          size='small'
                          sx={{ marginLeft: 'auto', marginTop: 'auto' }}
                        >
                          Person löschen
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <Button startIcon={<AddSharp/>} onClick={() => setAdditionalPersons([...additionalPersons, { id: uuid(), firstName: '', lastName: '' }])} variant='outlined'>Weitere Person</Button>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Button
              onClick={() => {
                const form = document.getElementById(formId)

                if (!form.checkValidity()) {
                  form.reportValidity()
                } else {
                  setShowPdfDialog(true)
                }
              }}
              sx={{ marginLeft: 'auto' }}
              variant='outlined'
            >
              PDF-Vorschau
            </Button>
          </CardActions>
        </Card>

        <Dialog
          close={() => setShowPdfDialog(false)}
          ok={!showSignaturePad ? () => setShowSignaturePad(true) : null}
          okText='Unterschreiben'
          open={showPdfDialog}
          text={!showSignaturePad
            ? <Box sx={{ paddingTop: '20px', height: '60vh', minWidth: { sm: '500px', md: '800px', lg: '1100px' } }}>
              <PDFViewer style={{ width: '100%', height: '100%', border: 'none' }} showToolbar={false}>
                <ConfirmationPdf
                  realEstate={realEstate}
                  tenant={tenant}
                  additionalPersons={additionalPersons}
                />
              </PDFViewer>
            </Box>
            : <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              {_.map(signaturePads, pad => (
                <Box sx={{ margin: '25px' }} key={pad.id}>
                  <SignaturePad
                    canvasProps={{ style: { border: '2px solid black' }, width: '400px', height: '150px' }}
                    ref={pad.ref}
                  />

                  <Grid container spacing={2} alignItems='center' sx={{ paddingLeft: '4px' }}>
                    <Grid item xs={10}>
                      <Typography>
                        {pad.label}
                      </Typography>
                    </Grid>

                    <Grid item xs={2} sx={{ display: 'flex' }}>
                      <IconButton
                        color='primary'
                        onClick={() => pad.ref.current.clear()}
                        sx={{ marginLeft: 'auto' }}
                      >
                        <Delete/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          }
          no={() => !showSignaturePad ? setShowPdfDialog(false) : setShowSignaturePad(false)}
          noText='Zurück'
          submit={showSignaturePad ? () => {} : null}
          submitFormId={formId}
          submitText='Speichern'
          maxWidth='lg'
          title='Wohnungsgeber-Bestätigung PDF-Vorschau'
        />
      </form>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />
    </Box>
}

export default DocumentConfirmationCreate
