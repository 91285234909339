import _ from 'lodash'
import api from '../app/api'
import React, { useState } from 'react'
import { Button, Box, Card, CardContent, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { ReadMore } from '@mui/icons-material'

const RealEstateAdministrationBox = ({ realEstate, onEditClick }) => {
  const { data: me } = api.useGetMeQuery()
  const [showContact, setShowContact] = useState(null)

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Hausverwaltung</Typography>
            </Grid>

            {realEstate.managementCompany || realEstate.managementStreet || realEstate.managementZip || realEstate.managementCountry || realEstate.managementPhone || realEstate.managementPhoneMobile || realEstate.managementEmail || (realEstate.managementContacts && realEstate.managementContacts.length > 0)
              ? <>
                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Firma</Typography>
                  <Typography>{realEstate.managementCompany}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
                  <Typography>{realEstate.managementStreet}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
                  <Typography>{realEstate.managementZip}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
                  <Typography>{realEstate.managementCity}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
                  <Typography>{realEstate.managementCountry ? realEstate.managementCountry.label : ""}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
                  <Typography><a href={'tel:' + realEstate.managementPhone}>{realEstate.managementPhone}</a></Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
                  <Typography><a href={'tel:' + realEstate.managementPhoneMobile}>{realEstate.managementPhoneMobile}</a></Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                  <Typography><a href={'mailto:' + realEstate.managementEmail}>{realEstate.managementEmail}</a></Typography>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>IBAN</Typography>
                  <Typography>{realEstate.managementIban}</Typography>
                </Grid>

                  <Grid item xs={12} sm={4} lg={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>BIC</Typography>
                    <Typography>{realEstate.managementBic}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={8} lg={4}>
                    <Typography variant='body2' sx={{ opacity: '50%' }}>Bank</Typography>
                    <Typography>{realEstate.managementBank}</Typography>
                  </Grid>

                <Grid item xs={12} sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Kontakte</Typography>
                </Grid>

                {_.reduce(_.map(realEstate.managementContacts, contact => (
                  <Grid item xs={12} container spacing={2} key={contact.id}>
                    <Grid item xs={12} sm={1.5}>
                      <Typography variant='body2' sx={{ opacity: '50%' }}>Anrede</Typography>
                      <Typography>{contact.salutation}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={2}>
                      <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
                      <Typography>{contact.firstName}</Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
                      <Typography>{contact.lastName}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={4.5}>
                      <Typography variant='body2' sx={{ opacity: '50%' }}>Tätigkeit</Typography>
                      <Typography>{contact.work}</Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton onClick={() => setShowContact(contact)}>
                        <ReadMore />
                      </IconButton>
                    </Grid>
                  </Grid>
                )), (prev, next) => (
                  [prev, <Divider sx={{ width: 'calc(100% - 16px)', marginTop: '16px' }} variant='middle' key={prev.key + '-diviver'}/>, next]
                ))}
              </>
              : <Grid item xs={12}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Es gibt keine Hausverwaltung</Typography>
              </Grid>
            }
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Eigentümer</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Firma</Typography>
              <Typography>{realEstate.ownerCompany}</Typography>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Anrede</Typography>
              <Typography>{realEstate.ownerSalutation}</Typography>
            </Grid>

            <Grid item xs={12} sm={4.5}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
              <Typography>{realEstate.ownerFirstName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
              <Typography>{realEstate.ownerLastName}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
              <Typography>{realEstate.ownerStreet}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
              <Typography>{realEstate.ownerZip}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
              <Typography>{realEstate.ownerCity}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
              <Typography>{realEstate.ownerCountry.label}</Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
              <Typography><a href={'tel:' + realEstate.ownerPhone}>{realEstate.ownerPhone}</a></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
              <Typography><a href={'tel:' + realEstate.ownerPhoneMobile}>{realEstate.ownerPhoneMobile}</a></Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
              <Typography><a href={'mailto:' + realEstate.ownerEmail}>{realEstate.ownerEmail}</a></Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant='body2' sx={{ opacity: '50%' }}>Gesetzlicher Vertreter</Typography>
              <Typography>{realEstate.ownerLegalRepresentative}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ marginTop: '10px' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Vermieter</Typography>
            </Grid>

            {realEstate.ownerIsLandlord
              ? <Grid item xs={12}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Eigentümer ist auch Vermieter</Typography>
                </Grid>
              : <>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Firma</Typography>
                  <Typography>{realEstate.landlordCompany}</Typography>
                </Grid>

                <Grid item xs={12} sm={1.5}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Anrede</Typography>
                  <Typography>{realEstate.landlordSalutation}</Typography>
                </Grid>

                <Grid item xs={12} sm={4.5}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
                  <Typography>{realEstate.landlordFirstName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
                  <Typography>{realEstate.landlordLastName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Straße und Hausnummer</Typography>
                  <Typography>{realEstate.landlordStreet}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Ort</Typography>
                  <Typography>{realEstate.landlordZip}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Postleitzahl</Typography>
                  <Typography>{realEstate.landlordCity}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Land</Typography>
                  <Typography>{realEstate.landlordCountry.label}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
                  <Typography><a href={'tel:' + realEstate.landlordPhone}>{realEstate.landlordPhone}</a></Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
                  <Typography><a href={'tel:' + realEstate.landlordPhoneMobile}>{realEstate.landlordPhoneMobile}</a></Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                  <Typography><a href={'mailto:' + realEstate.landlordEmail}>{realEstate.landlordEmail}</a></Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Gesetzlicher Vertreter</Typography>
                  <Typography>{realEstate.landlordLegalRepresentative}</Typography>
                </Grid>
              </>
            }
          </Grid>
        </CardContent>
      </Card>

      {me.role === 'user'
        ? null
        : <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={onEditClick} variant='outlined' sx={{ marginLeft: 'auto' }}>
            Bearbeiten
          </Button>
        </Box>
      }

      <Dialog
        close={() => setShowContact(null)}
        ok={() => setShowContact(null)}
        okText='Schließen'
        open={!!showContact}
        text={
          showContact
          ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Anrede</Typography>
                <Typography>{showContact.salutation}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
                <Typography>{showContact.firstName}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
                <Typography>{showContact.lastName}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon</Typography>
                <Typography><a href={'tel:' + showContact.phone}>{showContact.phone}</a></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Telefon (mobil)</Typography>
                <Typography><a href={'tel:' + showContact.phoneMobile}>{showContact.phoneMobile}</a></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                <Typography><a href={'mailto:' + showContact.email}>{showContact.email}</a></Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='body2' sx={{ opacity: '50%' }}>Tätigkeit</Typography>
                <Typography>{showContact.work}</Typography>
              </Grid>
            </Grid>
          )
          : null
        }
      />
    </Box>
  )
}

export default RealEstateAdministrationBox
