import _ from 'lodash'
import getDateString from '../app/utils/getDateString'
import moment from 'moment'
import { AddSharp } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'

const MeterDetailsBox = ({ meter, onAddNewMeterReading }) => {
  const currentValue = _.last(_.sortBy(meter.values, x => moment(x.date)))

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant='body2' sx={{ opacity: '50%' }}>Bezeichnung</Typography>
        <Typography>{meter.name}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant='body2' sx={{ opacity: '50%' }}>Zählernummer</Typography>
        <Typography>{meter.number}</Typography>
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <Typography variant='body2' sx={{ opacity: '50%' }}>Datum</Typography>
        <Typography>{getDateString(currentValue.date)}</Typography>
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <Typography variant='body2' sx={{ opacity: '50%' }}>Zählerstand</Typography>
        <Typography>{currentValue.value}</Typography>
      </Grid>

      <Grid item xs={12} sm={3} lg={1}>
        <Typography variant='body2' sx={{ opacity: '50%' }}>Einheit</Typography>
        <Typography>{meter.unit}</Typography>
      </Grid>

      <Grid item xs={1}>
        <IconButton onClick={onAddNewMeterReading}>
          <AddSharp />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default MeterDetailsBox
