const countries = ([
  { key: 'Germany', label: 'Deutschland', countryCode: 'DE' },
  { key: 'Austria', label: 'Österreich', countryCode: 'AT' },
  { key: 'Switzerland', label: 'Schweiz', countryCode: 'CH' },
  { key: 'Afghanistan', label: 'Afghanistan', countryCode: 'AF' },
  { key: 'Albania', label: 'Albanien', countryCode: 'AL' },
  { key: 'Algeria', label: 'Algerien', countryCode: 'DZ' },
  { key: 'Andorra', label: 'Andorra', countryCode: 'AD' },
  { key: 'Angola', label: 'Angola', countryCode: 'AO' },
  { key: 'Antigua and Barbuda', label: 'Antigua und Barbuda', countryCode: 'AG' },
  { key: 'Argentina', label: 'Argentinien', countryCode: 'AR' },
  { key: 'Armenia', label: 'Armenien', countryCode: 'AM' },
  { key: 'Azerbaijan', label: 'Aserbaidschan', countryCode: 'AZ' },
  { key: 'Australia', label: 'Australien', countryCode: 'AU' },
  { key: 'Egypt', label: 'Ägypten', countryCode: 'EG' },
  { key: 'Ethiopia', label: 'Äthiopien', countryCode: 'ET' },
  { key: 'Bahamas', label: 'Bahamas', countryCode: 'BS' },
  { key: 'Bangladesh', label: 'Bangladesch', countryCode: 'BD' },
  { key: 'Barbados', label: 'Barbados', countryCode: 'BB' },
  { key: 'Belarus', label: 'Belarus (Weißrussland)', countryCode: 'BY' },
  { key: 'Belgium', label: 'Belgien', countryCode: 'BE' },
  { key: 'Belize', label: 'Belize', countryCode: 'BZ' },
  { key: 'Benin', label: 'Benin', countryCode: 'BJ' },
  { key: 'Bhutan', label: 'Bhutan', countryCode: 'BT' },
  { key: 'Bolivia', label: 'Bolivien', countryCode: 'BO' },
  { key: 'Bosnia and Herzegovina', label: 'Bosnien und Herzegowina', countryCode: 'BA' },
  { key: 'Botswana', label: 'Botsuana', countryCode: 'BW' },
  { key: 'Brazil', label: 'Brasilien', countryCode: 'BR' },
  { key: 'Brunei Darussalam', label: 'Brunei Darussalam', countryCode: 'BN' },
  { key: 'Bulgaria', label: 'Bulgarien', countryCode: 'BG' },
  { key: 'Burkina Faso', label: 'Burkina Faso', countryCode: 'BF' },
  { key: 'Burundi', label: 'Burundi', countryCode: 'BI' },
  { key: 'Chile', label: 'Chile', countryCode: 'CL' },
  { key: 'China', label: 'China', countryCode: 'CN' },
  { key: 'Costa Rica', label: 'Costa Rica', countryCode: 'CR' },
  { key: 'Dominica', label: 'Dominica', countryCode: 'DM' },
  { key: 'Dominican Republic', label: 'Dominikanische Republik', countryCode: 'DO' },
  { key: 'Djibouti', label: 'Dschibuti', countryCode: 'DJ' },
  { key: 'Denmark', label: 'Dänemark', countryCode: 'DK' },
  { key: 'Ecuador', label: 'Ecuador', countryCode: 'EC' },
  { key: 'El Salvador', label: 'El Salvador', countryCode: 'SV' },
  { key: "Cote d'Ivoire", label: 'Elfenbeinküste', countryCode: 'CI' },
  { key: 'Eritrea', label: 'Eritrea', countryCode: 'ER' },
  { key: 'Estonia', label: 'Estland', countryCode: 'EE' },
  { key: 'Eswatini', label: 'Eswatini', countryCode: 'SZ' },
  { key: 'Fiji', label: 'Fidschi', countryCode: 'FJ' },
  { key: 'Finland', label: 'Finnland', countryCode: 'FI' },
  { key: 'France', label: 'Frankreich', countryCode: 'FR' },
  { key: 'Gabon', label: 'Gabun', countryCode: 'GA' },
  { key: 'Gambia', label: 'Gambia', countryCode: 'GM' },
  { key: 'Georgia', label: 'Georgien', countryCode: 'GE' },
  { key: 'Ghana', label: 'Ghana', countryCode: 'GH' },
  { key: 'Grenada', label: 'Grenada', countryCode: 'GD' },
  { key: 'Greece', label: 'Griechenland', countryCode: 'GR' },
  { key: 'Great Britain', label: 'Großbritannien', countryCode: 'GB' },
  { key: 'Guatemala', label: 'Guatemala', countryCode: 'GT' },
  { key: 'Guinea', label: 'Guinea', countryCode: 'GN' },
  { key: 'Guyana', label: 'Guyana', countryCode: 'GY' },
  { key: 'Haiti', label: 'Haiti', countryCode: 'HT' },
  { key: 'Honduras', label: 'Honduras', countryCode: 'HN' },
  { key: 'India', label: 'Indien', countryCode: 'IN' },
  { key: 'Indonesia', label: 'Indonesien', countryCode: 'ID' },
  { key: 'Iraq', label: 'Irak', countryCode: 'IQ' },
  { key: 'Iran', label: 'Iran', countryCode: 'IR' },
  { key: 'Ireland', label: 'Irland', countryCode: 'IE' },
  { key: 'Iceland', label: 'Island', countryCode: 'IS' },
  { key: 'Israel', label: 'Israel', countryCode: 'IL' },
  { key: 'Italy', label: 'Italien', countryCode: 'IT' },
  { key: 'Jamaica', label: 'Jamaika', countryCode: 'JM' },
  { key: 'Japan', label: 'Japan', countryCode: 'JP' },
  { key: 'Yemen', label: 'Jemen', countryCode: 'YE' },
  { key: 'Jordan', label: 'Jordanien', countryCode: 'JO' },
  { key: 'Cambodia', label: 'Kambodscha', countryCode: 'KH' },
  { key: 'Cameroon', label: 'Kamerun', countryCode: 'CM' },
  { key: 'Canada', label: 'Kanada', countryCode: 'CA' },
  { key: 'Cabo Verde', label: 'Kap Verde', countryCode: 'CV' },
  { key: 'Kazakhstan', label: 'Kasachstan', countryCode: 'KZ' },
  { key: 'Qatar', label: 'Katar', countryCode: 'QA' },
  { key: 'Kenya', label: 'Kenia', countryCode: 'KE' },
  { key: 'Kyrgyzstan', label: 'Kirgisistan', countryCode: 'KG' },
  { key: 'Kiribati', label: 'Kiribati', countryCode: 'KI' },
  { key: 'Colombia', label: 'Kolumbien', countryCode: 'CO' },
  { key: 'Comoros', label: 'Komoren', countryCode: 'KM' },
  { key: 'Congo', label: 'Kongo', countryCode: 'CG' },
  { key: 'Korea', label: 'Korea', countryCode: 'KR' },
  { key: 'Kosovo', label: 'Kosovo', countryCode: 'XK' },
  { key: 'Croatia', label: 'Kroatien', countryCode: 'HR' },
  { key: 'Cuba', label: 'Kuba', countryCode: 'CU' },
  { key: 'Kuwait', label: 'Kuwait', countryCode: 'KW' },
  { key: 'Laos', label: 'Laos', countryCode: 'LA' },
  { key: 'Lesotho', label: 'Lesotho', countryCode: 'LS' },
  { key: 'Latvia', label: 'Lettland', countryCode: 'LV' },
  { key: 'Lebanon', label: 'Libanon', countryCode: 'LB' },
  { key: 'Liberia', label: 'Liberien', countryCode: 'LR' },
  { key: 'Liechtenstein', label: 'Liechtenstein', countryCode: 'LI' },
  { key: 'Lithuania', label: 'Litauen', countryCode: 'LT' },
  { key: 'Luxembourg', label: 'Luxemburg', countryCode: 'LU' },
  { key: 'Madagascar', label: 'Madagaskar', countryCode: 'MG' },
  { key: 'Malawi', label: 'Malawi', countryCode: 'MW' },
  { key: 'Malaysia', label: 'Malaysia', countryCode: 'MY' },
  { key: 'Maldives', label: 'Malediven', countryCode: 'MV' },
  { key: 'Mali', label: 'Mali', countryCode: 'ML' },
  { key: 'Malta', label: 'Malta', countryCode: 'MT' },
  { key: 'Morocco', label: 'Marokko', countryCode: 'MA' },
  { key: 'Mauritania', label: 'Mauretanien', countryCode: 'MR' },
  { key: 'Mauritius', label: 'Mauritius', countryCode: 'MU' },
  { key: 'Mexico', label: 'Mexiko', countryCode: 'MX' },
  { key: 'Micronesia', label: 'Mikronesien', countryCode: 'FM' },
  { key: 'Moldova', label: 'Moldau', countryCode: 'MD' },
  { key: 'Monaco', label: 'Monaco', countryCode: 'MC' },
  { key: 'Mongolia', label: 'Mongolei', countryCode: 'MN' },
  { key: 'Montenegro', label: 'Montenegro', countryCode: 'ME' },
  { key: 'Mozambique', label: 'Mosambik', countryCode: 'MZ' },
  { key: 'Myanmar', label: 'Myanmar', countryCode: 'MM' },
  { key: 'Namibia', label: 'Namibia', countryCode: 'NA' },
  { key: 'Nauru', label: 'Nauru', countryCode: 'NR' },
  { key: 'Nepal', label: 'Nepal', countryCode: 'NP' },
  { key: 'New Zealand', label: 'Neuseeland', countryCode: 'NZ' },
  { key: 'Nicaragua', label: 'Nicaragua', countryCode: 'NI' },
  { key: 'Netherlands', label: 'Niederlande', countryCode: 'NL' },
  { key: 'Nigeria', label: 'Nigeria', countryCode: 'NG' },
  { key: 'North Macedonia', label: 'Nordmazedonien', countryCode: 'MK' },
  { key: 'Norway', label: 'Norwegen', countryCode: 'NO' },
  { key: 'Oman', label: 'Oman', countryCode: 'OM' },
  { key: 'Pakistan', label: 'Pakistan', countryCode: 'PK' },
  { key: 'Palau', label: 'Palau', countryCode: 'PW' },
  { key: 'Panama', label: 'Panama', countryCode: 'PA' },
  { key: 'Papua New Guinea', label: 'Papua Neuguinea', countryCode: 'PG' },
  { key: 'Paraguay', label: 'Paraguay', countryCode: 'PY' },
  { key: 'Peru', label: 'Peru', countryCode: 'PE' },
  { key: 'Philippines', label: 'Philippinen', countryCode: 'PH' },
  { key: 'Poland', label: 'Polen', countryCode: 'PL' },
  { key: 'Portugal', label: 'Portugal', countryCode: 'PT' },
  { key: 'Rwanda', label: 'Ruanda', countryCode: 'RW' },
  { key: 'Romania', label: 'Rumänien', countryCode: 'RO' },
  { key: 'Russian Federation', label: 'Russische Föderation', countryCode: 'RU' },
  { key: 'Zambia', label: 'Sambia', countryCode: 'ZM' },
  { key: 'San Marino', label: 'San Marino', countryCode: 'SM' },
  { key: 'Saudi Arabia', label: 'Saudi-Arabien', countryCode: 'SA' },
  { key: 'Sweden', label: 'Schweden', countryCode: 'SE' },
  { key: 'Senegal', label: 'Senegal', countryCode: 'SN' },
  { key: 'Serbia', label: 'Serbien', countryCode: 'RS' },
  { key: 'Seychelles', label: 'Seychellen', countryCode: 'SC' },
  { key: 'Sierra Leone', label: 'Sierra Leone', countryCode: 'SL' },
  { key: 'Zimbabwe', label: 'Simbabwe', countryCode: 'ZW' },
  { key: 'Singapore', label: 'Singapur', countryCode: 'SG' },
  { key: 'Slovakia', label: 'Slowakei', countryCode: 'SK' },
  { key: 'Slovenia', label: 'Slowenien', countryCode: 'SI' },
  { key: 'Somalia', label: 'Somalia', countryCode: 'SO' },
  { key: 'Spain', label: 'Spanien', countryCode: 'ES' },
  { key: 'Sri Lanka', label: 'Sri Lanka', countryCode: 'LK' },
  { key: 'Sudan', label: 'Sudan', countryCode: 'SD' },
  { key: 'Syria', label: 'Syrien', countryCode: 'SY' },
  { key: 'South Africa', label: 'Südafrika', countryCode: 'ZA' },
  { key: 'South Sudan', label: 'Südsudan', countryCode: 'SS' },
  { key: 'Tajikistan', label: 'Tadschikistan', countryCode: 'TJ' },
  { key: 'Taiwan', label: 'Taiwan', countryCode: 'TW' },
  { key: 'Tanzania', label: 'Tansania', countryCode: 'TZ' },
  { key: 'Thailand', label: 'Thailand', countryCode: 'TH' },
  { key: 'Togo', label: 'Togo', countryCode: 'TG' },
  { key: 'Tonga', label: 'Tonga', countryCode: 'TO' },
  { key: 'Trinidad and Tobago', label: 'Trinidad und Tobago', countryCode: 'TT' },
  { key: 'Chad', label: 'Tschad', countryCode: 'TD' },
  { key: 'Czech Republic', label: 'Tschechien', countryCode: 'CZ' },
  { key: 'Tunisia', label: 'Tunesien', countryCode: 'TN' },
  { key: 'Turkmenistan', label: 'Turkmenistan', countryCode: 'TM' },
  { key: 'Tuvalu', label: 'Tuvalu', countryCode: 'TV' },
  { key: 'Turkey', label: 'Türkei', countryCode: 'TR' },
  { key: 'Uganda', label: 'Uganda', countryCode: 'UG' },
  { key: 'Ukraine', label: 'Ukraine', countryCode: 'UA' },
  { key: 'Hungary', label: 'Ungarn', countryCode: 'HU' },
  { key: 'Uruguay', label: 'Uruguay', countryCode: 'UY' },
  { key: 'United States', label: 'USA - Vereinigte Staaten', countryCode: 'US' },
  { key: 'Uzbekistan', label: 'Usbekistan', countryCode: 'UZ' },
  { key: 'Vanuatu', label: 'Vanuatu', countryCode: 'VU' },
  { key: 'Vatican city', label: 'Vatikan', countryCode: 'VA' },
  { key: 'Venezuela', label: 'Venezuela', countryCode: 'VE' },
  { key: 'United Arab Emirates', label: 'Vereinigte Arabische Emirate', countryCode: 'AE' },
  { key: 'Vietnam', label: 'Vietnam', countryCode: 'VN' },
  { key: 'Central African Republic', label: 'Zentralafrikanische Republik', countryCode: 'CF' },
  { key: 'Cyprus', label: 'Zypern', countryCode: 'CY' }
])

export default countries
