import _ from 'lodash'
import api from '../app/api'
import React from 'react'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { useNavigate, Navigate } from 'react-router-dom'

const Users = () => {
  const { data: me } = api.useGetMeQuery()
  const { data: users, isLoading } = api.useGetUsersQuery()
  const navigate = useNavigate()

  return me.role !== 'admin'
    ? <Navigate to='/' replace/>
    : isLoading
      ? null
      : <Box sx={{ marginBottom: '50px'}}>
        <Typography variant='h3'>
          Benutzer
        </Typography>

        <Box sx={{ display: 'flex' }}>
          <Button
            disabled={me.maxUsers - users.length <= 0}
            onClick={() => navigate('/benutzer/neu')}
            sx={{ marginLeft: 'auto' }}
            variant='contained'
          >
            Neuer Benutzer
          </Button>
        </Box>

        <Box sx={{ marginTop: '10px' }}>
          <Typography>
            Lizenzen: {users.length} von {me.maxUsers} vergeben.
            <span style={{ fontSize: '0.8em' }}> (Wenden Sie sich über die Hilfe-Funktion an unser Service-Team, wenn Sie mehr Lizenzen wünschen.)</span>
          </Typography>
        </Box>

        {_.map(users, user => (
          <Card key={user.username} sx={{ margin: '30px 0' }} onClick={() => navigate('/benutzer/bearbeiten', { state: { username: user.username } })}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Benutzername</Typography>
                  <Typography>{user.username}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Rolle</Typography>
                  <Typography>{user.role === 'admin' ? 'Administrator' : (user.role === 'manager' ? 'Manager' : 'Benutzer')}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>E-Mail</Typography>
                  <Typography>{user.email}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={2}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Vorname</Typography>
                  <Typography>{user.firstName}</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant='body2' sx={{ opacity: '50%' }}>Nachname</Typography>
                  <Typography>{user.lastName}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Box>
}

export default Users
