import AppRestUrls from '../app/constants/AppRestUrls'
import { useEffect } from 'react'

const Login = () => {
  useEffect(() => {
    localStorage.clear()

    window.open(AppRestUrls.login(), '_self')
  })

  return null
}

export default Login
