import _ from 'lodash'
import bankDataGermany from '../constants/bankDataGermany'
import IBAN from 'iban'

const checkAndGetIbanInfo = iban => {
  if (iban === '') return { valid : true }

  const valid = IBAN.isValid(iban)
  if (!valid) return { valid: false }

  const countryCode = iban.replace(/[^0-9A-Z]+/g, "").substring(0, 2)
  if (countryCode !== 'DE') return { valid: true }

  const bankIdentifier = iban.replace(/[^0-9A-Z]+/g, "").substring(4, 12)

  return { valid: true, ..._.find(bankDataGermany, data => data.Bankleitzahl === bankIdentifier) }
}

export default checkAndGetIbanInfo
