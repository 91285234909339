const BASE = process.env.REACT_APP_REST_BASE_URL
const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL

const base = () => BASE

const login = () => BASE + '/login'
const logout = () => BASE + '/logout'

const support = () => SUPPORT_URL
const supportForApp = () => support() + '?app=imoto'

const AppRestUrls = {
  base,

  login,
  logout,

  supportForApp
}

export default AppRestUrls
