const getCurrencyString = (value, currency) => {
  if (!value) return ''

  switch (currency) {
    case 'USD':
      return parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    case 'GBP':
      return parseFloat(value).toLocaleString('en-GB', {style: 'currency', currency: 'GBP'})
    case 'CHF':
      return parseFloat(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF'})
    default:
      return parseFloat(value).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})
  }
}

export default getCurrencyString
