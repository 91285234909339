import _ from 'lodash'
import getCurrentPath from '../app/utils/getCurrentPath'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { Folder, FolderSpecial } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

const SettingsLocationsForm = ({ settings, folders }) => {
  const [putSettings, { isLoading: isUpdating }] = pouchApi.usePutSettingsMutation()
  const [showFolderSelectionDialog, setShowFolderSelectionDialog] = useState(null)
  const [folderSectionCurrentFolderId, setFolderSectionCurrentFolderId] = useState(null)

  const [handoverProtocolFolder, setHandoverProtocolFolder] = useState(!!settings ? settings.handoverProtocolFolder : null)
  const [returnProtocolFolder, setReturnProtocolFolder] = useState(!!settings ? settings.returnProtocolFolder : null)
  const [landlordConfirmationFolder, setLandlordConfirmationFolder] = useState(!!settings ? settings.landlordConfirmationFolder : null)

  return(
    <form
      onSubmit={async e => {
        e.preventDefault()

        const settingsUpdate = {
          ...settings,
          _id: 'settings',
          handoverProtocolFolder,
          returnProtocolFolder,
          landlordConfirmationFolder
        }

        await putSettings(settingsUpdate)
      }}
    >
      <Card sx={{ margin: '30px 0' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Speicherorte
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ display: 'inline', marginRight: '10px' }}>
                Ordner für Wohnungsübergabe-Protokolle:
              </Typography>

              <Button
                startIcon={<Folder/>}
                onClick={() => {
                  setFolderSectionCurrentFolderId(handoverProtocolFolder)
                  setShowFolderSelectionDialog("handoverProtocolFolder")
                }}
              >
                {_.map(getCurrentPath(handoverProtocolFolder, folders), folderId => (
                  folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'
                )).reduce((prev, curr) => [prev, ' > ', curr])}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ display: 'inline', marginRight: '10px' }}>
                Ordner für Wohnungsrückgabe-Protokolle:
              </Typography>

              <Button
                startIcon={<Folder/>}
                onClick={() => {
                  setFolderSectionCurrentFolderId(returnProtocolFolder)
                  setShowFolderSelectionDialog("returnProtocolFolder")
                }}
              >
                {_.map(getCurrentPath(returnProtocolFolder, folders), folderId => (
                  folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'
                )).reduce((prev, curr) => [prev, ' > ', curr])}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ display: 'inline', marginRight: '10px' }}>
                Ordner für Wohnungsgeberbestätigungen:
              </Typography>

              <Button
                startIcon={<Folder/>}
                onClick={() => {
                  setFolderSectionCurrentFolderId(landlordConfirmationFolder)
                  setShowFolderSelectionDialog("landlordConfirmationFolder")
                }}
              >
                {_.map(getCurrentPath(landlordConfirmationFolder, folders), folderId => (
                  folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'
                )).reduce((prev, curr) => [prev, ' > ', curr])}
              </Button>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <LoadingButton loading={isUpdating} type='submit' variant='contained' sx={{ marginLeft: 'auto' }}>
            Speichern
          </LoadingButton>
        </CardActions>
      </Card>

      <Dialog
        close={() => {
          setShowFolderSelectionDialog(null)
          setFolderSectionCurrentFolderId(null)
        }}
        maxWidth='lg'
        open={!!showFolderSelectionDialog}
        title={"Ordnerauswahl für " +
          (showFolderSelectionDialog === "handoverProtocolFolder"
            ? "Wohnungsübergabe-Protokolle"
            : showFolderSelectionDialog === "returnProtocolFolder"
              ? "Wohnungsrückgabe-Protokolle"
              : showFolderSelectionDialog === "landlordConfirmationFolder"
                ? "Wohnungsgeberbestätigungen"
                : ""
          )
        }
        text={<Box sx={{ paddingTop: '20px', minWidth: { sm: '500px', md: '700px', lg: '1000px' } }}>
          <Typography>
            Pfad: {_.map(getCurrentPath(folderSectionCurrentFolderId, folders), folderId => (
              <span
                key={folderId}
                onClick={() => setFolderSectionCurrentFolderId(folderId)}
                style={{ cursor: 'pointer'}}
              >
                <strong>{folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'}</strong>
              </span>
            )).reduce((prev, curr) => [prev, ' > ', curr])}
          </Typography>

          <List>
            {_.map(_.sortBy(_.filter(folders, x => x.parent === folderSectionCurrentFolderId), 'name'), folder => (
              <ListItem
                key={folder._id}
              >
                <ListItemButton onClick={() => setFolderSectionCurrentFolderId(folder._id)}>
                  <ListItemIcon>{folder.access === 'Beschränkt' ? <FolderSpecial/> : <Folder/>}</ListItemIcon>
                  <ListItemText primary={folder.name} sx={{ overflowWrap: 'break-word', hyphens: 'auto' }}/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>}
        no={() => {
          setShowFolderSelectionDialog(null)
          setFolderSectionCurrentFolderId(null)
        }}
        noText="Zurück"
        yes={() => {
          if (showFolderSelectionDialog === "handoverProtocolFolder") setHandoverProtocolFolder(folderSectionCurrentFolderId)
          else if (showFolderSelectionDialog === "returnProtocolFolder") setReturnProtocolFolder(folderSectionCurrentFolderId)
          else if (showFolderSelectionDialog === "landlordConfirmationFolder") setLandlordConfirmationFolder(folderSectionCurrentFolderId)

          setShowFolderSelectionDialog(null)
          setFolderSectionCurrentFolderId(null)
        }}
        yesText="Ordner auswählen"
      />
    </form>
  )
}

export default SettingsLocationsForm
