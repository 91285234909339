import _ from 'lodash'
import ErrorDialog from '../app/components/ErrorDialog'
import MeterDetailsForm from './MeterDetailsForm'
import moment from 'moment'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { AddSharp } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { v4 as uuid } from 'uuid'

const RealEstateMeterReadingBox = ({ realEstate, endEdit }) => {
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()
  const [errors, setErrors] = useState([])

  const [meters, setMeters] = useState(realEstate.meters)

  return (
    <form
      onSubmit={async e => {
        e.preventDefault()

        if (_.some(meters, x => _.some(x.values, v => moment(v.date).get('year') < 1900 || Number.isNaN(moment(v.date).get('year'))))) {
          setErrors(['Bitte überprüfen Sie Ihre eingegeben Daten. Ein Datum ist nicht korrekt.'])
          return
        }

        for (const meter of meters) {
          if (!meter.new) {
            //only currentValue editable
            const currentValue = _.last(_.sortBy(_.find(realEstate.meters, m => m.id === meter.id).values, x => moment(x.date)))
            const beforeCurrentValue = _.last(_.initial(_.sortBy(_.find(realEstate.meters, m => m.id === meter.id).values, x => moment(x.date))))

            if (!!beforeCurrentValue) {
              const newCurrentValue = _.find(meter.values, m => m.id === currentValue.id)

              if (moment(beforeCurrentValue.date).isAfter(moment(newCurrentValue.date))) {
                setErrors(['Es gibt bereits einen Zählerstand mit aktuellerem Datum. Bitte geben Sie ein Datum ein, das später ist.'])
                return
              }
              if (beforeCurrentValue.value >= newCurrentValue.value) {
                setErrors(['Es gibt bereits einen Zählerstand mit höherem Wert. Bitte geben Sie einen Zählerstand ein, der größer ist.'])
                return
              }
            }
          }
        }

        const res = await putRealEstate({
          ...realEstate,
          meters: _.map(meters, x => _.omit(x, 'new'))
        })

        if (!res.error) endEdit()
      }}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Wasser</Typography>
              </Grid>

              {_.map(_.filter(meters, x => x.type === 'water'), meter => {
                const currentValueId = meter.new
                  ? meter.values[0].id
                  : _.last(_.sortBy(_.find(realEstate.meters, m => m.id === meter.id).values, x => moment(x.date))).id

                return (
                  <MeterDetailsForm
                    key={meter.id}
                    meter={meter}
                    currentValueId={currentValueId}
                    onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                    setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                  />
                )
              })}

              <IconButton onClick={() => setMeters([...meters, { id: uuid(), new: true, type: 'water', name: '', number: '', unit: 'm³', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ marginTop: '10px' }}>
                <AddSharp />
              </IconButton>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Strom</Typography>
              </Grid>

              {_.map(_.filter(meters, x => x.type === 'electricity'), meter => {
                const currentValueId = meter.new
                  ? meter.values[0].id
                  : _.last(_.sortBy(_.find(realEstate.meters, m => m.id === meter.id).values, x => moment(x.date))).id

                return (
                  <MeterDetailsForm
                    key={meter.id}
                    meter={meter}
                    currentValueId={currentValueId}
                    onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                    setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                  />
                )
              })}

              <IconButton onClick={() => setMeters([...meters, { id: uuid(), new: true, type: 'electricity', name: '', number: '', unit: 'kWh', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ marginTop: '10px' }}>
                <AddSharp />
              </IconButton>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Heizung</Typography>
              </Grid>

              {_.map(_.filter(meters, x => x.type === 'radiator'), meter => {
                const currentValueId = meter.new
                  ? meter.values[0].id
                  : _.last(_.sortBy(_.find(realEstate.meters, m => m.id === meter.id).values, x => moment(x.date))).id

                return (
                  <MeterDetailsForm
                    key={meter.id}
                    meter={meter}
                    currentValueId={currentValueId}
                    onRemoveMeter={() => setMeters(_.filter(meters, x => x.id !== meter.id))}
                    setMeter={meter => setMeters(_.map(meters, x => x.id === meter.id ? meter : x))}
                  />
                )
              })}

              <IconButton onClick={() => setMeters([...meters, { id: uuid(), new: true, type: 'radiator', name: '', number: '', unit: 'kWh', values: [{ date: moment(), value: 0, id: uuid() }] }])} style={{ marginTop: '10px' }}>
                <AddSharp />
              </IconButton>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            Abbrechen
          </Button>

          <LoadingButton loading={isLoading} variant='outlined' type='submit'>
            Speichern
          </LoadingButton>
        </Box>
      </Box>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />
    </form>
  )
}

export default RealEstateMeterReadingBox
