import _ from 'lodash'
import api from '../app/api'
import getCurrentPath from '../app/utils/getCurrentPath'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import SettingsLocationsForm from '../settings/SettingsLocationsForm'
import { AddSharp, Close, Delete, Edit, Folder, FolderSpecial, Save } from '@mui/icons-material'
import { Button, Box, Card, CardContent, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { LoadingButton } from '@mui/lab'
import { Navigate } from 'react-router-dom'

const Settings = () => {
  const { data: me } = api.useGetMeQuery()
  const { data: folders, isLoading } = pouchApi.useGetFoldersQuery()
  const { data: realEstates, isLoading: isLoadingRealEstates } = pouchApi.useGetRealEstatesQuery()
  const { data: settings, isLoading: isLoadingSettings } = pouchApi.useGetSettingsQuery()

  const [postFolder, { isLoading: isCreating }] = pouchApi.usePostFolderMutation()
  const [putFolder, { isLoading: isUpdating }] = pouchApi.usePutFolderMutation()
  const [removeFolders] = pouchApi.useRemoveFoldersMutation()
  const [postFolders, { isLoading: isUpdatingAll }] = pouchApi.usePostFoldersMutation()

  const [currentFolderId, setCurrentFolderId] = useState(null)
  const currentFolder = currentFolderId ? _.find(folders, x => x._id === currentFolderId) : null
  const [newFolder, setNewFolder] = useState(null)
  const [editFolder, setEditFolder] = useState(null)
  const [deleteFolder, setDeleteFolder] = useState(null)

  const [showSetFolders, setShowSetFolders] = useState(false)

  const getFolderWithAllSubfolders = (folder) => {
    const subfolders = _.filter(folders, x => x.parent === folder._id)

    return _.concat(folder, _.flatten(_.map(subfolders, x => getFolderWithAllSubfolders(x))))
  }

  const canDeleteFolder = (folderToDelete) => {
    const importantFolders = getFolderWithAllSubfolders(folderToDelete)

    return !_.some(
      importantFolders,
      folder => _.some(
        realEstates,
        realEstate => _.some(
          realEstate.files,
          file => (
            file.parent === folder._id || (!!settings && (
              settings.handoverProtocolFolder === folder._id ||
              settings.returnProtocolFolder === folder._id ||
              settings.landlordConfirmationFolder === folder._id
            ))
          )
        )
      )
    )
  }

  const defaultFolders = [
    { name: 'Dokumente Finanzamt', parent: null, access: 'Beschränkt' },
    { name: 'Energieausweis', parent: null, access: 'Unbeschränkt' },
    { name: 'Grundriss', parent: null, access: 'Unbeschränkt' },
    { name: 'Kaufvertrag', parent: null, access: 'Beschränkt' },
    { name: 'Kreditverträge', parent: null, access: 'Beschränkt' },
    { name: 'Mietverträge', parent: null, access: 'Unbeschränkt' },
    { name: 'Nebenkostenabrechnungen Hausverwalter', parent: null, access: 'Unbeschränkt' },
    { name: 'Nebenkostenabrechnungen Mieter', parent: null, access: 'Unbeschränkt' },
    { name: 'Protokolle Eigentümerversammlung', parent: null, access: 'Unbeschränkt' },
    { name: 'Schriftwechsel', parent: null, access: 'Unbeschränkt' },
    { name: 'Sonstige Protokolle', parent: null, access: 'Unbeschränkt' },
    { name: 'Teilungserklärung', parent: null, access: 'Unbeschränkt' },
    { name: 'Verträge mit Dienstleistern', parent: null, access: 'Unbeschränkt' },
    { name: 'Wohnungsgeber-Bestätigungen', parent: null, access: 'Unbeschränkt' },
    { name: 'Wohnungsrückgabe-Protokolle', parent: null, access: 'Unbeschränkt' },
    { name: 'Wohnungsübergabe-Protokolle', parent: null, access: 'Unbeschränkt' },
  ]

  return me.role !== 'admin'
    ? <Navigate to='/' replace/>
    : isLoading || isLoadingRealEstates || isLoadingSettings
      ? null
      : <Box sx={{ marginBottom: '50px'}}>
        <Typography variant='h3' sx={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
          Einstellungen
        </Typography>

        <Card sx={{ margin: '30px 0' }}>
          <CardContent>
            <Box>
              <Typography variant='h6'>
                Ordnerstruktur
              </Typography>

              <Typography variant='body2' sx={{ opacity: '50%' }}>
                Info: Die mit Stern markierten Ordner (und deren Unterordner) unterliegen beschränkter Einsicht je nach Benutzerrolle.
              </Typography>

              <Typography sx={{ marginTop: '20px' }}>
                aktueller Pfad: {_.map(getCurrentPath(currentFolderId, folders), folderId => (
                  <span
                    key={folderId}
                    onClick={() => setCurrentFolderId(folderId)}
                    style={{ cursor: 'pointer'}}
                  >
                    {folderId ? _.find(folders, x => x._id === folderId).name : 'Hauptverzeichnis'}
                  </span>
                )).reduce((prev, curr) => [prev, ' > ', curr])}
              </Typography>

              <List sx={{ maxWidth: '700px' }}>
                {_.map(_.sortBy(_.filter(folders, x => x.parent === currentFolderId), 'name'), folder => (
                  !!editFolder && editFolder._id === folder._id
                    ? <ListItem
                        key={folder._id}
                        secondaryAction={
                          <>
                            <IconButton
                              disabled={isUpdating}
                              edge='end'
                              onClick={async () => {
                                let error = false

                                const res1 = await putFolder(editFolder)
                                if (res1.error) error = true

                                if (editFolder.access === 'Beschränkt') {
                                  const subFolders = _.filter(getFolderWithAllSubfolders(editFolder), x => x._id !== editFolder._id)
                                  for (const folder of subFolders) {
                                    const res2 = await putFolder({ ...folder, access: 'Beschränkt' })
                                    if (res2.error) error = true
                                  }
                                }
                                if (!error) setEditFolder(null)
                              }}
                              sx={{ color: 'primary.main', marginRight: '2px' }}
                            >
                              <Save/>
                            </IconButton>

                            <IconButton
                              edge='end'
                              onClick={() => setEditFolder(null)}
                              sx={{ color: 'primary.main' }}
                            >
                              <Close/>
                            </IconButton>
                          </>
                        }
                        sx={{ '&.MuiListItem-root': { paddingRight: '90px', paddingLeft: '16px' } }}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs='auto'>
                            <FormControl disabled={!!currentFolder && currentFolder.access === 'Beschränkt'} required variant='standard' sx={{ minWidth: '130px' }}>
                              <InputLabel id='access-label'>Einsicht</InputLabel>
                              <Select
                                labelId='access-label'
                                value={editFolder.access}
                                label='Einsicht'
                                onChange={e => setEditFolder({ ...editFolder, access: e.target.value })}
                              >
                                <MenuItem value='Beschränkt'>Beschränkt</MenuItem>
                                <MenuItem value='Unbeschränkt'>Unbeschränkt</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={7} md={8} lg={9}>
                            <TextField
                              fullWidth
                              label='Name'
                              onChange={e => setEditFolder({ ...editFolder, name: e.target.value })}
                              required
                              value={editFolder.name}
                              variant='standard'
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    : <ListItem
                        key={folder._id}
                        secondaryAction={
                          <>
                            <IconButton
                              edge='end'
                              onClick={() => setEditFolder(folder)}
                              sx={{ color: 'primary.main', marginRight: '2px' }}
                            >
                              <Edit/>
                            </IconButton>

                            <Tooltip title={!canDeleteFolder(folder) ? 'Ordner, die Dokumente enthalten oder als Speicherort angegeben sind, können nicht gelöscht werden' : ''} placement='top'>
                              <span>
                                <IconButton
                                  disabled={!canDeleteFolder(folder)}
                                  edge='end'
                                  onClick={() => setDeleteFolder(folder)}
                                  sx={{ color: 'primary.main' }}
                                >
                                  <Delete/>
                                </IconButton>
                              </span>
                            </Tooltip>
                          </>
                        }
                        sx={{ '&.MuiListItem-root': { paddingRight: '90px', '.MuiListItemButton-root': { paddingRight: '10px' } } }}
                        disablePadding
                      >
                        <ListItemButton onClick={() => setCurrentFolderId(folder._id)}>
                          <ListItemIcon>{folder.access === 'Beschränkt' ? <FolderSpecial/> : <Folder/>}</ListItemIcon>
                          <ListItemText primary={folder.name} sx={{ overflowWrap: 'break-word', hyphens: 'auto' }}/>
                        </ListItemButton>
                      </ListItem>
                ))}
              </List>

              {newFolder
                ? (
                  <form
                    onSubmit={async e => {
                      e.preventDefault()

                      const res = await postFolder(newFolder)
                      if (!res.error) setNewFolder(null)
                    }}
                    style={{ margin: '0 16px' }}
                  >
                    <Grid container spacing={3} alignItems='center'>
                      <Grid item xs='auto'>
                        <FormControl disabled={!!currentFolder && currentFolder.access === 'Beschränkt'} required variant='standard' sx={{ minWidth: '130px' }}>
                          <InputLabel id='access-label'>Einsicht</InputLabel>
                          <Select
                            labelId='access-label'
                            value={newFolder.access}
                            label='Einsicht'
                            onChange={e => setNewFolder({ ...newFolder, access: e.target.value })}
                          >
                            <MenuItem value='Beschränkt'>Beschränkt</MenuItem>
                            <MenuItem value='Unbeschränkt'>Unbeschränkt</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={8} md={5} lg={4}>
                        <TextField
                          fullWidth
                          label='Name'
                          onChange={e => setNewFolder({ ...newFolder, name: e.target.value })}
                          required
                          value={newFolder.name}
                          variant='standard'
                        />
                      </Grid>

                      <Grid item xs='auto'>
                        <LoadingButton variant='outlined' type='submit' loading={isCreating}>Speichern</LoadingButton>
                      </Grid>
                      <Grid item xs='auto'>
                        <IconButton sx={{ color: 'primary.main' }} onClick={() => setNewFolder(null)}><Close/></IconButton>
                      </Grid>
                    </Grid>
                  </form>
                )
                : (
                  <Button
                    onClick={() => setNewFolder({ name: '', parent: currentFolderId, access: !!currentFolder ? currentFolder.access : 'Unbeschränkt' })}
                    startIcon={<AddSharp/>}
                    variant='outlined'
                  >
                    Neuer Ordner
                  </Button>
                )
              }

              {folders.length === 0
                ? <Box>
                    <LoadingButton
                      loading={isUpdatingAll}
                      onClick={() => setShowSetFolders(true)}
                      sx={{ float: 'right', margin: '15px 0' }}
                      variant='outlined'
                    >
                      Standard-Ordner anlegen
                    </LoadingButton>
                  </Box>
                : null
              }
            </Box>
          </CardContent>
        </Card>

        <SettingsLocationsForm
          settings={settings}
          folders={folders}
        />

        <Dialog
          close={() => setDeleteFolder(null)}
          no={() => setDeleteFolder(null)}
          noText='Zurück'
          open={!!deleteFolder}
          text='Sind Sie sicher, dass Sie diesen Ordner und alle Unterordner löschen möchten?'
          title={deleteFolder ? 'Ordner "' + deleteFolder.name + '" löschen' : ''}
          yes={async () => {
            const res = await removeFolders(getFolderWithAllSubfolders(deleteFolder))
            if (!res.error) setDeleteFolder(null)
          }}
          yesText='Löschen'
        />

        <Dialog
          close={() => setShowSetFolders(false)}
          ok={async () => {
            const res = await postFolders(defaultFolders)
            if (!res.error) setShowSetFolders(false)
          }}
          okText='Anlegen'
          open={showSetFolders}
          text={
            <Box>
              <Typography>Folgende Ordnerstruktur wird von uns vorgeschlagen:</Typography>
              <br/>
              <Typography variant='body2'>Anschließend können die Ordner auch bearbeitet, entfernt und mit neuen Ordnern erweitert werden.</Typography>

              <List sx={{ maxWidth: '450px' }}>
                {_.map(_.filter(defaultFolders, x => x.parent === null), folder => (
                  <Box key={folder.name}>
                    <ListItem>
                      <ListItemIcon>{folder.access === 'Beschränkt' ? <FolderSpecial/> : <Folder/>}</ListItemIcon>
                      <ListItemText sx={{ overflowWrap: 'break-word', hyphens: 'auto' }} primary={folder.name}/>
                    </ListItem>
                  </Box>
                ))}
              </List>
            </Box>
          }
          title='Standard-Ordner anlegen'
        />
      </Box>
}

export default Settings
