import _ from 'lodash'
import checkAndGetIbanInfo from '../app/utils/checkAndGetIbanInfo'
import checkAndParsePhoneNumber from '../app/utils/checkAndParsePhoneNumber'
import countries from '../app/constants/countries'
import ErrorDialog from '../app/components/ErrorDialog'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import { AddSharp, Delete } from '@mui/icons-material'
import { Autocomplete, Button, Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { Dialog } from 'common_components'
import { LoadingButton } from '@mui/lab'
import { v4 as uuid } from 'uuid'

const RealEstateAdministrationForm = ({ realEstate, endEdit }) => {
  const [putRealEstate, { isLoading }] = pouchApi.usePutRealEstateMutation()
  const formId = "real-estate-administration-form"
  const [errors, setErrors] = useState([])
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const [updateConfirmed, setUpdateConfirmed] = useState(false)

  const [ownerCompany, setOwnerCompany] = useState(realEstate.ownerCompany)
  const [ownerSalutation, setOwnerSalutation] = useState(realEstate.ownerSalutation)
  const [ownerFirstName, setOwnerFirstName] = useState(realEstate.ownerFirstName)
  const [ownerLastName, setOwnerLastName] = useState(realEstate.ownerLastName)
  const [ownerStreet, setOwnerStreet] = useState(realEstate.ownerStreet)
  const [ownerZip, setOwnerZip] = useState(realEstate.ownerZip)
  const [ownerCity, setOwnerCity] = useState(realEstate.ownerCity)
  const [ownerCountry, setOwnerCountry] = useState(realEstate.ownerCountry)
  const [ownerPhone, setOwnerPhone] = useState(realEstate.ownerPhone)
  const [ownerPhoneMobile, setOwnerPhoneMobile] = useState(realEstate.ownerPhoneMobile)
  const [ownerEmail, setOwnerEmail] = useState(realEstate.ownerEmail)
  const [ownerLegalRepresentative, setOwnerLegalRepresentative] = useState(realEstate.ownerLegalRepresentative)

  const [ownerIsLandlord, setOwnerIsLandlord] = useState(realEstate.ownerIsLandlord)
  const [landlordCompany, setLandlordCompany] = useState(realEstate.landlordCompany)
  const [landlordSalutation, setLandlordSalutation] = useState(realEstate.landlordSalutation)
  const [landlordFirstName, setLandlordFirstName] = useState(realEstate.landlordFirstName)
  const [landlordLastName, setLandlordLastName] = useState(realEstate.landlordLastName)
  const [landlordStreet, setLandlordStreet] = useState(realEstate.landlordStreet)
  const [landlordZip, setLandlordZip] = useState(realEstate.landlordZip)
  const [landlordCity, setLandlordCity] = useState(realEstate.landlordCity)
  const [landlordCountry, setLandlordCountry] = useState(realEstate.landlordCountry)
  const [landlordPhone, setLandlordPhone] = useState(realEstate.landlordPhone)
  const [landlordPhoneMobile, setLandlordPhoneMobile] = useState(realEstate.landlordPhoneMobile)
  const [landlordEmail, setLandlordEmail] = useState(realEstate.landlordEmail)
  const [landlordLegalRepresentative, setLandlordLegalRepresentative] = useState(realEstate.landlordLegalRepresentative)

  const [managementCompany, setManagementCompany] = useState(realEstate.managementCompany)
  const [managementStreet, setManagementStreet] = useState(realEstate.managementStreet)
  const [managementZip, setManagementZip] = useState(realEstate.managementZip)
  const [managementCity, setManagementCity] = useState(realEstate.managementCity)
  const [managementCountry, setManagementCountry] = useState(realEstate.managementCountry)
  const [managementPhone, setManagementPhone] = useState(realEstate.managementPhone)
  const [managementPhoneMobile, setManagementPhoneMobile] = useState(realEstate.managementPhoneMobile)
  const [managementEmail, setManagementEmail] = useState(realEstate.managementEmail)
  const [managementIban, setManagementIban] = useState(realEstate.managementIban)
  const [managementIbanValid, setManagementIbanValid] = useState(true)
  const [managementBankDataKnown, setManagementBankDataKnown] = useState(realEstate.managementBankDataKnown)
  const [managementBic, setManagementBic] = useState(realEstate.managementBic)
  const [managementBank, setManagementBank] = useState(realEstate.managementBank)
  const [managementContacts, setManagementContacts] = useState(realEstate.managementContacts)

  const deletedManagementContacts = _.filter(realEstate.managementContacts, m => !_.some(managementContacts, x => m.id === x.id))

  const onManagementIbanBlur = () => {
    const res = checkAndGetIbanInfo(managementIban)

    setManagementIbanValid(res.valid)

    if (!!res.BIC && !!res.Bezeichnung) {
      setManagementBic(res.BIC)
      setManagementBank(res.Bezeichnung)
      setManagementBankDataKnown(true)
    } else {
      setManagementBankDataKnown(false)
    }

    if (managementIban === '') {
      setManagementBic('')
      setManagementBank('')
    }
  }

  return(
    <form
      id={formId}
      onSubmit={async e => {
        e.preventDefault()

        if (deletedManagementContacts.length > 0 && !updateConfirmed) {
          setShowUpdateConfirmation(true)
          return
        }

        let ownerPhoneParsed = ownerPhone
        if (ownerPhone) {
          ownerPhoneParsed = checkAndParsePhoneNumber(ownerCountry.countryCode, ownerPhone)
        }
        let ownerPhoneMobileParsed = ownerPhoneMobile
        if (ownerPhoneMobile) {
          ownerPhoneMobileParsed = checkAndParsePhoneNumber(ownerCountry.countryCode, ownerPhoneMobile)
        }
        let landlordPhoneParsed = landlordPhone
        if (landlordPhone) {
          landlordPhoneParsed = checkAndParsePhoneNumber(landlordCountry.countryCode, landlordPhone)
        }
        let landlordPhoneMobileParsed = landlordPhoneMobile
        if (landlordPhoneMobile) {
          landlordPhoneMobileParsed = checkAndParsePhoneNumber(landlordCountry.countryCode, landlordPhoneMobile)
        }
        let managementPhoneParsed = managementPhone
        if (managementPhone) {
          managementPhoneParsed = checkAndParsePhoneNumber(managementCountry.countryCode, managementPhone)
        }
        let managementPhoneMobileParsed = managementPhoneMobile
        if (managementPhoneMobile) {
          managementPhoneMobileParsed = checkAndParsePhoneNumber(managementCountry.countryCode, managementPhoneMobile)
        }
        let managementContactsParsed = managementContacts
        let managementContactsError = false
        for (let contact of managementContacts) {
          if (contact.phone) {
            const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, contact.phone)
            if (parsedTemp === false) managementContactsError = true
            else managementContactsParsed = _.map(managementContactsParsed, x => x.id === contact.id ? { ...x, phone: parsedTemp } : x)
          }
          if (contact.phoneMobile) {
            const parsedTemp = checkAndParsePhoneNumber(managementCountry.countryCode, contact.phoneMobile)
            if (parsedTemp === false) managementContactsError = true
            else managementContactsParsed = _.map(managementContactsParsed, x => x.id === contact.id ? { ...x, phoneMobile: parsedTemp } : x)
          }
        }

        if (ownerPhoneParsed === false
          || ownerPhoneMobileParsed === false
          || landlordPhoneParsed === false
          || landlordPhoneMobileParsed === false
          || managementPhoneParsed === false
          || managementPhoneMobileParsed === false
          || managementContactsError
        ) {
          setErrors(['Eine Telefonnummer konnte nicht normalisiert werden, bitte überprüfen Sie Ihre Eingaben.'])
          return
        }

        if (!managementIbanValid) {
          setErrors(['Die IBAN ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe.'])
          return
        }

        const res = await putRealEstate({
          ...realEstate,

          ownerCompany,
          ownerSalutation,
          ownerFirstName,
          ownerLastName,
          ownerStreet,
          ownerZip,
          ownerCity,
          ownerCountry,
          ownerPhone: ownerPhoneParsed,
          ownerPhoneMobile: ownerPhoneMobileParsed,
          ownerEmail,
          ownerLegalRepresentative,

          ownerIsLandlord,
          landlordCompany,
          landlordSalutation,
          landlordFirstName,
          landlordLastName,
          landlordStreet,
          landlordZip,
          landlordCity,
          landlordCountry,
          landlordPhone: landlordPhoneParsed,
          landlordPhoneMobile: landlordPhoneMobileParsed,
          landlordEmail,
          landlordLegalRepresentative,

          managementCompany,
          managementStreet,
          managementZip,
          managementCity,
          managementCountry,
          managementPhone: managementPhoneParsed,
          managementPhoneMobile: managementPhoneMobileParsed,
          managementEmail,
          managementIban,
          managementBankDataKnown,
          managementBic,
          managementBank,
          managementContacts: managementContactsParsed
        })

        if (!res.error) endEdit()
      }}
    >
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Hausverwaltung</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Firma'
                  onChange={e => setManagementCompany(e.target.value)}
                  value={managementCompany}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Straße und Hausnummer'
                  onChange={e => setManagementStreet(e.target.value)}
                  value={managementStreet}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Postleitzahl'
                  onChange={e => setManagementZip(e.target.value)}
                  value={managementZip}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='Ort'
                  onChange={e => setManagementCity(e.target.value)}
                  value={managementCity}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  onChange={(e, country) => setManagementCountry(country)}
                  options={countries}
                  renderInput={(params) => <TextField
                    {...params}
                    label='Land'
                    required={!!managementPhone || !!managementPhoneMobile || (!!managementContacts && managementContacts.length > 0)}
                    variant='standard'
                    style={{ width: '100%' }}
                  />}
                  value={managementCountry}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Telefon'
                  onChange={e => setManagementPhone(e.target.value)}
                  value={managementPhone}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Telefon (mobil)'
                  onChange={e => setManagementPhoneMobile(e.target.value)}
                  value={managementPhoneMobile}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='E-Mail'
                  onChange={e => setManagementEmail(e.target.value)}
                  type='email'
                  value={managementEmail}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <TextField
                  error={!managementIbanValid}
                  fullWidth
                  helperText={managementIbanValid ? '' : 'Diese IBAN ist nicht gültig.'}
                  label='IBAN'
                  onBlur={onManagementIbanBlur}
                  onChange={e => setManagementIban(e.target.value)}
                  onKeyDown={e => e.key === 'Enter' ? onManagementIbanBlur() : null}
                  value={managementIban}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={4} lg={4}>
                <TextField
                  disabled={!managementIban || !managementIbanValid || managementBankDataKnown}
                  fullWidth
                  label='BIC'
                  onChange={e => setManagementBic(e.target.value)}
                  value={managementBic}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={8} lg={4}>
                <TextField
                  disabled={!managementIban || !managementIbanValid || managementBankDataKnown}
                  fullWidth
                  label='Bank'
                  onChange={e => setManagementBank(e.target.value)}
                  value={managementBank}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Kontakte</Typography>
              </Grid>

              {_.map(managementContacts, contact => {
                const updateManagementContact = contactUpdate => {
                  setManagementContacts(_.map(
                    managementContacts,
                    x => x.id === contact.id
                      ? {
                        ...contact,
                        ...contactUpdate
                      }
                      : x
                  ))
                }

                return (
                  <Grid item xs={12} container spacing={2} key={contact.id} justifyContent='flex-end' sx={{ marginBottom: '10px' }}>
                    <Grid item xs={12} lg={4}>
                      <FormControl size="small">
                        <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                        <RadioGroup row value={contact.salutation} onChange={e => updateManagementContact({ salutation: e.target.value })}>
                          <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                          <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                          <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={5} lg={4}>
                      <TextField
                        fullWidth
                        label='Vorname'
                        onChange={(e) => updateManagementContact({ firstName: e.target.value })}
                        required
                        value={contact.firstName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={7} lg={4}>
                      <TextField
                        fullWidth
                        label='Nachname'
                        onChange={(e) => updateManagementContact({ lastName: e.target.value })}
                        required
                        value={contact.lastName}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Aufgaben'
                        onChange={(e) => updateManagementContact({ work: e.target.value })}
                        value={contact.work}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label='Telefon'
                        onChange={(e) => updateManagementContact({ phone: e.target.value })}
                        value={contact.phone}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label='Telefon (mobil)'
                        onChange={(e) => updateManagementContact({ phoneMobile: e.target.value })}
                        value={contact.phoneMobile}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label='E-Mail'
                        onChange={(e) => updateManagementContact({ email: e.target.value })}
                        type='email'
                        value={contact.email}
                        variant='standard'
                      />
                    </Grid>

                    <Grid item xs='auto' md={1}>
                      <IconButton onClick={() => setManagementContacts(_.filter(managementContacts, x => x.id !== contact.id))}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}

              <IconButton onClick={() => setManagementContacts([...managementContacts, { id: uuid(), firstName: '', lastName: '', phone: '', phoneMobile: '', email: '', work: '' }])} style={{ margin: '5px 12px' }}>
                <AddSharp />
              </IconButton>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Eigentümer</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Firma'
                  onChange={e => setOwnerCompany(e.target.value)}
                  value={ownerCompany}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormControl size="small">
                  <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                  <RadioGroup row value={ownerSalutation} onChange={e => setOwnerSalutation(e.target.value)}>
                    <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                    <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                    <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  label='Vorname'
                  onChange={e => setOwnerFirstName(e.target.value)}
                  required
                  value={ownerFirstName}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  label='Nachname'
                  onChange={e => setOwnerLastName(e.target.value)}
                  required
                  value={ownerLastName}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Straße und Hausnummer'
                  onChange={e => setOwnerStreet(e.target.value)}
                  required
                  value={ownerStreet}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Postleitzahl'
                  onChange={e => setOwnerZip(e.target.value)}
                  required
                  value={ownerZip}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Ort'
                  onChange={e => setOwnerCity(e.target.value)}
                  required
                  value={ownerCity}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  onChange={(e, country) => setOwnerCountry(country)}
                  options={countries}
                  renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                  value={ownerCountry}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Telefon'
                  onChange={e => setOwnerPhone(e.target.value)}
                  value={ownerPhone}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Telefon (mobil)'
                  onChange={e => setOwnerPhoneMobile(e.target.value)}
                  value={ownerPhoneMobile}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='E-Mail'
                  onChange={e => setOwnerEmail(e.target.value)}
                  required
                  type='email'
                  value={ownerEmail}
                  variant='standard'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Gesetzlicher Vertreter'
                  onChange={e => setOwnerLegalRepresentative(e.target.value)}
                  value={ownerLegalRepresentative}
                  variant='standard'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ marginTop: '10px' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>Vermieter</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ownerIsLandlord}
                      onChange={e => {
                        setLandlordCompany('')
                        setLandlordSalutation(e.target.checked ? '' : 'Herr')
                        setLandlordFirstName('')
                        setLandlordLastName('')
                        setLandlordStreet('')
                        setLandlordZip('')
                        setLandlordCity('')
                        setLandlordCountry(e.target.checked ? null : countries[0])
                        setLandlordPhone('')
                        setLandlordPhoneMobile('')
                        setLandlordEmail('')
                        setLandlordLegalRepresentative('')
                        setOwnerIsLandlord(e.target.checked)
                      }}
                    />
                  }
                  label='Eigentümer ist auch Vermieter'
                />
              </Grid>

              {ownerIsLandlord
                ? null
                : <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Firma'
                      onChange={e => setLandlordCompany(e.target.value)}
                      value={landlordCompany}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <FormControl size="small">
                      <FormLabel sx={{ fontSize: '12px' }}>Anrede</FormLabel>
                      <RadioGroup row value={landlordSalutation} onChange={e => setLandlordSalutation(e.target.value)}>
                        <FormControlLabel value='Herr' control={<Radio />} label='Herr' />
                        <FormControlLabel value='Frau' control={<Radio />} label='Frau' />
                        <FormControlLabel value='' control={<Radio />} label='Keine Angabe' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      fullWidth
                      label='Vorname'
                      onChange={e => setLandlordFirstName(e.target.value)}
                      required
                      value={landlordFirstName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                      fullWidth
                      label='Nachname'
                      onChange={e => setLandlordLastName(e.target.value)}
                      required
                      value={landlordLastName}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label='Straße und Hausnummer'
                      onChange={e => setLandlordStreet(e.target.value)}
                      required
                      value={landlordStreet}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label='Postleitzahl'
                      onChange={e => setLandlordZip(e.target.value)}
                      required
                      value={landlordZip}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label='Ort'
                      onChange={e => setLandlordCity(e.target.value)}
                      required
                      value={landlordCity}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.key === value.key}
                      onChange={(e, country) => setLandlordCountry(country)}
                      options={countries}
                      renderInput={(params) => <TextField {...params} label='Land' required variant='standard' style={{ width: '100%' }} />}
                      value={landlordCountry}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label='Telefon'
                      onChange={e => setLandlordPhone(e.target.value)}
                      value={landlordPhone}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label='Telefon (mobil)'
                      onChange={e => setLandlordPhoneMobile(e.target.value)}
                      value={landlordPhoneMobile}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label='E-Mail'
                      onChange={e => setLandlordEmail(e.target.value)}
                      required
                      type='email'
                      value={landlordEmail}
                      variant='standard'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label='Gesetzlicher Vertreter'
                      onChange={e => setLandlordLegalRepresentative(e.target.value)}
                      value={landlordLegalRepresentative}
                      variant='standard'
                    />
                  </Grid>
                </>
              }
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button onClick={endEdit} variant='outlined' sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            Abbrechen
          </Button>

          <LoadingButton loading={isLoading} variant='outlined' type='submit'>
            Speichern
          </LoadingButton>
        </Box>
      </Box>

      <ErrorDialog
        close={() => setErrors([])}
        errors={errors}
        ok={() => setErrors([])}
        open={errors.length > 0}
      />

      <Dialog
        close={() => setShowUpdateConfirmation(false)}
        open={showUpdateConfirmation}
        title="Bestätigung"
        text="Sind Sie sicher, dass die Änderungen inkl. dem Löschen von Kontakten übernommen werden sollen?"
        no={() => setShowUpdateConfirmation(false)}
        noText="Zurück"
        submit={() => {
          setShowUpdateConfirmation(false)
          setUpdateConfirmed(true)
        }}
        submitFormId={formId}
        submitText="Speichern"
      />
    </form>
  )
}

export default RealEstateAdministrationForm
