import _ from 'lodash'
import pouchApi from '../app/pouchApi'
import React, { useState } from 'react'
import TenantCard from '../tenants/TenantCard'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const Tenants = () => {
  const { data: tenants, isLoading } = pouchApi.useGetTenantsQuery()
  const [showDetails, setShowDetails] = useState(null)

  const navigate = useNavigate()

  return isLoading
    ? null
    : <Box sx={{ marginBottom: '50px'}}>
      <Typography variant='h3'>
        Mieter
      </Typography>

      <Box sx={{ display: 'flex' }}>
        <Button onClick={() => navigate('/mieter/neu')} sx={{ marginLeft: 'auto' }} variant='contained'>
          Neuer Mieter
        </Button>
      </Box>

      {_.map(_.filter(tenants, x => !x.ownerOccupation), tenant => {
        return <TenantCard
          key={tenant._id}
          onEditClick={() => navigate('/mieter/bearbeiten', { state: { _id: tenant._id } })}
          setShowDetails={setShowDetails}
          showAll={showDetails === tenant._id}
          tenant={tenant}
        />
      })}
    </Box>
}

export default Tenants
