import TenantForm from '../tenants/TenantForm'
import { Box, Typography } from '@mui/material'

const TenantCreate = () => {
  return (
    <Box sx={{ marginBottom: '50px' }}>
      <Typography variant='h3' sx={{ marginBottom: '20px' }}>
        Mieter erstellen
      </Typography>

      <TenantForm/>
    </Box>
  )
}

export default TenantCreate
