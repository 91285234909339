import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import React, { forwardRef } from 'react'

const AppLink = forwardRef( ({ href = "#", children, className, onClick }, ref) => (
  <Button component={Link} to={href} className={className} onClick={onClick} ref={ref} sx={{ textTransform: 'none' }}>
    {children}
  </Button>
))

export default AppLink
