import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Document, Image, Font, Page, Text, View } from '@react-pdf/renderer'

Font.registerHyphenationCallback(word => [word])

export default function ConfirmationPdf ({ realEstate = {}, tenant = {}, additionalPersons, signaturePads }) {
  const pageMargin = '40px'

  const persons = _.concat(tenant.persons, additionalPersons)

  return (
    <Document>
      <Page style={{ margin: pageMargin, fontSize: '14px', fontFamily: 'Helvetica' }}>
        <Text style={{ fontSize: '20px', fontFamily: 'Helvetica-Bold', textAlign: 'center', marginBottom: '30px', marginTop: '10px' }}>
          Wohnungsgeber-Bestätigung
        </Text>

        <Text style={{ marginBottom: '20px' }}>
          Hiermit wird ein Einzug in folgende <Text style={{ fontFamily: 'Helvetica-Bold' }}>Wohnung</Text> bestätigt:
        </Text>

        <View style={{ marginBottom: '25px' }}>
          <Text style={{ marginBottom: '5px' }}>
            {realEstate.street + ', ' + realEstate.zip + ' ' + realEstate.city}
          </Text>

          {realEstate.floor
            ? <Text style={{ marginBottom: '5px' }}>
               Etage: {realEstate.floor}
              </Text>
            : null
          }
        </View>

        <View wrap={false}>
          <Text style={{ marginBottom: '20px' }}>
            In die genannte Wohung ist/sind am {moment(tenant.rentalStart).format('DD.MM.yyyy')} folgende <Text style={{ fontFamily: 'Helvetica-Bold' }}>Person/en</Text> eingezogen:
          </Text>

          <View style={{ marginBottom: '20px' }}>
            {_.map(persons, (person, i) => (
              <View key={person.id} style={{ flexDirection: 'row', marginBottom: '10px' }}>
                <Text style={{ marginHorizontal: 6 }}>•</Text>
                <Text>{person.firstName + ' ' + person.lastName}</Text>
              </View>
            ))}
          </View>
        </View>

        <View wrap={false} style={{ marginBottom: '20px' }}>
          <Text style={{ marginBottom: '10px' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Name des Wohnungsgebers: </Text>

            {realEstate.ownerIsLandlord
              ? realEstate.ownerFirstName + ' ' + realEstate.ownerLastName
              : realEstate.landlordFirstName + ' ' + realEstate.landlordLastName
            }
          </Text>

          <Text style={{ marginBottom: '10px' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Anschrift des Wohnungsgebers: </Text>

            {realEstate.ownerIsLandlord
              ? realEstate.ownerStreet + ', ' + realEstate.ownerZip + ' ' + realEstate.ownerCity
              : realEstate.landlordStreet + ', ' + realEstate.landlordZip + ' ' + realEstate.landlordCity
            }
          </Text>

          <Text style={{ marginBottom: '10px' }}>
            {realEstate.ownerIsLandlord
              ? 'Der Wohnungsgeber ist gleichzeitig Eigentümer der Wohnung.'
              : 'Der Wohnungsgeber ist nicht Eigentümer der Wohnung:'}
          </Text>

          {!realEstate.ownerIsLandlord
            ? <View>
              <Text style={{ marginBottom: '10px' }}>
                <Text style={{ fontFamily: 'Helvetica-Bold' }}>Name des Eigentümers: </Text>

                {realEstate.ownerFirstName + ' ' + realEstate.ownerLastName}
              </Text>

              <Text style={{ marginBottom: '10px' }}>
                <Text style={{ fontFamily: 'Helvetica-Bold' }}>Anschrift des Eigentümers: </Text>

                {realEstate.ownerStreet + ', ' + realEstate.ownerZip + ' ' + realEstate.ownerCity}
              </Text>
            </View>
            : null
          }
        </View>

        <View wrap={false} style={{ marginBottom: '50px',marginRight: pageMargin }}>
          <Text style={{ marginRight: pageMargin, textAlign: 'justify', textAlignLast: 'none' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>Ich bestätige mit meiner Unterschrift, dass die oben gemachten Angaben den Tatsachen entsprechen. </Text>
            Mir ist bekannt, dass es verboten ist, eine Wohnanschrift für eine Anmeldung einem Dritten anzubieten oder zur Verfügung zu stellen,
            obwohl ein tatsächlicher Bezug der Wohnung durch diesen weder stattfindet noch beabsichtigt ist.
            Ein Verstoß gegen das Verbot stellt ebenso eine Ordnungswidrigkeit dar wie die Ausstellung dieser Bestätigung ohne dazu als Wohnungsgeber
            oder dessen Beauftragter berechtigt zu sein.
          </Text>
        </View>

       {!!signaturePads && signaturePads.length > 0
          ? <View wrap={false} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '25px', marginBottom: pageMargin, marginRight: pageMargin }}>
            {_.map(signaturePads, (pad, i) => (
              <View key={pad.label} style={{ marginBottom: i < signaturePads.length - 1 ? '5px' : pageMargin }}>
                <Text style={{ marginBottom: '20px' }}>{realEstate.city + ', ' + moment().format('DD.MM.yyyy')}</Text>

                <Image src={pad.src} style={{ maxHeight: '50px', maxWidth: '140px', objectFit: 'contain', marginBottom: '10px' }}/>

                <Text>{pad.label}</Text>
              </View>
            ))}
          </View>
          : null
        }
      </Page>
    </Document>
  )
}
