import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Grid, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Remove } from '@mui/icons-material'

const MeterCreateForm = ({ meter, onRemoveMeter, setMeter }) => {
  return (
    <Grid item xs={12} container spacing={2} justifyContent='flex-end'>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          fullWidth
          label='Bezeichnung'
          onChange={(e) => setMeter({ ...meter, name: e.target.value })}
          required
          value={meter.name}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          fullWidth
          label='Zählernummer'
          onChange={(e) => setMeter({ ...meter, number: e.target.value })}
          value={meter.number}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <DatePicker
          label='Datum'
          mask='__.__.____'
          value={meter.values[0].date}
          onChange={date => setMeter({ ...meter, values: [{ ...meter.values[0], date }] })}
          renderInput={(params) => <TextField {...params} required variant='standard' inputProps={{ ...params.inputProps, placeholder: 'dd.mm.yyyy' }} style={{ width: '100%' }}/>}
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={2}>
        <TextField
          fullWidth
          label='Zählerstand'
          onChange={e => setMeter({ ...meter, values: [{ ...meter.values[0], value: e.target.value }] })}
          required
          type='number'
          value={meter.values[0].value}
          variant='standard'
        />
      </Grid>

      <Grid item xs={12} sm={3} lg={1}>
        <FormControl fullWidth required variant='standard'>
          <InputLabel id='unit-label'>Einheit</InputLabel>
          <Select
            labelId='unit-label'
            value={meter.unit}
            label='Einheit'
            onChange={e => setMeter({ ...meter, unit: e.target.value })}
          >
            <MenuItem value='m³'>m³</MenuItem>
            <MenuItem value='kWh'>kWh</MenuItem>
            <MenuItem value='MWh'>MWh</MenuItem>
            <MenuItem value='Einheiten'>Einheiten</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs='auto' sm={1}>
        <IconButton onClick={onRemoveMeter}>
          <Remove />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default MeterCreateForm
